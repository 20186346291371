import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductLink.less';
import Diamond from '../../entity/Diamond';
import Rough from '../../entity/Rough';
import Jewelry from '../../entity/Jewelry';
import SetupPreset from '../../../media/entity/SetupPreset';
import Link, {NAV, NONE, REGULAR} from '../../../common/components/Link/Link';
import URLProvider from '../../../common/services/URLProvider';

@observer
export default class ProductLink extends Component {
  static propTypes = {
    product: PropTypes.oneOfType([PropTypes.instanceOf(Diamond), PropTypes.instanceOf(Rough), PropTypes.instanceOf(Jewelry)]).isRequired,
    setupPreset: PropTypes.instanceOf(SetupPreset),
    text: PropTypes.string,
    className: PropTypes.string,
    appearance: PropTypes.oneOf([REGULAR, NAV, NONE]),
    external: PropTypes.bool,
  };

  static defaultProps = {
    setupPreset: null,
    text: null,
    className: '',
    appearance: REGULAR,
    external: false,
  };

  render() {
    const {
      product, setupPreset, text, external, ...rest
    } = this.props;
    const title = text || product.shortView;

    if (product.isReference) {
      return (
        <span className={`${styles.productLink} ${this.props.className}`} {...rest} title={title}>
          {title}
        </span>
      );
    }

    return (
      <Link
        external={external}
        className={`${styles.productLink} ${this.props.className}`}
        to={URLProvider.productDetailPage(product, setupPreset)}
        aria-label="Product Link"
        title={title}
        {...rest}
      >
        {title}
      </Link>
    );
  }
}
