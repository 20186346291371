import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { NextPayment } from '@/common/components/NextPayment/NextPayment';
import { ALL_CONTEXTS } from '@/common/constants/context';
export const FIELD_NEXT_PAYMENT = {
    label: 'Next Payment',
    fullLabel: 'Next Payment',
    key: 'nextPayment',
    type: FieldType.Custom,
    icon: '',
    getter(product) {
        if (!product.expenses) {
            return null;
        }
        return _jsx(NextPayment, { amount: product.expenses.nextAmount, days: product.expenses.nextDays });
    },
    getterPlain() {
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
