import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';

export default class Certification extends Component {
  static propTypes = {
    certification: PropTypes.object.isRequired,
  };

  render() {
    if (!this.props.certification || !this.props.certification.laboratory) {
      return null;
    }

    return <Link href={this.props.certification.certificateOnline || this.props.certification.certificateFile} external>{this.props.certification.laboratory.title}</Link>;
  }
}
