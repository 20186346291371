// extracted by mini-css-extract-plugin
var _1 = "animatedDropdown";
var _2 = "animatedDropdownContent";
var _3 = "animatedDropdownContentClosed";
var _4 = "animatedDropdownLabel";
var _5 = "animatedDropdownLabelLeft";
var _6 = "animatedDropdownLabelOpened";
var _7 = "animatedDropdownMenuContainer";
var _8 = "animatedDropdownTriangle";
var _9 = "animatedDropdownTriangleOpened";
var _a = "#76F366";
var _b = "#EB8632";
var _c = "#ECC834";
var _d = "#21FC87";
var _e = "#EA4A30";
var _f = "#C7EA45";
var _10 = "#272B2B";
var _11 = "#BDBDBD";
var _12 = "#7D7D7D";
var _13 = "#4A90E2";
var _14 = "#ffffff";
var _15 = "product-block-grid";
var _16 = "product-block-subgrid";
var _17 = "reset-button";
var _18 = "1200px";
var _19 = "992px";
var _1a = "1366px";
var _1b = "768px";
var _1c = "1475px";
var _1d = "576px";
var _1e = "1600px";
var _1f = "320px";
var _20 = "2500px";
var _21 = "single-page-responsive-font-size";
export { _1 as "animatedDropdown", _2 as "animatedDropdownContent", _3 as "animatedDropdownContentClosed", _4 as "animatedDropdownLabel", _5 as "animatedDropdownLabelLeft", _6 as "animatedDropdownLabelOpened", _7 as "animatedDropdownMenuContainer", _8 as "animatedDropdownTriangle", _9 as "animatedDropdownTriangleOpened", _a as "colorGradeEX", _b as "colorGradeFR", _c as "colorGradeGD", _d as "colorGradeOU", _e as "colorGradePR", _f as "colorGradeVG", _10 as "colorNeutral", _11 as "colorNeutralLighest", _12 as "colorNeutralLight", _13 as "colorRegularLinks", _14 as "colorWhite", _15 as "product-block-grid", _16 as "product-block-subgrid", _17 as "reset-button", _18 as "screenlg", _19 as "screenmd", _1a as "screenml", _1b as "screensm", _1c as "screenxlg", _1d as "screenxs", _1e as "screenxxlg", _1f as "screenxxs", _20 as "screenxxxlg", _21 as "single-page-responsive-font-size" }
export default { "animatedDropdown": _1, "animatedDropdownContent": _2, "animatedDropdownContentClosed": _3, "animatedDropdownLabel": _4, "animatedDropdownLabelLeft": _5, "animatedDropdownLabelOpened": _6, "animatedDropdownMenuContainer": _7, "animatedDropdownTriangle": _8, "animatedDropdownTriangleOpened": _9, "colorGradeEX": _a, "colorGradeFR": _b, "colorGradeGD": _c, "colorGradeOU": _d, "colorGradePR": _e, "colorGradeVG": _f, "colorNeutral": _10, "colorNeutralLighest": _11, "colorNeutralLight": _12, "colorRegularLinks": _13, "colorWhite": _14, "product-block-grid": _15, "product-block-subgrid": _16, "reset-button": _17, "screenlg": _18, "screenmd": _19, "screenml": _1a, "screensm": _1b, "screenxlg": _1c, "screenxs": _1d, "screenxxlg": _1e, "screenxxs": _1f, "screenxxxlg": _20, "single-page-responsive-font-size": _21 }
