// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "product-block-grid";
var _d = "product-block-subgrid";
var _e = "reset-button";
var _f = "1200px";
var _10 = "992px";
var _11 = "1366px";
var _12 = "768px";
var _13 = "1475px";
var _14 = "576px";
var _15 = "1600px";
var _16 = "320px";
var _17 = "2500px";
var _18 = "single-page-responsive-font-size";
var _19 = "textFieldCloseButtonWrapper";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "product-block-grid", _d as "product-block-subgrid", _e as "reset-button", _f as "screenlg", _10 as "screenmd", _11 as "screenml", _12 as "screensm", _13 as "screenxlg", _14 as "screenxs", _15 as "screenxxlg", _16 as "screenxxs", _17 as "screenxxxlg", _18 as "single-page-responsive-font-size", _19 as "textFieldCloseButtonWrapper" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "product-block-grid": _c, "product-block-subgrid": _d, "reset-button": _e, "screenlg": _f, "screenmd": _10, "screenml": _11, "screensm": _12, "screenxlg": _13, "screenxs": _14, "screenxxlg": _15, "screenxxs": _16, "screenxxxlg": _17, "single-page-responsive-font-size": _18, "textFieldCloseButtonWrapper": _19 }
