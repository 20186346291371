/**
 * @see https://octonus-teams.com/jira/browse/CUT-6144
 * @param {Query} query
 * @return {Query}
 */
export default (query) => {
  const originalCarat = query.requestBody._originalCarat;

  if (originalCarat) {
    delete query.requestBody._originalCarat;
    query.requestBody.carat = Number(originalCarat).toFixed(5);
  }

  return query;
};
