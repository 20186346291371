export class ACLAssigneeModel {
    id;
    email;
    firstName;
    lastName;
    username;
    company;
}
export class ACLAssignee extends ACLAssigneeModel {
    constructor(model) {
        super();
        Object.keys(model).forEach((key) => {
            this[key] = model[key];
        });
    }
    get name() {
        let name = '';
        if (this.firstName && this.lastName) {
            name = `${this.firstName} ${this.lastName}`;
        }
        return name ? `${name} / ${this.email}` : this.email;
    }
}
