// This module is used for access to ENV variables during runtime
export default {
    MOBX_LOG_ENABLED: MOBX_LOG_ENABLED,
    CLIENT_ID: CLIENT_ID,
    AUTH_URI: AUTH_URI,
    REDIRECT_URI: REDIRECT_URI,
    NETBOX_AUTH_URI: NETBOX_AUTH_URI,
    NETBOX_CLIENT_ID: NETBOX_CLIENT_ID,
    NETBOX_REDIRECT_URI: NETBOX_REDIRECT_URI,
    IS_DEV: IS_DEV,
    PLAYER_JS_URL: '',
    PLAYER_CSS_URL: '',
    CARBON_VIEWER_URL: CARBON_VIEWER_URL,
    APP_VERSION: APP_VERSION,
};
