// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "metricBar";
var _d = "product-block-grid";
var _e = "product-block-subgrid";
var _f = "reset-button";
var _10 = "1200px";
var _11 = "992px";
var _12 = "1366px";
var _13 = "768px";
var _14 = "1475px";
var _15 = "576px";
var _16 = "1600px";
var _17 = "320px";
var _18 = "2500px";
var _19 = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "metricBar", _d as "product-block-grid", _e as "product-block-subgrid", _f as "reset-button", _10 as "screenlg", _11 as "screenmd", _12 as "screenml", _13 as "screensm", _14 as "screenxlg", _15 as "screenxs", _16 as "screenxxlg", _17 as "screenxxs", _18 as "screenxxxlg", _19 as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "metricBar": _c, "product-block-grid": _d, "product-block-subgrid": _e, "reset-button": _f, "screenlg": _10, "screenmd": _11, "screenml": _12, "screensm": _13, "screenxlg": _14, "screenxs": _15, "screenxxlg": _16, "screenxxs": _17, "screenxxxlg": _18, "single-page-responsive-font-size": _19 }
