import { action, observable } from 'mobx';
import ReferenceAPI from '../api/ReferenceAPI';
import Diamond from '../../product/entity/Diamond';
import {appStore} from "@/common/stores/AppStore";

class ReferenceStore {
  /**
   * @type {boolean}
   */
  @observable showReference = false;

  /**
   * @param {AppStore} appStore
   */
  constructor(appStore) {
    this.appStore = appStore;
    this.referenceAPI = new ReferenceAPI();
  }

  /**
   * @type {Object.<number, Diamond>}
   */
  referenceMap = {};

  /**
   * @return {Promise}
   */
  fetch(product) {
    if (product.carat === 0) {
      return Promise.resolve(null);
    }

    if (this.referenceMap[product.carat]) {
      return Promise.resolve(this.referenceMap[product.carat]);
    }

    return this.referenceAPI.fetchItem(product).then((res) => {
      const diamond = new Diamond(res.content);
      this.referenceMap[product.carat] = diamond;

      return this.referenceMap[product.carat];
    });
  }

  /**
   * @param {boolean} showReference
   */
  @action
  setShowReference = (showReference) => {
    this.showReference = showReference;
  };

  @action
  toggleShowReference = () => {
    this.setShowReference(!this.showReference);
  };
}

export const referenceStore = new ReferenceStore(appStore);
export default ReferenceStore
