// extracted by mini-css-extract-plugin
var _1 = "active";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#4A90E2";
var _c = "#ffffff";
var _d = "header";
var _e = "header--displayed";
var _f = "header--hidden";
var _10 = "header-link";
var _11 = "header-link--desktop";
var _12 = "header-link--dropdown";
var _13 = "header-link__triangle";
var _14 = "header-link__triangle--opened";
var _15 = "headerContent";
var _16 = "headerFixed";
var _17 = "headerLinkActive";
var _18 = "headerLinkLGD";
var _19 = "headerProductTypeIcon";
var _1a = "headerProductTypeMenuItem";
var _1b = "headerProductTypeMenuWrapper";
var _1c = "header__cache-proxy";
var _1d = "header__chapters";
var _1e = "header__hm";
var _1f = "header__left";
var _20 = "header__link";
var _21 = "header__link--collections";
var _22 = "header__link--desktop";
var _23 = "header__link--disabled";
var _24 = "header__link--icon";
var _25 = "header__link-title";
var _26 = "header__links";
var _27 = "header__logo";
var _28 = "header__mobile";
var _29 = "header__mobile--opened";
var _2a = "header__mobile-item";
var _2b = "header__mobile-item-list";
var _2c = "hide-header";
var _2d = "hide-on-small-screens";
var _2e = "iconFancy";
var _2f = "label";
var _30 = "pinned-collection";
var _31 = "pinned-collection__label";
var _32 = "pinned-collection__link";
var _33 = "popover-container";
var _34 = "product-block-grid";
var _35 = "product-block-subgrid";
var _36 = "reset-button";
var _37 = "1200px";
var _38 = "992px";
var _39 = "1366px";
var _3a = "768px";
var _3b = "1475px";
var _3c = "576px";
var _3d = "1600px";
var _3e = "320px";
var _3f = "2500px";
var _40 = "show-header";
var _41 = "show-on-small-screens";
var _42 = "single-page-responsive-font-size";
export { _1 as "active", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorRegularLinks", _c as "colorWhite", _d as "header", _e as "header--displayed", _f as "header--hidden", _10 as "header-link", _11 as "header-link--desktop", _12 as "header-link--dropdown", _13 as "header-link__triangle", _14 as "header-link__triangle--opened", _15 as "headerContent", _16 as "headerFixed", _17 as "headerLinkActive", _18 as "headerLinkLGD", _19 as "headerProductTypeIcon", _1a as "headerProductTypeMenuItem", _1b as "headerProductTypeMenuWrapper", _1c as "header__cache-proxy", _1d as "header__chapters", _1e as "header__hm", _1f as "header__left", _20 as "header__link", _21 as "header__link--collections", _22 as "header__link--desktop", _23 as "header__link--disabled", _24 as "header__link--icon", _25 as "header__link-title", _26 as "header__links", _27 as "header__logo", _28 as "header__mobile", _29 as "header__mobile--opened", _2a as "header__mobile-item", _2b as "header__mobile-item-list", _2c as "hide-header", _2d as "hide-on-small-screens", _2e as "iconFancy", _2f as "label", _30 as "pinned-collection", _31 as "pinned-collection__label", _32 as "pinned-collection__link", _33 as "popover-container", _34 as "product-block-grid", _35 as "product-block-subgrid", _36 as "reset-button", _37 as "screenlg", _38 as "screenmd", _39 as "screenml", _3a as "screensm", _3b as "screenxlg", _3c as "screenxs", _3d as "screenxxlg", _3e as "screenxxs", _3f as "screenxxxlg", _40 as "show-header", _41 as "show-on-small-screens", _42 as "single-page-responsive-font-size" }
export default { "active": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorRegularLinks": _b, "colorWhite": _c, "header": _d, "header--displayed": _e, "header--hidden": _f, "header-link": _10, "header-link--desktop": _11, "header-link--dropdown": _12, "header-link__triangle": _13, "header-link__triangle--opened": _14, "headerContent": _15, "headerFixed": _16, "headerLinkActive": _17, "headerLinkLGD": _18, "headerProductTypeIcon": _19, "headerProductTypeMenuItem": _1a, "headerProductTypeMenuWrapper": _1b, "header__cache-proxy": _1c, "header__chapters": _1d, "header__hm": _1e, "header__left": _1f, "header__link": _20, "header__link--collections": _21, "header__link--desktop": _22, "header__link--disabled": _23, "header__link--icon": _24, "header__link-title": _25, "header__links": _26, "header__logo": _27, "header__mobile": _28, "header__mobile--opened": _29, "header__mobile-item": _2a, "header__mobile-item-list": _2b, "hide-header": _2c, "hide-on-small-screens": _2d, "iconFancy": _2e, "label": _2f, "pinned-collection": _30, "pinned-collection__label": _31, "pinned-collection__link": _32, "popover-container": _33, "product-block-grid": _34, "product-block-subgrid": _35, "reset-button": _36, "screenlg": _37, "screenmd": _38, "screenml": _39, "screensm": _3a, "screenxlg": _3b, "screenxs": _3c, "screenxxlg": _3d, "screenxxs": _3e, "screenxxxlg": _3f, "show-header": _40, "show-on-small-screens": _41, "single-page-responsive-font-size": _42 }
