export const generateFiltersSetKey = productType => `filters.${productType}.fields`;

/**
 * Stores settings data in a storage of different types (like Localstorage, Sessionstorage, API etc.)
 *
 * Settings scheme:
 * {
 *   "filters": {
 *     "rough": {},
 *     "diamond-colorless": {
 *       "fields": ["price", "carat", "cut],
 *       "predefined": [
 *         {
 *            "title": "Example 1",
 *            "fields": ["fluor", "carat", "seller"]
 *         }
 *       ]
 *     }
 *   },
 *   "ui": {
 *     "isSidebarOpened": false,
 *     "isHQModeEnabled": true
 *   }
 * }
 */
export default class UserSessionDataManager {
  constructor(driver) {
    this.setDriver(driver);
  }

  /**
   * @param {string} key
   * @return {Promise}
   */
  get(key) {
    return this.driver.get(key);
  }

  /**
   * @param {string} key
   * @param {*} data
   * @return {Promise}
   */
  set(key, data) {
    return this.driver.set(key, data);
  }

  /**
   * @param {string} key
   * @param {function} callback
   */
  onKeyChange(key, callback) {
    this.driver.onKeyChange(key, callback);
  }

  /**
   * @param {LocalStorageDriver|APIDriver}driver
   */
  setDriver(driver) {
    this.driver = driver;
  }
}
