import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ONLY_LISTING_CONTEXTS } from '@/common/constants/context';
export const FIELD_SELLER = {
    label: 'Seller',
    fullLabel: 'Seller',
    key: 'b2b',
    type: FieldType.Scalar,
    getter(p) {
        const titles = [];
        if (p.b2b?.title) {
            titles.push(p.b2b.title);
        }
        if (p.seller?.title && !titles.includes(p.seller.title)) {
            titles.push(p.seller.title);
        }
        if (titles.length === 0) {
            return '';
        }
        return titles.join(', ');
    },
    getterPlain(p) {
        if (p.seller?.title) {
            return p.seller;
        }
        if (p.b2b?.title) {
            return p.b2b;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ONLY_LISTING_CONTEXTS, // hide this field on StockManagement Mode
};
