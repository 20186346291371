import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import priceFormatter from '@/common/helpers/priceFormatter';
export const FIELD_PRICE = {
    label: 'Price',
    fullLabel: 'Price',
    key: 'price',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        return p.price ? priceFormatter.format(p.price) : null;
    },
    getterPlain(p) {
        return p.price ? p.price : null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        return p.price ? p.price * -1 : null;
    },
    contexts: ALL_CONTEXTS,
};
