import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
import MetricScaleContainer from '@/metric/components/MetricScaleContainer/MetricScaleContainer';
import OptMetricBar from '@/metric/components/OptMetricBar/OptMetricBar';
import Diamond from '@/product/entity/Diamond';
export const FIELD_FIRE = {
    key: 'fire.val',
    label: 'Fire',
    fullLabel: 'Fire',
    type: FieldType.Metric,
    spGroupId: SetupPresetGroupType.FireDiBOX,
    icon: 'metrics-fire',
    getter(p) {
        if (p instanceof Diamond && p.fireMetric) {
            return p.fireMetric.hr;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.fireMetric) {
            return p.fireMetric.val;
        }
        return null;
    },
    decorator: (_, p, { ratio, size, label, icon, metricDataDecorator } = {}) => {
        if (p instanceof Diamond) {
            return (_jsx(OptMetricBar, { cutShapeId: p.cutShapeId, metric: p.fireMetric, metricDataDecorator: metricDataDecorator, ratio: ratio, type: "fire", children: (scale, abs) => (_jsx(MetricScaleContainer, { abs: abs, icon: icon, isMetricCalculated: Boolean(p.fireMetric), label: label, scale: scale, size: size })) }));
        }
        return null;
    },
    iteratee(p) {
        if (p instanceof Diamond && p.fireMetric) {
            return p.fireMetric.val;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
