import Auth from '@/user/services/auth/Auth';
import Notifier from '../../services/Notifier';
import AppError from '../../state/AppError';
import { HttpStatus } from '../constants/httpStatus';
function generateErrorMessage(error) {
    if (!error) {
        return '';
    }
    if (Array.isArray(error.error) && error.error.length > 0) {
        const filteredErrorMessages = error.error.filter((e) => e);
        if (filteredErrorMessages.length > 0) {
            return filteredErrorMessages.join('; ');
        }
    }
    else if (error.error instanceof Object) {
        const errorMessages = Object.values(error.error);
        if (errorMessages.length > 0) {
            return errorMessages.join('; ');
        }
    }
    if (error.message) {
        return `${error.message}`;
    }
    return 'Server error!';
}
function handleCommonServerStatuses(res) {
    if (!res.ok) {
        switch (res.status) {
            case HttpStatus.BadRequest:
                Notifier.warn(generateErrorMessage(res.content));
                break;
            case HttpStatus.Unauthorized:
                Auth.logoutAfterSessionExpiration();
                break;
            case HttpStatus.NotFound:
            case HttpStatus.PreconditionFailed:
                break;
            default:
                Notifier.error(generateErrorMessage(res.content));
                break;
        }
    }
}
export default function HandleErrors(isSilent = false) {
    return (query) => {
        if (!isSilent) {
            handleCommonServerStatuses(query.result);
        }
        if (!query?.result?.ok) {
            throw AppError.createErrorFromCodeAndTitle(query.result.status, generateErrorMessage(query.result.content));
        }
        return query;
    };
}
