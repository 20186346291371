import { ContactData } from '@/contacts/entity/ContactData';
export class ContactDataLinkModel {
    id;
    contactData;
}
export class ContactDataLink extends ContactDataLinkModel {
    constructor(modelData) {
        super();
        Object.keys(modelData).forEach((key) => {
            switch (key) {
                case 'contactData':
                    this.contactData = new ContactData(modelData.contactData);
                    break;
                default:
                    this[key] = modelData[key];
                    break;
            }
        });
    }
}
