import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { CUSTOM_GRADE_COLOR } from '@/customGrading/constants/customGradeCodes';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
export const FIELD_CUSTOM_COLOR = {
    label: 'Custom Color',
    fullLabel: 'Custom Color',
    key: 'customColor',
    type: FieldType.Scalar,
    spGroupId: SetupPresetGroupType.PavColorLab,
    icon: 'metrics-color',
    getter(p) {
        return p.customGrades?.[CUSTOM_GRADE_COLOR]?.value?.title ?? null;
    },
    getterPlain(p) {
        return p.customGrades?.[CUSTOM_GRADE_COLOR]?.rawValue ?? null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        return p.customGrades?.[CUSTOM_GRADE_COLOR]?.value ? p.customGrades[CUSTOM_GRADE_COLOR].value.id * -1 : null;
    },
    contexts: ALL_CONTEXTS,
    isCustomGrade: true,
};
