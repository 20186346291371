// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "layout";
var _d = "layoutOneColumn";
var _e = "layoutStrictHeight";
var _f = "layoutTwoColumn";
var _10 = "layoutWithSidebar";
var _11 = "layoutWithoutSidebar";
var _12 = "product-block-grid";
var _13 = "product-block-subgrid";
var _14 = "reset-button";
var _15 = "1200px";
var _16 = "992px";
var _17 = "1366px";
var _18 = "768px";
var _19 = "1475px";
var _1a = "576px";
var _1b = "1600px";
var _1c = "320px";
var _1d = "2500px";
var _1e = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "layout", _d as "layoutOneColumn", _e as "layoutStrictHeight", _f as "layoutTwoColumn", _10 as "layoutWithSidebar", _11 as "layoutWithoutSidebar", _12 as "product-block-grid", _13 as "product-block-subgrid", _14 as "reset-button", _15 as "screenlg", _16 as "screenmd", _17 as "screenml", _18 as "screensm", _19 as "screenxlg", _1a as "screenxs", _1b as "screenxxlg", _1c as "screenxxs", _1d as "screenxxxlg", _1e as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "layout": _c, "layoutOneColumn": _d, "layoutStrictHeight": _e, "layoutTwoColumn": _f, "layoutWithSidebar": _10, "layoutWithoutSidebar": _11, "product-block-grid": _12, "product-block-subgrid": _13, "reset-button": _14, "screenlg": _15, "screenmd": _16, "screenml": _17, "screensm": _18, "screenxlg": _19, "screenxs": _1a, "screenxxlg": _1b, "screenxxs": _1c, "screenxxxlg": _1d, "single-page-responsive-font-size": _1e }
