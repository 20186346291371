import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from 'mobx-react';
import { analyticsStore } from '@/analytics/store/AnalyticsStore';
import { collectionsStore } from '@/collection/stores/CollectionsStore';
import { appStore } from '@/common/stores/AppStore';
import { configurationStore } from '@/common/stores/ConfigurationStore';
import { presentationStore } from '@/common/stores/PresentationStore';
import { referenceStore } from '@/common/stores/ReferenceStore';
import separateWindowStore from '@/common/stores/SeparateWindowStore';
import { comparisonStore } from '@/comparison/stores/ComparisonStore';
import { contactsStore } from '@/contacts/stores/ContactsStore';
import { conversionStore } from '@/conversion/stores/ConversionStore';
import { netBoxStore } from '@/netbox/stores/NetBoxStore';
import { userStore } from '@/user/stores/UserStore';
const store = {
    configurationStore,
    appStore,
    userStore,
    comparisonStore,
    collectionsStore,
    analyticsStore,
    netBoxStore,
    referenceStore,
    presentationStore,
    separateWindowStore,
    contactsStore,
    conversionStore,
};
export function StoreProvider({ children }) {
    return _jsx(Provider, { ...store, children: children });
}
export default store;
