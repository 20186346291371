/**
 * GIA Rounding
 *
 * @see https://octonus-teams.com/jira/browse/CUT-5454
 * @param {Query} query
 * @return {Query}
 */
export default (query) => {
  const filters = query.queryStringParams.filters;

  if (filters.carat && filters.carat.from) {
    filters.carat.from = (filters.carat.from - 0.001).toFixed(3);
  }

  if (filters.carat && filters.carat.to) {
    filters.carat.to = (filters.carat.to + 0.008).toFixed(3);
  }

  return query;
};
