import isEmpty from 'lodash/isEmpty';
import {dictionaryRepository} from '@/common/repositories/DictionaryRepository';

export default class FiltersNormalizer {
  /**
   * @param {Object} data JSON
   * @return {Object} JSON
   */
  static normalize(data) {
    if (data.createdAt && data.createdAt.from && data.createdAt.from.format) {
      data.createdAt.from = data.createdAt.from.format('yyyy-MM-dd');
    }

    if (data.createdAt && data.createdAt.to && data.createdAt.to.format) {
      data.createdAt.to = data.createdAt.to.format('yyyy-MM-dd');
    }

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof Object && !Array.isArray(value)) {
        data[key] = FiltersNormalizer.normalize(value);
      }

      if (data[key] === false || data[key] === true) {
        data[key] = Number(data[key]);
      }

      if (((value instanceof Object || Array.isArray(value)) && isEmpty(value)) || value === null || value === undefined || value === '') {
        delete data[key];
      }
    });

    if (data.fireGmc) {
      data['fire.gmc'] = data.fireGmc;
      delete data.fireGmc;
    }

    if (data.scintillationGmc) {
      data['scintillation.gmc'] = data.scintillationGmc;
      delete data.scintillationGmc;
    }

    if (data.brillianceGmc) {
      data['brilliance.gmc'] = data.brillianceGmc;
      delete data.brillianceGmc;
    }

    if (data.brillianceGmc2) {
      data['brilliance.gmc2'] = data.brillianceGmc2;
      delete data.brillianceGmc2;
    }

    if (data.brillianceDz) {
      data['brilliance.dz'] = data.brillianceDz;
      delete data.brillianceDz;
    }

    if (data.brillianceAvg) {
      data['brilliance.avg'] = data.brillianceAvg;
      delete data.brillianceAvg;
    }

    if (data.brillianceFaceUp) {
      data['brilliance.faceup'] = data.brillianceFaceUp;
      delete data.brillianceFaceUp;
    }

    if (data.integralGmc) {
      data['integral.gmc'] = data.integralGmc;
      delete data.integralGmc;
    }

    if (data.integralSmc) {
      data['integral.smc'] = data.integralSmc;
      delete data.integralSmc;
    }

    if (data.fluorGrade) {
      data['fluor.grade'] = data.fluorGrade;
      delete data.fluorGrade;
    }

    if (data.fluorColor) {
      data['fluor.color'] = data.fluorColor;
      delete data.fluorColor;
    }

    if (data.symmetryGmc) {
      data['symmetry.gmc'] = data.symmetryGmc;
      delete data.symmetryGmc;
    }

    if (data.inclusions && Array.isArray(data.inclusions)) {
      ['openInc', 'whiteInc', 'blackInc'].forEach((key) => {
        data.inclusions.forEach((title) => {
          const item = dictionaryRepository.findItemInDictionaryByTitle(key, title);

          if (item) {
            data[key] = Array.isArray(data[key]) ? data[key].concat(item.id) : [item.id];
          }
        });
      });

      delete data.inclusions;
    }

    return data;
  }
}
