import isNil from 'lodash/isNil';

export default class ReportData {
  /** @type {?number} */
  rt = null;

  /** @type {?number} */
  crnAn = null;

  /** @type {?number} */
  culet = null;

  /** @type {?number} */
  pav1An = null;

  /** @type {?number} */
  pavAn = null;

  /** @type {?number} */
  table = null;

  /** @type {?number} */
  width = null;

  /** @type {?number} */
  height = null;

  /** @type {?number} */
  length = null;

  /** @type {?number} */
  culetPc = null;

  /** @type {?number} */
  tablePc = null;

  /** @type {?number} */
  heightPc = null;

  /** @type {?number} */
  pavDepth = null;

  /** @type {?number} */
  crnHeight = null;

  /** @type {?number} */
  pavDepthPc = null;

  /** @type {?number} */
  crnHeightPc = null;

  /** @type {?number} */
  girdleBezel = null;

  /** @type {?number} */
  girdleBezelPc = null;

  /** @type {?string} */
  dzPavColor = null;

  /** @type {?string} */
  dzTableColor = null;

  /** @type {?string} */
  fHist = null;

  /** @type {?string} */
  fChroma = null;

  /**
   * @param {?number} rt
   * @param {?number} crnAn
   * @param {?number} culet
   * @param {?number} pav1An
   * @param {?number} pavAn
   * @param {?number} table
   * @param {?number} width
   * @param {?number} height
   * @param {?number} length
   * @param {?number} culetPc
   * @param {?number} tablePc
   * @param {?number} heightPc
   * @param {?number} pavDepth
   * @param {?number} crnHeight
   * @param {?number} pavDepthPc
   * @param {?number} crnHeightPc
   * @param {?number} girdleBezel
   * @param {?number} girdleBezelPc
   * @param {?string} dzPavColor
   * @param {?string} dzTableColor
   * @param {?string} fHist
   * @param {?string} fChroma
   */
  constructor({
    rt,
    crnAn,
    culet,
    pav1An,
    pavAn,
    table,
    width,
    height,
    length,
    culetPc,
    tablePc,
    heightPc,
    pavDepth,
    crnHeight,
    pavDepthPc,
    crnHeightPc,
    girdleBezel,
    girdleBezelPc,
    dzPavColor,
    dzTableColor,
    fHist,
    fChroma,
  }) {
    this.rt = !isNil(rt) ? rt : null;
    this.crnAn = !isNil(crnAn) ? crnAn : null;
    this.culet = !isNil(culet) ? culet : null;
    this.pav1An = !isNil(pav1An) ? pav1An : null;
    this.pavAn = !isNil(pavAn) ? pavAn : null;
    this.table = !isNil(table) ? table : null;
    this.width = !isNil(width) ? width : null;
    this.height = !isNil(height) ? height : null;
    this.length = !isNil(length) ? length : null;
    this.culetPc = !isNil(culetPc) ? culetPc : null;
    this.tablePc = !isNil(tablePc) ? tablePc : null;
    this.heightPc = !isNil(heightPc) ? heightPc : null;
    this.pavDepth = !isNil(pavDepth) ? pavDepth : null;
    this.crnHeight = !isNil(crnHeight) ? crnHeight : null;
    this.pavDepthPc = !isNil(pavDepthPc) ? pavDepthPc : null;
    this.crnHeightPc = !isNil(crnHeightPc) ? crnHeightPc : null;
    this.girdleBezel = !isNil(girdleBezel) ? girdleBezel : null;
    this.girdleBezelPc = !isNil(girdleBezelPc) ? girdleBezelPc : null;
    this.dzPavColor = !isNil(dzPavColor) ? dzPavColor : null;
    this.dzTableColor = !isNil(dzTableColor) ? dzTableColor : null;
    this.fHist = !isNil(fHist) ? fHist : null;
    this.fChroma = !isNil(fChroma) ? fChroma : null;
  }
}
