import { useContext, useMemo } from 'react';
import { MobXProviderContext } from 'mobx-react';
// Hook for getting store from Mobx Provider.
// Does not support multiple stores with same type in provider context
// For stores, written in ts, it should be added in end of store file
// For stores, still written in js, should be added in separate ts file with .hook suffix (UserStore.hook.ts, for example)
// (if added in js file hook will return store with any type)
export default function useStore(storeClass) {
    const storeMap = useContext(MobXProviderContext);
    const store = useMemo(() => {
        const stores = Object.values(storeMap);
        return stores.find((store) => store instanceof storeClass);
    }, [storeMap, storeClass]);
    if (!store) {
        throw new Error(`no store '${storeClass.name}' passed to Mobx Provider`);
    }
    return store;
}
