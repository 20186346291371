/**
 * @param {HpoColor} mainHpoColor
 * @param {HpoColor} secondaryHpoColor
 * @return {?string}
 */
export default (mainHpoColor, secondaryHpoColor) => {
  if (!mainHpoColor || !secondaryHpoColor) {
    return null;
  }

  const mainHpoColorIndex1 = mainHpoColor.getPart1Index();
  const secondaryHpoColorIndex1 = secondaryHpoColor.getPart1Index();

  if (mainHpoColorIndex1 === null || secondaryHpoColorIndex1 === null) {
    return null;
  }

  const mainHpoColorPercent2 = mainHpoColor.getPart2Percent();
  const secondaryHpoColorPercent2 = secondaryHpoColor.getPart2Percent();

  const hpoColorDifference = mainHpoColorIndex1 + mainHpoColorPercent2 - (secondaryHpoColorIndex1 + secondaryHpoColorPercent2);
  const printableHpoColorDifference = hpoColorDifference > 0 ? `+${hpoColorDifference.toFixed(1)}` : hpoColorDifference.toFixed(1);

  return `(${printableHpoColorDifference})`;
};
