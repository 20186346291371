var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from 'mobx';
import Notifier from '@/common/services/Notifier';
import Jewelry from '@/product/entity/Jewelry';
import Rough from '@/product/entity/Rough';
import SecurityAPI from '../api/SecurityAPI';
export class AccessStore {
    entities = []; // contains entities that will be updated - "products" or "projects"
    entitiesValues = [];
    recursive = false;
    isUpdating = false;
    isFetching = false;
    type;
    securityAPI;
    constructor(entities, type) {
        this.type = type;
        this.entities = entities;
        // we don't need the whole structure when we change data
        this.entitiesValues = this.getAccessValues(entities);
        this.securityAPI = new SecurityAPI();
    }
    get accessValues() {
        return this.entitiesValues.map((entity) => entity.access);
    }
    get dataAccessValues() {
        return this.entitiesValues.map((entity) => entity.dataAccess);
    }
    get accessDiff() {
        return (this.entitiesValues.filter(({ id, access }) => this.entities.find((entity) => entity.id === id)?.access !== access).length > 0);
    }
    get dataAccessDiff() {
        return (this.entitiesValues.filter(({ id, dataAccess }) => this.entities.find((entity) => entity.id === id)?.dataAccess !== dataAccess).length > 0);
    }
    get entitiesCount() {
        return this.entities.length;
    }
    get isRough() {
        return this.entities?.[0] instanceof Rough;
    }
    get isJewelry() {
        return this.entities?.[0] instanceof Jewelry;
    }
    setAccess = (nextAccessLevel) => {
        this.entitiesValues = this.entitiesValues.map((entity) => {
            entity.access = nextAccessLevel;
            return entity;
        });
    };
    setDataAccess = (nextDataAccessLevel) => {
        this.entitiesValues = this.entitiesValues.map((entity) => {
            entity.dataAccess = nextDataAccessLevel;
            return entity;
        });
    };
    setRecursive = (state) => {
        this.recursive = state;
    };
    updateAccessList = async () => {
        this.isUpdating = true;
        try {
            const entities = this.entitiesValues.map((entity) => {
                if (this.type === 'project') {
                    entity.recursive = this.recursive;
                }
                return {
                    ...entity,
                    type: this.type,
                };
            });
            const response = await this.securityAPI.updateAccess(entities);
            const successUpdate = [];
            const failUpdate = [];
            (response?.content ?? []).forEach((entity) => {
                if (entity.state === 'ok') {
                    successUpdate.push(entity);
                }
                else {
                    failUpdate.push(entity);
                }
            });
            if (successUpdate.length > 0 && failUpdate.length === 0) {
                Notifier.success(`${this.type === 'project' ? 'Project' : 'Product'} access updated`);
            }
            if (failUpdate.length > 0) {
                if (successUpdate.length > 0) {
                    Notifier.warn(`${failUpdate.length} failed to update`);
                }
                else if (failUpdate.length === 1 && failUpdate?.[0]?.error) {
                    Notifier.error(failUpdate[0].error);
                }
                else {
                    Notifier.error(`${failUpdate.length} failed to update`);
                }
            }
            this.isUpdating = false;
            return this.entities.map((entity) => {
                const updatedEntity = successUpdate.find(({ id }) => `${entity.id}` === `${id}`);
                if (updatedEntity) {
                    entity.access = updatedEntity.access;
                    entity.dataAccess = updatedEntity.dataAccess;
                }
                return entity;
            });
        }
        catch (e) {
            Notifier.error(e);
            this.isUpdating = false;
            return [];
        }
    };
    reset = () => {
        this.setRecursive(false);
        this.entitiesValues = this.getAccessValues(this.entities);
    };
    getAccessValues(entities) {
        return entities.map((item) => ({
            id: item.id,
            access: item.access,
            dataAccess: item.dataAccess,
        }));
    }
}
__decorate([
    observable
], AccessStore.prototype, "entities", void 0);
__decorate([
    observable
], AccessStore.prototype, "entitiesValues", void 0);
__decorate([
    observable
], AccessStore.prototype, "recursive", void 0);
__decorate([
    observable
], AccessStore.prototype, "isUpdating", void 0);
__decorate([
    observable
], AccessStore.prototype, "isFetching", void 0);
__decorate([
    observable
], AccessStore.prototype, "type", void 0);
__decorate([
    computed
], AccessStore.prototype, "accessValues", null);
__decorate([
    computed
], AccessStore.prototype, "dataAccessValues", null);
__decorate([
    computed
], AccessStore.prototype, "accessDiff", null);
__decorate([
    computed
], AccessStore.prototype, "dataAccessDiff", null);
__decorate([
    computed
], AccessStore.prototype, "entitiesCount", null);
