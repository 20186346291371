import CutwiseAPIClient, { METHOD_GET, METHOD_POST } from '@/common/network/CutwiseAPIClient';
import ToFormDataSerializationStrategy from '@/common/network/serialization/ToFormDataSerializationStrategy';
const PATH_ORDER_LIST = 'api/order/list';
const PATH_CREATE_ORDER = 'api/order/conversion';
export default class ConversionAPI {
    getOrderList() {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_ORDER_LIST);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    createOrder(data) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .path(PATH_CREATE_ORDER)
            .body(data)
            .serializer(ToFormDataSerializationStrategy)
            .suppressErrorNotifications();
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
