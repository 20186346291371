export const MEDIA_SIZE_40 = 40;
export const MEDIA_SIZE_80 = 80;
export const MEDIA_SIZE_160 = 160;
export const MEDIA_SIZE_240 = 240;
export const MEDIA_SIZE_320 = 320;
export const MEDIA_SIZE_480 = 480;
export const MEDIA_SIZE_640 = 640;
export const MEDIA_SIZE_960 = 960;
export const MEDIA_SIZE_1200 = 1200;
export const MEDIA_SIZE_1920 = 1920;
export const ALL_POSSIBLE_ORIGINAL_MEDIA_SIZES = [
    MEDIA_SIZE_160,
    MEDIA_SIZE_240,
    MEDIA_SIZE_320,
    MEDIA_SIZE_480,
    MEDIA_SIZE_640,
    MEDIA_SIZE_960,
    MEDIA_SIZE_1200,
    MEDIA_SIZE_1920,
];
