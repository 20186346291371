import { ACCENT, SECONDARY, TERTIARY } from '@/common/components/Label/Label';
export const ACCESS_TITLE_MAP = {
    product: 'General',
    project: 'General',
};
export var AccessLevel;
(function (AccessLevel) {
    AccessLevel[AccessLevel["Restricted"] = 0] = "Restricted";
    AccessLevel[AccessLevel["Unlisted"] = 1] = "Unlisted";
    AccessLevel[AccessLevel["Public"] = 2] = "Public";
})(AccessLevel || (AccessLevel = {}));
export const PRODUCT_ACCESS_LEVELS = [
    {
        id: AccessLevel.Restricted,
        title: 'Restricted',
        description: 'Product is not available to non-company employees (except users with private access).',
    },
    {
        id: AccessLevel.Unlisted,
        title: 'Unlisted',
        description: 'Anyone on the internet with DiLog or widget link can view product. Product is not visible in Cutwise Catalog.',
    },
    {
        id: AccessLevel.Public,
        title: 'Public',
        description: 'Product is available in Cutwise Catalog and via direct links (including widget \\ DiLog) to anyone.',
    },
];
export const PROJECT_ACCESS_LEVELS = [
    {
        id: AccessLevel.Restricted,
        title: 'Restricted',
        description: 'Project is not available to non-company employees (except users with private access).',
    },
    {
        id: AccessLevel.Public,
        title: 'Public',
        description: 'Project is available via direct link to anyone.',
    },
];
export function getAccessByType(type) {
    if (type === 'product') {
        return PRODUCT_ACCESS_LEVELS;
    }
    if (type === 'project') {
        return PROJECT_ACCESS_LEVELS;
    }
    return [];
}
export function getAccessById(id, type) {
    return getAccessByType(type).find((access) => access.id === id);
}
const ACCESS_APPEARANCE = {
    [AccessLevel.Restricted]: TERTIARY,
    [AccessLevel.Unlisted]: SECONDARY,
    [AccessLevel.Public]: ACCENT,
};
const ACCESS_ICON = {
    [AccessLevel.Restricted]: 'block',
    [AccessLevel.Unlisted]: 'code',
    [AccessLevel.Public]: 'check',
};
export function getAccessLabelAppearance(level) {
    return ACCESS_APPEARANCE[level];
}
export function getAccessLabelIcon(level) {
    return ACCESS_ICON[level];
}
