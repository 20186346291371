import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo, useEffect, useRef } from 'react';
import cls from 'classnames';
import { useToggle } from '@/common/hooks/useToggle/useToggle';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import ModalPortal from '../ModalPortal/ModalPortal';
import styles from './Modal.less';
export const CENTER = 'center';
export const TOP = 'top';
export const BOTTOM = 'bottom';
export const LEFT = 'left'; // fixme: doesn't work
export const RIGHT = 'right'; // fixme: doesn't work
const CLASS_POSITION_MAP = {
    [CENTER]: styles.modalCentered,
    [TOP]: styles.modalTopped,
    [RIGHT]: styles.modalRighted,
    [BOTTOM]: styles.modalBottomed,
    [LEFT]: styles.modalLefted,
};
const Modal = forwardRef((props, ref) => {
    const { onClose, isShowed = false, usePortal = true, title, style, position = TOP, renderModalFooter, stretch = false, wide = false, fitWidthByContent = false, disableClose = false, children, contentClassName, } = props;
    const backdropElement = useRef(null);
    const childrenRef = useRef(null);
    const [isScrollVisible, showScroll, hideScroll] = useToggle();
    function onBackdropClick(e) {
        if (e.target !== backdropElement.current) {
            return;
        }
        if (disableClose) {
            return;
        }
        onClose?.(e);
    }
    function onCloseHandler(e) {
        if (disableClose) {
            return;
        }
        onClose?.(e);
    }
    function onPressEscKey(e) {
        if (e.code !== 'Escape') {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (disableClose) {
            return;
        }
        onClose?.(e);
    }
    function getScrollState() {
        const childrenHeight = childrenRef.current?.offsetHeight;
        const contentHeight = childrenRef.current?.parentNode?.offsetHeight;
        if (childrenHeight && contentHeight && childrenHeight > contentHeight) {
            showScroll();
        }
        else {
            hideScroll();
        }
    }
    function addListeners() {
        document.body.style.position = 'fixed';
        document.addEventListener('keydown', onPressEscKey, false);
        window.addEventListener('resize', getScrollState);
    }
    function removeListeners() {
        document.body.style.position = 'initial';
        document.removeEventListener('keydown', onPressEscKey, false);
        window.removeEventListener('resize', getScrollState);
    }
    useEffect(() => {
        const observer = new MutationObserver(getScrollState);
        if (isShowed) {
            if (childrenRef.current) {
                observer.observe(childrenRef.current, { childList: true, subtree: true });
            }
            getScrollState();
            addListeners();
        }
        else {
            observer.disconnect();
            removeListeners();
        }
        return () => {
            observer.disconnect();
            removeListeners();
        };
    }, [isShowed, disableClose]);
    const modal = isShowed ? (_jsxs("div", { "aria-modal": "true", className: cls(styles.modal, CLASS_POSITION_MAP[position]), role: "dialog", style: style, children: [_jsx("div", { ref: backdropElement, className: styles.modalBackdrop, onClick: onBackdropClick }), _jsxs("div", { className: cls(styles.modalInner, {
                    [styles.modalInnerStretched]: stretch,
                    [styles.modalInnerWide]: wide,
                    [styles.modalInnerFitByContent]: fitWidthByContent,
                }, contentClassName), children: [_jsx("div", { className: styles.modalHeader, children: title && _jsx("h2", { className: styles.modalTitle, children: title }) }), _jsx("div", { className: styles.modalControls, children: onClose && (_jsx(Button, { "aria-label": "Close Modal", color: "transparent", onClick: onCloseHandler, withIcon: true, children: _jsx(Icon, { name: "close" }) })) }), _jsx("div", { className: styles.modalContent, children: _jsx("div", { ref: ref, className: cls(styles.modalContentWrapper, { [styles.modalContentWithScroll]: isScrollVisible }), children: _jsx("div", { ref: childrenRef, className: styles.modalChildren, children: children }) }) }), renderModalFooter ? _jsx("div", { className: styles.modalFooter, children: renderModalFooter() }) : null] })] })) : null;
    return usePortal ? _jsx(ModalPortal, { children: modal }) : modal;
});
export default memo(Modal);
