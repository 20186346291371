import { jsx as _jsx } from "react/jsx-runtime";
import cls from 'classnames';
import Avatar from '../Avatar/Avatar';
import styles from './AvatarsList.module.css';
const SIZE_CLASS_MAP = {
    small: styles.small,
    medium: styles.medium,
    large: styles.large,
};
function AvatarsList(props) {
    const { avatars, size = 'medium' } = props;
    return (_jsx("div", { className: cls(styles.root, SIZE_CLASS_MAP[size]), children: avatars.map((avatar) => (_jsx(Avatar, { size: size, ...avatar }, avatar.username))) }));
}
export default AvatarsList;
