import { toJS } from 'mobx';
import FiltersNormalizer from './FiltersNormalizer';
import Filters from '../../../catalog/entity/Filters';

export default class FiltersJSONSerializer {
  /**
   * @param {Object} filters
   * @return {Object} JSON
   */
  static serialize(filters) {
    return FiltersNormalizer.normalize(toJS(filters));
  }

  /**
   * @param {Object} jsonData
   * @return {Filters}
   */
  static deserialize(jsonData) {
    return Object.assign(new Filters(), jsonData);
  }
}
