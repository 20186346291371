import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { formatDate } from '@/common/helpers/formatDate';
export const FIELD_CREATED_AT = {
    label: 'Added',
    fullLabel: 'Added',
    key: 'createdAt',
    type: FieldType.Scalar,
    getter(p) {
        if (!p.createdAt) {
            return null;
        }
        return formatDate.toHumanDate(p.createdAt);
    },
    getterPlain(p) {
        return p.createdAt || null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
