import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './PatentList.less';
import Link from '../../../../common/components/Link/Link';

export default class PatentList extends Component {
  static propTypes = {
    cutShapePatents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        country: PropTypes.string,
        patentUrl: PropTypes.string,
        position: PropTypes.number,
        title: PropTypes.string,
      }),
    ).isRequired,
  };

  render() {
    return (
      <div className={styles.patentList}>
        {this.props.cutShapePatents.map(patent => (
          <div className={styles.patentListItem} key={patent.id}>
            {patent.country && <div className={styles.patentListItemCountry}>{patent.country}</div>}
            <div className={styles.patentListItemTitle}>
              {patent.patentUrl ? (
                <Link href={patent.patentUrl} external>
                  {patent.title}
                </Link>
              ) : (
                patent.title
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
