export var Context;
(function (Context) {
    Context[Context["Listing"] = 1] = "Listing";
    Context[Context["B2B"] = 2] = "B2B";
    Context[Context["Shared"] = 3] = "Shared";
})(Context || (Context = {}));
export const CONTEXT_LISTING = Context.Listing;
export const CONTEXT_B2B = Context.B2B;
export const CONTEXT_SHARED = Context.Shared;
export const ALL_CONTEXTS = [CONTEXT_B2B, CONTEXT_LISTING];
export const ONLY_LISTING_CONTEXTS = [CONTEXT_LISTING];
