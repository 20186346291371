import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import Certification from '@/common/components/Certification/Certification';
import { ONLY_LISTING_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CERTIFICATE = {
    key: 'cert',
    label: 'Cert.',
    fullLabel: 'Certificate',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        return p instanceof Diamond && p.certificate ? p.certificate : null;
    },
    decorator(v) {
        return v ? _jsx(Certification, { certification: v }) : null;
    },
    iteratee: null,
    contexts: ONLY_LISTING_CONTEXTS,
};
