export var DefaultViewTemplate;
(function (DefaultViewTemplate) {
    DefaultViewTemplate[DefaultViewTemplate["Colorless"] = 16] = "Colorless";
    DefaultViewTemplate[DefaultViewTemplate["Fancy"] = 17] = "Fancy";
    DefaultViewTemplate[DefaultViewTemplate["Rough"] = 15] = "Rough";
    // fixme figure out why it is duplicated and fix
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    DefaultViewTemplate[DefaultViewTemplate["Jewelry"] = 15] = "Jewelry";
    DefaultViewTemplate[DefaultViewTemplate["ColorlessEdit"] = 18] = "ColorlessEdit";
    DefaultViewTemplate[DefaultViewTemplate["FancyEdit"] = 19] = "FancyEdit";
    DefaultViewTemplate[DefaultViewTemplate["RoughEdit"] = 20] = "RoughEdit";
    // fixme figure out why it is duplicated and fix
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    DefaultViewTemplate[DefaultViewTemplate["JewelryEdit"] = 20] = "JewelryEdit";
})(DefaultViewTemplate || (DefaultViewTemplate = {}));
