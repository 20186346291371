export default class CollectionResponse {
  /**
   * @param {[*]} data
   * @param {number} totalCount
   * @param {number} expenses
   * @param {number} nextPayment
   */
  constructor(data, totalCount, expenses, nextPayment) {
    this.data = data;
    this.totalCount = totalCount;
    this.expenses = expenses;
    this.nextPayment = nextPayment;
  }
}
