// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "modal";
var _d = "modalBackdrop";
var _e = "modalBottomed";
var _f = "modalCentered";
var _10 = "modalChildren";
var _11 = "modalContent";
var _12 = "modalContentWithScroll";
var _13 = "modalContentWrapper";
var _14 = "modalControls";
var _15 = "modalFooter";
var _16 = "modalHeader";
var _17 = "modalInner";
var _18 = "modalInnerFitByContent";
var _19 = "modalInnerStretched";
var _1a = "modalInnerWide";
var _1b = "modalTitle";
var _1c = "product-block-grid";
var _1d = "product-block-subgrid";
var _1e = "reset-button";
var _1f = "1200px";
var _20 = "992px";
var _21 = "1366px";
var _22 = "768px";
var _23 = "1475px";
var _24 = "576px";
var _25 = "1600px";
var _26 = "320px";
var _27 = "2500px";
var _28 = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "modal", _d as "modalBackdrop", _e as "modalBottomed", _f as "modalCentered", _10 as "modalChildren", _11 as "modalContent", _12 as "modalContentWithScroll", _13 as "modalContentWrapper", _14 as "modalControls", _15 as "modalFooter", _16 as "modalHeader", _17 as "modalInner", _18 as "modalInnerFitByContent", _19 as "modalInnerStretched", _1a as "modalInnerWide", _1b as "modalTitle", _1c as "product-block-grid", _1d as "product-block-subgrid", _1e as "reset-button", _1f as "screenlg", _20 as "screenmd", _21 as "screenml", _22 as "screensm", _23 as "screenxlg", _24 as "screenxs", _25 as "screenxxlg", _26 as "screenxxs", _27 as "screenxxxlg", _28 as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "modal": _c, "modalBackdrop": _d, "modalBottomed": _e, "modalCentered": _f, "modalChildren": _10, "modalContent": _11, "modalContentWithScroll": _12, "modalContentWrapper": _13, "modalControls": _14, "modalFooter": _15, "modalHeader": _16, "modalInner": _17, "modalInnerFitByContent": _18, "modalInnerStretched": _19, "modalInnerWide": _1a, "modalTitle": _1b, "product-block-grid": _1c, "product-block-subgrid": _1d, "reset-button": _1e, "screenlg": _1f, "screenmd": _20, "screenml": _21, "screensm": _22, "screenxlg": _23, "screenxs": _24, "screenxxlg": _25, "screenxxs": _26, "screenxxxlg": _27, "single-page-responsive-font-size": _28 }
