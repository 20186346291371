import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label, {SECONDARY, SIZE_MEDIUM, SIZE_XLARGE} from '../../../../common/components/Label/Label';
import Product from '../../../entity/Product';

@observer
export default class IsLGDLabel extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    block: PropTypes.bool,
    short: PropTypes.bool,
    size: PropTypes.oneOf([SIZE_MEDIUM, SIZE_XLARGE]),
  };

  static defaultProps = {
    block: false,
    short: true,
    size: SIZE_MEDIUM,
  };

  render() {
    if (!this.props.product || !this.props.product.isLabGrown) {
      return null;
    }

    return (
      <Label appearance={SECONDARY} size={this.props.size} block={this.props.block}>{this.props.short ? 'LGD' : 'Laboratory-Grown Diamond'}</Label>
    );
  }
}
