// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "personalAccessAvatar";
var _d = "personalAccessDescription";
var _e = "personalAccessDescriptionAccess";
var _f = "personalAccessDescriptionIcon";
var _10 = "personalAccessDescriptionName";
var _11 = "personalAccessMore";
var _12 = "personalAccessOptionRemove";
var _13 = "personalAccessUser";
var _14 = "personalAccessUserContent";
var _15 = "product-block-grid";
var _16 = "product-block-subgrid";
var _17 = "reset-button";
var _18 = "1200px";
var _19 = "992px";
var _1a = "1366px";
var _1b = "768px";
var _1c = "1475px";
var _1d = "576px";
var _1e = "1600px";
var _1f = "320px";
var _20 = "2500px";
var _21 = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "personalAccessAvatar", _d as "personalAccessDescription", _e as "personalAccessDescriptionAccess", _f as "personalAccessDescriptionIcon", _10 as "personalAccessDescriptionName", _11 as "personalAccessMore", _12 as "personalAccessOptionRemove", _13 as "personalAccessUser", _14 as "personalAccessUserContent", _15 as "product-block-grid", _16 as "product-block-subgrid", _17 as "reset-button", _18 as "screenlg", _19 as "screenmd", _1a as "screenml", _1b as "screensm", _1c as "screenxlg", _1d as "screenxs", _1e as "screenxxlg", _1f as "screenxxs", _20 as "screenxxxlg", _21 as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "personalAccessAvatar": _c, "personalAccessDescription": _d, "personalAccessDescriptionAccess": _e, "personalAccessDescriptionIcon": _f, "personalAccessDescriptionName": _10, "personalAccessMore": _11, "personalAccessOptionRemove": _12, "personalAccessUser": _13, "personalAccessUserContent": _14, "product-block-grid": _15, "product-block-subgrid": _16, "reset-button": _17, "screenlg": _18, "screenmd": _19, "screenml": _1a, "screensm": _1b, "screenxlg": _1c, "screenxs": _1d, "screenxxlg": _1e, "screenxxs": _1f, "screenxxxlg": _20, "single-page-responsive-font-size": _21 }
