export default class QueryResult {
    content;
    networkResponse;
    cacheHit = false;
    // temporal getter for supporting old interface
    /**
     * @deprecated
     * @return {*}
     */
    get data() {
        return this.content;
    }
    // temporal getter for supporting old interface
    /**
     * @deprecated
     * @return {boolean|*}
     */
    get ok() {
        return this.cacheHit || this.networkResponse?.ok;
    }
    /**
     * @return {number}
     */
    get status() {
        return this.cacheHit ? 200 : this.networkResponse?.status;
    }
}
