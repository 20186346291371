import memoize from 'lodash/memoize';
import {observable} from 'mobx';
import MediaSubFormat from '../entity/MediaSubFormat';

class MediaSubFormatRepository {
  /**
   * @type {[MediaSubFormat]}
   */
  @observable
  mediaSubFormats;

  constructor() {
    this.findMediaSubFormatById = memoize(this.findMediaSubFormatById);
  }

  /**
   * @param {{ id: number, r: ?number }[]} mediaSubFormatsData
   * @return {*}
   */
  initMediaSubFormats(mediaSubFormatsData) {
    this.mediaSubFormats = mediaSubFormatsData.map(d => new MediaSubFormat(d));
  }

  /**
   * @param {number} id
   * @returns {?MediaSubFormat}
   */
  findMediaSubFormatById = (id) => {
    if (!this.mediaSubFormats) {
      return null;
    }

    return this.mediaSubFormats.find(msf => msf.id === id);
  };
}

export const mediaSubFormatRepository = new MediaSubFormatRepository();
