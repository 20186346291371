/*
 * Idea of this conversion is:
 *   weight should have at least two digs after comma and max 4
 *
 *   possible variants:
 *     5.00
 *     5.10
 *     5.11
 *     5.111
 *     5.1111
 * */
export function getScanWeight(weight) {
    const weightValue = Number(weight);
    if (!`${weightValue}`.includes('.')) {
        return `${weightValue.toFixed(2)}`;
    }
    const [, digits] = `${weightValue}`.split('.');
    if (digits.length === 1) {
        return `${weightValue.toFixed(2)}`;
    }
    return `${parseFloat(weightValue.toFixed(4))}`;
}
