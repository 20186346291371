import { toJS } from 'mobx';
import isEqual from 'lodash/isEqual';

export function formDiff(product, form) {
  const formJSON = form.serialize ? form.serialize() : toJS(form);

  const productJSON = product.serialize ? product.serialize() : toJS(product);
  const formObjectKeys = Object.keys(formJSON);

  const diff = formObjectKeys.reduce(
    (result, formKey) => {
      // todo should use toQueryString and deserialize methods in entities
      if (formKey === 'mediaCollection' || formKey === 'certifications' || formKey === 'visibility') {
        return result;
      }

      let formValue = formJSON[formKey];
      let productValue = productJSON[formKey];

      if (formValue instanceof Object && formValue.id) {
        formValue = formValue.id;
      }

      if (productValue instanceof Object && productValue.id) {
        productValue = productValue.id;
      }

      if (productValue === '') {
        productValue = null;
      }

      if (formValue === '') {
        formValue = null;
      }

      const isEqualObjects = productValue instanceof Object && formValue instanceof Object && isEqual(productValue, formValue);
      if (isEqualObjects) {
        return result;
      }

      if (formValue !== productValue || (formValue === null && productValue === undefined)) {
        if (!result) {
          result = {};
        }
        result[formKey] = formValue;
      }
      return result;
    },
    null,
  );

  return diff;
}
