import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cutShapesSVG from 'assets/cutShapesSVG';
import './ProductPlaceholder.less';

export const COLOR_DEFAULT = 'gray';
export const COLOR_OUTLINE_BROWN = 'outline-brown';
export const COLOR_OUTLINE_GREEN = 'outline-green';
export const COLOR_OUTLINE = 'outline';

const DEFAULT_SHAPE = 2;

export default class ProductPlaceholder extends PureComponent {
  static propTypes = {
    shape: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    bold: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    color: COLOR_DEFAULT,
    shape: DEFAULT_SHAPE,
    bold: false,
    width: null,
    height: null,
  };

  /**
   * @return {ReactElement}
   */
  render() {
    const bold = this.props.bold;
    const gemSVG = cutShapesSVG[this.props.shape] || cutShapesSVG[DEFAULT_SHAPE];

    return <svg width={this.props.width} height={this.props.height} className={`gem gem-${this.props.shape} gem-${this.props.color} ${bold ? 'gem--bold' : ''}`} viewBox={gemSVG.viewBox} dangerouslySetInnerHTML={{ __html: gemSVG.body }} />;
  }
}
