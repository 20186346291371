import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cls from 'classnames'; // --> OFF
import Link, { NONE } from '../Link/Link';
import Loader, { SMALL, WHITE, X_SMALL } from '../Loader/Loader';
import styles from './Button.less';
export const SIZE_XSMALL = 'xs';
export const SIZE_SMALL = 's';
export const SIZE_MEDIUM = 'm';
export const SIZE_XLARGE = 'xl';
export const DEFAULT = 'default';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const QUATERNARY = 'quaternary';
export const ACCENT = 'accent';
export const GHOST = 'ghost';
export const GHOST_WARNING = 'ghost-warning';
export const TRANSPARENT = 'transparent';
export const BORDERED = 'bordered';
export const NEUTRAL_NOT_SO_LIGHT = 'neutral-not-so-light';
export const TEXT_COLOR_DARK = 'text-color-dark';
export const TEXT_COLOR_LIGHT = 'text-color-light';
export const ACTIVE_COLOR_DEFAULT = 'active-default';
export const ACTIVE_COLOR_AMBIENT = 'active-ambient';
export const LEFT_ICON_POSITION = 'left';
export const RIGHT_ICON_POSITION = 'right';
const CLASS_COLOR_MAP = {
    [DEFAULT]: styles.buttonDefault,
    [PRIMARY]: styles.buttonPrimary,
    [SECONDARY]: styles.buttonSecondary,
    [TERTIARY]: styles.buttonTertiary,
    [QUATERNARY]: styles.buttonQuaternary,
    [ACCENT]: styles.buttonAccent,
    [GHOST]: styles.buttonGhost,
    [GHOST_WARNING]: styles.buttonGhostWarning,
    [TRANSPARENT]: styles.buttonTransparent,
    [BORDERED]: styles.buttonBordered,
    [NEUTRAL_NOT_SO_LIGHT]: styles.buttonNeutralNotSoLight,
};
const CLASS_TEXT_COLOR_MAP = {
    [TEXT_COLOR_DARK]: styles.buttonTextColorDark,
    [TEXT_COLOR_LIGHT]: styles.buttonTextColorLight,
};
const CLASS_ICON_POSITION_MAP = {
    [LEFT_ICON_POSITION]: styles.buttonIconLeft,
    [RIGHT_ICON_POSITION]: styles.buttonIconRight,
};
export default function Button(props) {
    const { type = 'button', color = DEFAULT, block = false, silent = false, size = SIZE_MEDIUM, active = null, activeColor = ACTIVE_COLOR_DEFAULT, className, contentClassName, disabled = false, textColor, withIcon = false, withImage = false, iconPosition = null, href = null, externalURL = false, target = null, withoutPaddings = false, loading = false, textOverflow, wide = false, form, children, ...rest } = props;
    const btnClassName = cls(styles.btn, CLASS_COLOR_MAP[color], textColor ? CLASS_TEXT_COLOR_MAP[textColor] : null, { 'btn--icon': withIcon, 'btn--image': withImage }, `btn--${size}`, {
        'btn--block': block,
        'btn--active': active,
        'btn--active-default': active && activeColor === ACTIVE_COLOR_DEFAULT,
        'btn--active-ambient': active && activeColor === ACTIVE_COLOR_AMBIENT,
        'btn--disabled': disabled,
        'btn--without-paddings': withoutPaddings,
        'btn--silent': silent,
        'btn--text-eillipsis': textOverflow === 'ellipsis',
        [styles.btnWide]: wide,
        [styles.btnLoading]: loading,
    }, className);
    if (active !== null) {
        rest['aria-pressed'] = active;
    }
    const content = (_jsxs(React.Fragment, { children: [_jsx("span", { className: cls(styles.btnContentWrapper, iconPosition ? CLASS_ICON_POSITION_MAP[iconPosition] : null, { [styles.btnHideChildren]: loading }, contentClassName), children: children }), loading && (_jsx(Loader, { appearance: WHITE, className: styles.btnLoader, size: size === SIZE_XSMALL ? X_SMALL : SMALL }))] }));
    if (href) {
        if (externalURL || href.startsWith('http') || href.startsWith('mailto')) {
            return (_jsx(Link, { className: btnClassName, href: href, target: target, ...rest, appearance: NONE, children: content }));
        }
        return (_jsx(Link, { className: btnClassName, to: href, ...rest, appearance: NONE, children: content }));
    }
    return (_jsx("button", { className: btnClassName, form: form, type: type, ...rest, children: content }));
}
