import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_GIRDLE = {
    label: 'Girdle',
    fullLabel: 'Girdle Grade',
    key: 'girdle',
    type: FieldType.Scalar,
    icon: 'metrics-girdle',
    getter(p, short = false) {
        if (p instanceof Diamond && p.girdleThicknessGrade) {
            let resultName = short ? p.girdleThicknessGrade.short : p.girdleThicknessGrade.title;
            if (p.girdleThicknessMax) {
                resultName += ` – ${short ? p.girdleThicknessMax.short : p.girdleThicknessMax.title}`;
            }
            return resultName;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.girdleThicknessGrade) {
            return p.girdleThicknessGrade.id;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.girdleThicknessGrade) {
            return p.girdleThicknessGrade.position * -1;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
