import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
import FluMetricBar from '@/metric/components/FluMetricBar/FluMetricBar';
import MetricScaleContainer, { SIZE_MEDIUM, SIZE_XSMALL, } from '@/metric/components/MetricScaleContainer/MetricScaleContainer';
import Diamond from '@/product/entity/Diamond';
export const FIELD_FLUOR = {
    key: 'fluor.val',
    label: 'Face-up Fluorescence',
    fullLabel: 'Face-up Fluorescence',
    type: FieldType.Metric,
    spGroupId: SetupPresetGroupType.FlourTable,
    hasAbsoluteValue: false,
    icon: 'metrics-fluor-table',
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.fluorMetric) {
            return p.fluorMetric.compositeVal;
        }
        return null;
    },
    decorator: (_, p, { ratio, size, label, icon } = {}) => {
        if (p instanceof Diamond) {
            return (_jsx(FluMetricBar, { metric: p.fluorMetric, ratio: ratio, children: (scale, abs) => {
                    let modifiedABS = abs;
                    if (size !== SIZE_XSMALL && p.fluorMetric) {
                        modifiedABS = p.fluorMetric.colorTitle;
                    }
                    if (size === SIZE_MEDIUM) {
                        modifiedABS = '';
                    }
                    return (_jsx(MetricScaleContainer, { abs: modifiedABS, icon: icon, isMetricCalculated: Boolean(p.fluorMetric), label: label, scale: scale, size: size }));
                } }));
        }
        return null;
    },
    iteratee(p) {
        if (p instanceof Diamond && p.fluorMetric) {
            return p.fluorMetric.compositeVal;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
