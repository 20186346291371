import {dictionaryRepository} from '../../common/repositories/DictionaryRepository';

export default class HpoColorPart {
  /**
   * @type {DictionaryItem}
   */
  dictGrade;

  /**
   * @type {number}
   */
  percent;

  /**
   * @param {DictionaryItem} dictGrade
   * @param {number} percent
   */
  constructor({ dictGrade, percent }) {
    this.dictGrade = dictGrade;
    this.percent = percent;
  }

  /**
   * @return {?number}
   */
  getIndex() {
    return dictionaryRepository.getIndexByColorTitle(this.dictGrade.title);
  }

  /**
   * @return {boolean}
   */
  isMain() {
    return this.percent > 55;
  }

  /**
   * @return {string}
   */
  format() {
    return `${this.dictGrade.title} ${this.percent}%`;
  }
}
