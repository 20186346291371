import { action, observable } from 'mobx';
import CustomGradingAPI from '../api/CustomGradingAPI';
import CustomGradeAttribute from '../entity/CustomGradeAttribute';

export default class CustomGradeAttributeStore {
  /**
   * @type {CustomGradingAPI}
   */
  customGradingAPI;

  @observable
  attributes = {};

  @observable
  promises = {};

  constructor() {
    this.customGradingAPI = new CustomGradingAPI();
  }

  /**
   * @param {string} customGradeCode
   * @return {PromiseLike<[CustomGradingDictEntry]>}
   */
  fetchItem = (customGradeCode) => {
    if (this.promises[customGradeCode]) {
      return this.promises[customGradeCode];
    }

    this.promises[customGradeCode] = this.customGradingAPI.fetchCustomGradeAttribute(customGradeCode)
      .then(action((res) => {
        this.attributes[customGradeCode] = new CustomGradeAttribute({id: customGradeCode, ...res.content});

        return this.attributes[customGradeCode];
      }));

    return this.promises[customGradeCode];
  };
}

export const customGradeAttributeStore = new CustomGradeAttributeStore();
