import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './Link.less';

export const REGULAR = 'regular';
export const NAV = 'nav';
export const NONE = 'none';

const CLASS_MAP = {
  [REGULAR]: styles.link,
  [NAV]: styles.nav,
  [NONE]: '',
};

export default class Link extends Component {
  static propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    className: PropTypes.string,
    appearance: PropTypes.oneOf([REGULAR, NAV, NONE]),
    external: PropTypes.bool,
  };

  static defaultProps = {
    to: null,
    href: null,
    className: null,
    appearance: REGULAR,
    external: false,
  };

  /**
   * @return {ReactElement}
   */
  render() {
    const {appearance, external, ...rest} = this.props;
    const newProps = rest;

    if (external) {
      newProps.target = '_blank';
    }

    const className = `${CLASS_MAP[appearance]} ${!newProps.className ? '' : newProps.className}`;

    if (newProps.to) {
      return (
        <NavLink
          {...newProps}
          className={className}
        />
      );
    }

    return (
      <a
        {...newProps}
        className={className}
      />
    );
  }
}
