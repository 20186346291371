export class B2BModel {
    id;
    title;
    account;
}
export class B2B extends B2BModel {
    constructor({ id, title }) {
        super();
        this.id = id;
        this.title = title;
    }
}
