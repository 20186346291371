import { TYPE_DIAMOND_COLORLESS, TYPE_JEWELRY, TYPE_ROUGH } from '@/product/constants/productTypes';
export var CollectionType;
(function (CollectionType) {
    CollectionType[CollectionType["Diamond"] = 1] = "Diamond";
    CollectionType[CollectionType["Jewelry"] = 2] = "Jewelry";
    CollectionType[CollectionType["Rough"] = 3] = "Rough";
})(CollectionType || (CollectionType = {}));
// function extracted from old CollectionDetailsStore.updateProductTypeContext method
// and used for same logic in SurveyStore. It is not using fancy and lgd product types,
// which is maybe wrong for general usage by fine for setting productTypeContext
export function toProductTypeContext(collectionType) {
    let productType = TYPE_DIAMOND_COLORLESS;
    if (collectionType === CollectionType.Jewelry) {
        productType = TYPE_JEWELRY;
    }
    if (collectionType === CollectionType.Rough) {
        productType = TYPE_ROUGH;
    }
    return productType;
}
