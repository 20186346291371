import { computed } from 'mobx';
import {dictionaryRepository} from '../../common/repositories/DictionaryRepository';

export default class FluorMetric {
  /**
   * @type {number}
   */
  f;

  /**
   * @type {number}
   */
  m;

  /**
   * @type {number}
   */
  n;

  /**
   * @type {number}
   */
  s;

  /**
   * @type {string}
   */
  ver;

  /**
   * @type {number}
   */
  vs;

  /**
   * @type {number}
   */
  color;

  /**
   * @type {number}
   */
  grade;

  /**
   *
   * @param {number} f
   * @param {number} m
   * @param {number} n
   * @param {number} s
   * @param {string} ver
   * @param {number} vs
   * @param {number} color
   */
  constructor({
    f, m, n, s, ver, vs, color,
  }) {
    this.f = Math.round(f * 100);
    this.m = Math.round(m * 100);
    this.n = Math.round(n * 100);
    this.s = Math.round(s * 100);
    this.ver = ver;
    this.vs = Math.round(vs * 100);
    this.color = color;
  }

  @computed
  get compositeVal() {
    const vars = [];

    if (this.vs > 0) {
      vars.push(`VS${this.vs}%`);
    }
    if (this.s > 0) {
      vars.push(`S${this.s}%`);
    }
    if (this.m > 0) {
      vars.push(`M${this.m}%`);
    }
    if (this.f > 0) {
      vars.push(`F${this.f}%`);
    }
    if (this.n > 0) {
      vars.push(`N${this.n}%`);
    }

    const intensity = vars.join(' / ');

    const result = [intensity];

    if (this.colorTitle) {
      result.push(this.colorTitle);
    }

    return result.join(' ');
  }

  @computed
  get colorTitle() {
    if (!this.color) {
      return '';
    }

    const dictColorValue = dictionaryRepository.getFluorescenceColorById(this.color);

    if (!dictColorValue) {
      return '';
    }

    if (dictColorValue.title.toLowerCase() === 'none') {
      return '';
    }

    return dictColorValue.title;
  }
}
