/**
 * @see https://octonus-teams.com/jira/browse/CUT-7019
 * @param {Query} query
 * @return {Query}
 */
import {dictionaryRepository} from '../../../common/repositories/DictionaryRepository';

export default (query) => {
  const filters = query.queryStringParams.filters;

  if (!filters.cutShape) {
    return query;
  }

  const resultedShapesList = [];
  filters.cutShape.forEach((shapeId) => {
    resultedShapesList.push(shapeId);
    const shape = dictionaryRepository.getCutShapeById(shapeId);

    if (shape && shape.children && shape.children.length > 0) {
      resultedShapesList.push(...shape.children.map(c => c.id));
    }
  });

  filters.cutShape = resultedShapesList;

  return query;
};
