// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "gCQ4o1VgC3goi7UQYCos";
var _d = "Pr0XZX1_sZVwqxSyKOVa";
var _e = "aaZ735A1r1aeORFIKOnc";
var _f = "WBrG0HZHlPEuntkny38_";
var _10 = "P9YbrcXYkK8TzXPCMxwC";
var _11 = "EArXry_GZSn2vLlfCNw7";
var _12 = "1200px";
var _13 = "992px";
var _14 = "1366px";
var _15 = "768px";
var _16 = "1475px";
var _17 = "576px";
var _18 = "1600px";
var _19 = "320px";
var _1a = "2500px";
var _1b = "yxByVbig2bI2XvfECMeg";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "heading", _d as "headingBright", _e as "headingUppercase", _f as "product-block-grid", _10 as "product-block-subgrid", _11 as "reset-button", _12 as "screenlg", _13 as "screenmd", _14 as "screenml", _15 as "screensm", _16 as "screenxlg", _17 as "screenxs", _18 as "screenxxlg", _19 as "screenxxs", _1a as "screenxxxlg", _1b as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "heading": _c, "headingBright": _d, "headingUppercase": _e, "product-block-grid": _f, "product-block-subgrid": _10, "reset-button": _11, "screenlg": _12, "screenmd": _13, "screenml": _14, "screensm": _15, "screenxlg": _16, "screenxs": _17, "screenxxlg": _18, "screenxxs": _19, "screenxxxlg": _1a, "single-page-responsive-font-size": _1b }
