export default class ToFormDataSerializationStrategy {
  /**
   * @param {Object} data
   */
  static serialize(data) {
    if (!data) {
      return null;
    }

    const formData = new FormData();
    Object.keys(data).forEach((dataObjectKey) => {
      formData.append(dataObjectKey, data[dataObjectKey]);
    });

    return formData;
  }

  /**
   * @return {null}
   */
  static contentType() {
    return null;
  }
}
