export const DATA_ACCESS_TITLE_MAP = {
    product: 'Cut Data',
    project: 'Project Data',
};
export var ProductDataAccess;
(function (ProductDataAccess) {
    ProductDataAccess[ProductDataAccess["HideAllDesignData"] = 0] = "HideAllDesignData";
    ProductDataAccess[ProductDataAccess["HideModelReports"] = 1] = "HideModelReports";
    ProductDataAccess[ProductDataAccess["ShowAllData"] = 2] = "ShowAllData";
})(ProductDataAccess || (ProductDataAccess = {}));
export var ProjectDataAccess;
(function (ProjectDataAccess) {
    ProjectDataAccess[ProjectDataAccess["HideProjectSource"] = 0] = "HideProjectSource";
    ProjectDataAccess[ProjectDataAccess["ShowAllData"] = 1] = "ShowAllData";
})(ProjectDataAccess || (ProjectDataAccess = {}));
const DATA_ACCESS_PRODUCT_ICON = {
    [ProductDataAccess.HideAllDesignData]: 'full-protect',
    [ProductDataAccess.HideModelReports]: 'protect',
    [ProductDataAccess.ShowAllData]: 'show-all',
};
export const PRODUCT_HIDE_ALL_DESIGN = {
    id: ProductDataAccess.HideAllDesignData,
    title: 'Hide All Design Data',
    description: 'Hide proportion images, symmetry images, facet slope angles, reports and 3d model.',
    icon: DATA_ACCESS_PRODUCT_ICON[ProductDataAccess.HideAllDesignData],
};
export const PRODUCT_HIDE_MODEL_REPORTS = {
    id: ProductDataAccess.HideModelReports,
    title: 'Hide Model & Reports',
    description: 'Hide facet slopes angles, reports and 3d model. Cut can possibly be reproduced from proportions images.',
    icon: DATA_ACCESS_PRODUCT_ICON[ProductDataAccess.HideModelReports],
};
export const PRODUCT_SHOW_ALL_DATA = {
    id: ProductDataAccess.ShowAllData,
    title: 'Show All Data',
    description: 'All Cut Data, including model and report is available to any user with access.',
    icon: DATA_ACCESS_PRODUCT_ICON[ProductDataAccess.ShowAllData],
};
const DATA_ACCESS_PROJECT_ICON = {
    [ProjectDataAccess.HideProjectSource]: 'protect',
    [ProjectDataAccess.ShowAllData]: 'show-all',
};
export const PROJECT_HIDE_PROJECT_SOURCE = {
    id: ProjectDataAccess.HideProjectSource,
    title: 'Hide Project Source',
    description: 'Hide HPCarbon data file (.ox2z) is not shown. Only solution information from Cutwise and CarbonViewer is available to users',
    icon: DATA_ACCESS_PROJECT_ICON[ProjectDataAccess.HideProjectSource],
};
export const PROJECT_SHOW_ALL_DATA = {
    id: ProjectDataAccess.ShowAllData,
    title: 'Show All Data',
    description: 'HPCarbon data file (.ox2z) is available to any user with access. Solution parameters and proportions can be viewed in HPC.',
    icon: DATA_ACCESS_PROJECT_ICON[ProjectDataAccess.ShowAllData],
};
const PROJECT_DATA_ACCESS = [PROJECT_HIDE_PROJECT_SOURCE, PROJECT_SHOW_ALL_DATA];
const PRODUCT_DATA_ACCESS = [
    PRODUCT_HIDE_ALL_DESIGN,
    PRODUCT_HIDE_MODEL_REPORTS,
    PRODUCT_SHOW_ALL_DATA,
];
export function getDataAccessByType(type) {
    if (type === 'product') {
        return PRODUCT_DATA_ACCESS;
    }
    if (type === 'project') {
        return PROJECT_DATA_ACCESS;
    }
    return [];
}
export function getDataAccessById(id, type) {
    return getDataAccessByType(type).find((dataAccess) => dataAccess.id === id);
}
export function getDataAccessIcon(level, type) {
    if (type === 'product') {
        return DATA_ACCESS_PRODUCT_ICON[level];
    }
    if (type === 'project') {
        return DATA_ACCESS_PROJECT_ICON[level];
    }
    return '';
}
