import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_SYMMETRY = {
    label: 'Symmetry',
    fullLabel: 'Symmetry',
    key: 'symmetry',
    type: FieldType.Scalar,
    icon: 'metrics-symmetry',
    getter(p, short = false) {
        if (p instanceof Diamond && p.symmetry) {
            return short ? p.symmetry?.short : p.symmetry?.title;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.symmetry) {
            return p.symmetry.position;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.symmetry) {
            return p.symmetry.position * -1;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
