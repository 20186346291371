import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { SETUP_PRESET_GROUP_DARKFIELD } from '@/media/entity/SetupPresetGroup';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CLARITY = {
    label: 'Clarity',
    fullLabel: 'Clarity',
    key: 'clarity',
    type: FieldType.Scalar,
    spGroupId: SETUP_PRESET_GROUP_DARKFIELD,
    icon: 'metrics-clarity',
    getter(p, short = false) {
        if (p instanceof Diamond && p.clarity) {
            return short ? p.clarity?.short : p.clarity?.title;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.clarity) {
            return p.clarity.position;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.clarity) {
            return p.clarity.position * -1;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
