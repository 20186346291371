import { isObject } from '@cutwise/utils';
import { getCompareContextByType } from '@/comparison/helpers/getCompareContextByType';
import ProductFactory from '@/product/entity/ProductFactory';
export default class ComparisonListSerializer {
    static serialize(productList) {
        const jsonProductList = {};
        if (!isObject(productList)) {
            return jsonProductList;
        }
        Object.keys(productList).forEach((productType) => {
            const type = getCompareContextByType(productType);
            if (productList[type]) {
                jsonProductList[type] = productList[type].map((item) => item.id);
            }
        });
        return jsonProductList;
    }
    static deserialize(jsonProductList) {
        const productList = {};
        if (!jsonProductList || !isObject(jsonProductList)) {
            return productList;
        }
        const productTypes = Object.keys(jsonProductList);
        productTypes.forEach((productType) => {
            const type = getCompareContextByType(productType);
            productList[type] = [];
            if (!jsonProductList[type]) {
                jsonProductList[type] = [];
            }
            jsonProductList[type].forEach((productId) => {
                productList[type]?.push(ProductFactory.make(type, { id: productId }));
            });
        });
        return productList;
    }
}
