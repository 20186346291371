import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductPrice.less';
import Product from '../../entity/Product';

export default class ProductPrice extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    align: PropTypes.oneOf(['right', 'left']),
  };

  static defaultProps = {
    align: 'right',
  };

  render() {
    if (!this.props.product.price) {
      return null;
    }

    return (
      <div className={`${styles.productPrice} ${this.props.align === 'right' ? styles.productPriceRightAligned : styles.productPriceLeftAligned}`}>
        <div className={styles.productPriceABS}>
          $
          {this.props.product.price.toFixed(0)}
        </div>
        <div className={styles.productPriceCt}>
          $
          {' '}
          {this.props.product.priceCt.toFixed(0)}
          /ct
        </div>
      </div>
    );
  }
}
