export default class HpoColor {
  /**
   * @type {HpoColorPart[]}
   */
  parts;

  /**
   * @param {HpoColorPart[]} parts
   * @returns {HpoColor}
   */
  setParts(parts) {
    this.parts = parts;

    return this;
  }

  /**
   * @return {string}
   */
  format() {
    const result = this.parts.map(mt => mt.format());

    return `${result.join(' / ')}`;
  }

  /**
   * @return {?number}
   */
  getPart1Index() {
    return this.parts[0] ? this.parts[0].getIndex() : null;
  }

  /**
   * @return {number}
   */
  getPart2Percent() {
    return this.parts[1] ? this.parts[1].percent / 100 : 0;
  }
}
