import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import cls from 'classnames';
import AvatarsList from '@/common/components/AvatarsList/AvatarsList';
import Icon from '@/common/components/Icon/Icon';
import { useToggle } from '@/common/hooks/useToggle/useToggle';
import PrivateAccessModal from '../PrivateAccessModal/PrivateAccessModal';
import styles from './ACLStatus.module.css';
const SIZE_CLASS_MAP = {
    small: styles.aclAddButtonSmall,
    medium: styles.aclAddButtonMedium,
    large: styles.aclAddButtonLarge,
};
function ACLStatus(props) {
    const { product, project, size = 'medium', isCreatable, onUpdate } = props;
    const [isPersonalShareModalOpen, openPersonalShareModal, closePersonalShareModal] = useToggle();
    const entity = (product ?? project);
    if (!entity) {
        return null;
    }
    const type = product ? 'product' : 'project';
    const acl = (entity?.acl ?? []).slice(0, 3);
    function onClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!isCreatable) {
            return;
        }
        openPersonalShareModal();
    }
    return (_jsxs(Fragment, { children: [_jsxs("div", { className: cls(styles.aclStatus, { [styles.interactive]: isCreatable }), onClick: onClickHandler, children: [isCreatable && acl.length === 0 && (_jsx("button", { className: cls(styles.aclAddButton, SIZE_CLASS_MAP[size]), type: "button", children: _jsx(Icon, { className: styles.aclAddButtonIcon, name: "add-person" }) })), acl.length > 0 && (_jsx(AvatarsList, { avatars: acl.map(({ assignee }) => ({ username: assignee.name })), size: size }))] }), _jsx(PrivateAccessModal, { entities: [entity], isOpen: isPersonalShareModalOpen, onClose: closePersonalShareModal, onSuccess: onUpdate, type: type })] }));
}
export default ACLStatus;
