import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_POLISH = {
    label: 'Polish',
    fullLabel: 'Polish',
    key: 'polish',
    type: FieldType.Scalar,
    icon: 'metrics-polish',
    getter(p, short = false) {
        if (p instanceof Diamond && p.polish) {
            return short ? p.polish.short : p.polish.title;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.polish) {
            return p.polish.position;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.polish) {
            return p.polish.position * -1;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
