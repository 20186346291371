import ToJSONSerializationStrategy from './serialization/ToJSONSerializationStrategy';
import {SECURITY_CHECK_ACL} from '../../security/constants/securityCheck';

export default class Query {
  /**
   * @type {string}
   */
  path;

  /**
   * @type {?string}
   */
  method = null;

  /**
   * @type {?Object}
   */
  requestBody = null;

  /**
   * @type {Object}
   */
  queryStringParams = {};

  /**
   * @type {ToJSONSerializationStrategy|ToFormDataSerializationStrategy}
   */
  serializer = ToJSONSerializationStrategy;

  /**
   * @type {QueryResult}
   */
  result = null;

  /**
   * @type {Object}
   */
  headers = {};

  /**
   * @type {[function]}
   */
  requestMiddlewares = [];

  /**
   * @type {[function]}
   */
  responseMiddlewares = [];

  /**
   * @type {boolean}
   */
  suppressErrorNotifications = false;

  /**
   * @type {number}
   */
  repeatRequestTimeout = 0;

  /**
   * @type {number}
   */
  repeatRequestRetryTimeout = 0;

  /**
   * @type {function}
   */
  hydrator = null;

  /**
   * @type {string}
   */
  securityCheck = SECURITY_CHECK_ACL;

  /**
   * @type {string}
   */
  responseType = 'json';

  /**
   * @param {string} key
   * @param {*} value
   */
  addQueryStringParam(key, value) {
    this.queryStringParams[key] = value;
  }

  /**
   * @param {Partial<Object>} failSettings
   * @param {number} failSettings.retryTimeout - timeout between retries
   * @param {number} failSettings.requestTimeout - total time of repeating request
   */
  addFailConfiguration(failSettings) {
    const requestTimeout = Number(failSettings.requestTimeout);
    const retryTimeout = Number(failSettings.retryTimeout);

    if (typeof requestTimeout === 'number' && (requestTimeout === 0 || requestTimeout > 0)) {
      this.repeatRequestTimeout = requestTimeout;
    }

    if (typeof retryTimeout === 'number' && (retryTimeout === 0 || retryTimeout > 0)) {
      this.repeatRequestRetryTimeout = retryTimeout;
    }
  }

  /**
   * @param {string} key
   * @param {*} value
   */
  addHeader(key, value) {
    this.headers[key] = value;
  }

  /**
   * @param {function} middleware
   */
  addRequestMiddleware(middleware) {
    this.requestMiddlewares.push(middleware);
  }

  /**
   * @param {function} middleware
   */
  addResponseMiddleware(middleware) {
    this.responseMiddlewares.push(middleware);
  }

  /**
   * @param {string} securityCheck
   */
  setSecurityCheck(securityCheck) {
    this.securityCheck = securityCheck;
  }

  /**
   * @return {*}
   */
  getCacheId() {
    return this.path;
  }
}
