import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './MenuItem.module.css';
function MenuItem(props) {
    const { onClick, active, disabled, className, children } = props;
    return (_jsx("div", { className: cls(styles.menuItem, {
            [styles.menuItemClickable]: Boolean(onClick),
            [styles.menuItemDisabled]: disabled,
            [styles.menuItemActive]: active,
        }, className), onClick: () => onClick?.(), children: children }));
}
export default memo(MenuItem);
