import UserAPI from '../../../user/api/UserAPI';

export default class APIDriver {
  constructor() {
    this.userAPI = new UserAPI();
  }

  /**
   * @param {string} key
   * @returns {Promise<QueryResult | never>}
   */
  get(key) {
    return this.userAPI.fetchSettingsByKey(key).then(res => res.data);
  }

  set(key, value) {
    const nestedObjectBasedOnKey = {};
    const keyChain = key.split('.');

    keyChain.reduce((accamulator, subkey, i) => {
      accamulator[subkey] = i === keyChain.length - 1 ? value : {};
      return accamulator[subkey];
    }, nestedObjectBasedOnKey);

    return this.userAPI.patchSettings(nestedObjectBasedOnKey);
  }

  // todo
  onKeyChange() {}
}
