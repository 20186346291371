import { NETBOX_DOMAIN } from '@/netbox/constants/network';
import LoadingTask from '../entity/LoadingTask';
export default class ProxyCache {
    static saveProducts(products, title, sizes = [160, 320, 480, 640, 960], setupPresetIdList = null) {
        const allURLs = [];
        const allPromises = [];
        products.forEach((product) => {
            let urlsToSave = [];
            const promises = [];
            if (!product || product.isLoaded === false || !product.mediaCollection || !product.mediaCollection.mediaItems) {
                return;
            }
            product.mediaCollection.mediaItems.forEach((m) => {
                if (setupPresetIdList && (!m.setupPreset || !setupPresetIdList.includes(m.setupPreset.id))) {
                    return;
                }
                sizes.forEach((size) => {
                    const subfile = m.mainMediaSource?.getSubfileByMediaSize(size);
                    if (!subfile) {
                        return;
                    }
                    const ext = subfile.externalLink.substr(subfile.externalLink.lastIndexOf('.') + 1);
                    if (ext === 'json') {
                        promises.push(fetch(subfile.externalLink).then((res) => res.json()));
                    }
                    else {
                        urlsToSave.push(subfile.externalLink);
                    }
                });
            });
            const productPromise = Promise.all(promises)
                .then((results) => {
                results.forEach((result) => {
                    urlsToSave = [
                        ...urlsToSave,
                        ...result.frames.map((f) => {
                            if (f.f) {
                                return result.baseUri + f.f;
                            }
                            return null;
                        }),
                    ];
                });
            })
                .then(() => {
                allURLs.push(urlsToSave.filter((url) => Boolean(url)));
            });
            allPromises.push(productPromise);
        });
        return Promise.all(allPromises)
            .then(() => fetch(`${NETBOX_DOMAIN}/cache/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: title,
                payload: products.map((product, i) => ({ productId: product.id, uri: allURLs[i] })),
            }),
        }))
            .then((res) => res.json())
            .then((responseJSON) => {
            if (responseJSON) {
                return new LoadingTask(responseJSON);
            }
            return responseJSON;
        });
    }
}
