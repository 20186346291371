import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import { useFormik } from 'formik';
import { URL_PRODUCT_PERMISSIONS_DOC } from '@/catalog/constants/urls';
import Avatar from '@/common/components/Avatar/Avatar';
import Button, { NEUTRAL_NOT_SO_LIGHT, PRIMARY } from '@/common/components/Button/Button';
import ButtonContainer from '@/common/components/ButtonContainer/ButtonContainer';
import DocumentationLink from '@/common/components/DocumentationLink/DocumentationLink';
import Heading from '@/common/components/Heading/Heading';
import Icon from '@/common/components/Icon/Icon';
import Checkbox from '@/common/components/inputs/Checkbox/Checkbox';
import FancySelect from '@/common/components/inputs/FancySelect/FancySelect';
import FormGroup from '@/common/components/inputs/FormGroup/FormGroup';
import FormLabel from '@/common/components/inputs/FormGroup/FormLabel/FormLabel';
import Loader, { MEDIUM } from '@/common/components/Loader/Loader';
import Modal, { CENTER } from '@/common/components/Modal/Modal';
import { pureStructureFrom } from '@/common/helpers/pureStructureFrom';
import Notifier from '@/common/services/Notifier';
import { useAppStore } from '@/common/stores/AppStore';
import { getDataAccessById, getDataAccessByType, getDataAccessIcon, ProductDataAccess, ProjectDataAccess, } from '../../../constants/dataAccess';
import { ACLAssignee } from '../../../entities/ACLAssignee';
import { aclAssigneeToOption } from '../../../helpers/aclAssigneeToOption';
import validateEmail from '../../../helpers/validateEmail';
import { usePrivateAccessStore } from '../../../stores/PrivateAccessStore';
import styles from './PrivateAccessModalContent.less';
import PrivateAccessUser from './PrivateAccessUser/PrivateAccessUser';
let loadUserTimeout;
const DEFAULT_FORM = {
    assignee: [],
    recursive: false,
    dataAccess: undefined,
};
function PrivateAccessModalContent(props) {
    const { isOpen, onClose, onSuccess } = props;
    const { isPhone } = useAppStore();
    const { isFetching, isUpdating, shouldDisableDataAccess, type, entitiesCount, users, aclByEntityDiff, assigneeOptions, getPrivateAccessData, findUserByEmail, addACLByAssignee, updateACl, reset, dispose, } = usePrivateAccessStore();
    const [inputValue, setInputValue] = useState('');
    const dataAccessOptions = useMemo(() => getDataAccessByType(type).map((level) => ({
        value: level.id,
        label: level.title,
        icon: level.icon,
        description: level.description,
    })), [type]);
    const form = useFormik({
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        initialValues: DEFAULT_FORM,
        onSubmit: (v, { resetForm }) => {
            try {
                addACLByAssignee(v.assignee, v.dataAccess, v.recursive);
                resetForm({
                    values: pureStructureFrom(DEFAULT_FORM),
                });
            }
            catch (e) {
                Notifier.error(e);
            }
        },
    });
    function loadUser(email, callback) {
        const value = email.trim();
        const existAssigneeList = assigneeOptions.filter((option) => option.assignee?.email?.includes(value));
        if (existAssigneeList.length > 0) {
            callback(existAssigneeList);
            return;
        }
        if (!validateEmail(value)) {
            callback([]);
            return;
        }
        clearTimeout(loadUserTimeout);
        loadUserTimeout = setTimeout(() => {
            findUserByEmail(value)
                .then((user) => {
                callback(user ? [aclAssigneeToOption(new ACLAssignee(user))] : []);
            })
                .catch(() => {
                callback([]);
            });
        }, 300);
    }
    function renderUserOption(option) {
        const { label } = option.data;
        return (_jsxs("div", { className: styles.userOption, children: [_jsx(Avatar, { size: "large", username: label }), _jsx("div", { className: styles.userOptionDescription, children: _jsx("div", { className: styles.userOptionName, children: label }) })] }));
    }
    function renderSelectedUserOption(option) {
        const { assignee } = option.data;
        const username = assignee.username ?? assignee.email;
        return (_jsxs("div", { className: styles.userSelectedOption, children: [_jsx(Avatar, { size: "small", username: username }), _jsx("span", { className: styles.userSelectedOptionName, children: username })] }));
    }
    function onInputChangeHandler(value, { action }) {
        if (action === 'menu-close') {
            return;
        }
        setInputValue(value.trim());
    }
    function onUpdateHandler() {
        updateACl().then((entities) => {
            onCloseHandler();
            onSuccess?.(entities);
        });
    }
    function onCloseHandler() {
        form.resetForm({
            values: pureStructureFrom(DEFAULT_FORM),
        });
        reset();
        onClose();
    }
    function onChangeAssignee(value) {
        form.setFieldValue('assignee', value);
        if (!shouldDisableDataAccess) {
            if (type === 'project') {
                form.setFieldValue('dataAccess', ProjectDataAccess.HideProjectSource);
            }
            if (type === 'product') {
                form.setFieldValue('dataAccess', ProductDataAccess.HideModelReports);
            }
        }
    }
    function isUpdateAllowed() {
        if (!isUpdating && form.values.assignee.length > 0) {
            if (shouldDisableDataAccess) {
                return true;
            }
            return typeof form.values.dataAccess !== 'undefined';
        }
        return false;
    }
    useEffect(() => {
        if (isOpen) {
            getPrivateAccessData();
        }
        else {
            dispose();
        }
    }, [isOpen]);
    useEffect(() => {
        if (!form.values.recursive) {
            form.setFieldValue('recursive', true);
        }
    }, [form.values.dataAccess]);
    const recursiveDataAccessLevel = form.values.dataAccess === ProjectDataAccess.ShowAllData
        ? ProductDataAccess.ShowAllData
        : ProductDataAccess.HideAllDesignData;
    const isDataAccessSelected = form.values.dataAccess !== null && typeof form.values.dataAccess !== 'undefined';
    return (_jsxs(Modal, { contentClassName: styles.personalAccessModal, disableClose: isUpdating, isShowed: isOpen, onClose: onCloseHandler, position: CENTER, title: `Share Private Access${entitiesCount > 1 ? ` (${entitiesCount} ${type}s will be affected)` : ''}`, children: [_jsxs("div", { className: styles.personalAccessContent, children: [_jsxs("div", { className: styles.personalAccessLeft, children: [_jsx(Heading, { appearance: "h3", className: styles.personalAccessHeading, children: "Add people" }), _jsxs("form", { className: styles.personalAccessForm, onReset: form.handleReset, onSubmit: form.handleSubmit, children: [_jsxs(FormGroup, { children: [_jsx(FormLabel, { children: "Type an email" }), _jsx(FancySelect, { defaultOptions: assigneeOptions, hideArrow: true, inputValue: inputValue, isDisabled: isUpdating, isMulti: true, loadOptions: loadUser, noOptionsMessage: "Not found matching users", onChange: onChangeAssignee, onInputChange: onInputChangeHandler, renderMultiValueOption: renderSelectedUserOption, renderOption: renderUserOption, value: form.values.assignee })] }), !shouldDisableDataAccess && (_jsxs(FormGroup, { children: [type === 'product' && _jsx(FormLabel, { children: "Cut Data Access" }), type === 'project' && _jsx(FormLabel, { children: "Project Data Access" }), _jsx(FancySelect, { isDisabled: isUpdating, onChange: (option) => form.setFieldValue('dataAccess', option?.[0].value ?? null), options: dataAccessOptions, value: form.values.dataAccess })] })), type === 'project' && (_jsxs(FormGroup, { children: [_jsx(Checkbox, { checked: form.values.recursive, className: styles.recursiveCheckbox, disabled: isUpdating || !isDataAccessSelected, label: _jsx("div", { className: styles.recursiveLabel, children: "Set existing solutions access to" }), labelClassName: styles.recursiveCheckboxLabel, onChange: () => form.setFieldValue('recursive', !form.values.recursive), type: "checkbox" }), _jsx("div", { className: cls(styles.recursiveDescriptionInput, {
                                                    [styles.recursiveDescriptionInputHidden]: !isDataAccessSelected || !form.values.recursive,
                                                }), children: isDataAccessSelected && (_jsxs(Fragment, { children: [_jsx(Icon, { className: styles.recursiveDescriptionSecurityIcon, name: getDataAccessIcon(recursiveDataAccessLevel, 'product') }), _jsx("span", { children: getDataAccessById(recursiveDataAccessLevel, 'product')?.title })] })) })] })), _jsx(Button, { className: styles.personalAccessAdd, color: PRIMARY, disabled: !isUpdateAllowed(), type: "submit", wide: true, children: "Add" })] })] }), _jsxs("div", { className: styles.personalAccessRight, children: [_jsx(Heading, { appearance: "h3", className: styles.personalAccessHeading, children: "People with access" }), _jsxs("div", { className: styles.personalAccessRightContent, children: [isFetching && users.length === 0 && (_jsx("div", { className: styles.personalAccessRightLoader, children: _jsx(Loader, { size: MEDIUM }) })), !isFetching && (_jsxs(Fragment, { children: [users.length === 0 && (_jsx("div", { className: styles.personalAccessRightEmpty, children: "No people with personal access yet" })), users.map((acl) => (_jsx(PrivateAccessUser, { user: acl }, acl.assignee.id)))] }))] })] })] }), _jsxs("div", { className: styles.personalAccessFooter, children: [_jsx(DocumentationLink, { href: URL_PRODUCT_PERMISSIONS_DOC }), _jsxs(ButtonContainer, { children: [_jsx(Button, { color: NEUTRAL_NOT_SO_LIGHT, disabled: isUpdating, onClick: onCloseHandler, wide: !isPhone, children: "Cancel" }), _jsx(Button, { color: PRIMARY, disabled: !aclByEntityDiff, loading: isUpdating, onClick: onUpdateHandler, wide: !isPhone, children: "Ok" })] })] })] }));
}
export default observer(PrivateAccessModalContent);
