var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
import Jewelry from '@/product/entity/Jewelry';
import Rough from '@/product/entity/Rough';
import UserAPI from '@/user/api/UserAPI';
import SecurityAPI from '../api/SecurityAPI';
import { ACLByEntity } from '../entities/ACL';
import { ACLAssignee } from '../entities/ACLAssignee';
import { aclAssigneeToOption } from '../helpers/aclAssigneeToOption';
export class PrivateAccessStore {
    entities = []; // contains entities that will be updated - "products" or "projects"
    assigneeList = [];
    aclByEntityDefault = {}; // contains grouped ACLs by entity
    aclByEntityToRemove = [];
    aclByEntity = {};
    isUpdating = false;
    isFetching = false;
    type;
    securityAPI;
    userAPI;
    constructor(items, type) {
        this.type = type;
        this.entities = items;
        this.securityAPI = new SecurityAPI();
        this.userAPI = new UserAPI();
    }
    get aclByEntityDiff() {
        // compare two arrays
        return JSON.stringify(this.aclByEntity) !== JSON.stringify(this.aclByEntityDefault);
    }
    get assigneeOptions() {
        return (this.assigneeList
            .map((assignee) => aclAssigneeToOption(assignee))
            // filter already selected assignees
            .filter(({ value }) => !this.users.some(({ assignee }) => `${assignee.id}` === `${value}`)));
    }
    get entitiesCount() {
        return this.entities.length;
    }
    get users() {
        return this.groupACLsByUser(Object.values(this.aclByEntity).reduce((prev, acls) => [...prev, ...acls], []));
    }
    get isRough() {
        return this.entities?.[0] instanceof Rough;
    }
    get isJewelry() {
        return this.entities?.[0] instanceof Jewelry;
    }
    get shouldDisableDataAccess() {
        return this.isRough || this.isJewelry;
    }
    getPrivateAccessData = async () => {
        this.isFetching = true;
        try {
            const [assignee, acl] = await Promise.all([
                this.securityAPI.getAssigneeList(),
                this.securityAPI.getACLList(this.entities.map(({ id }) => id), this.type),
            ]);
            this.assigneeList = (assignee?.content ?? []).map((item) => new ACLAssignee(item));
            const aclsList = acl?.content ?? [];
            this.aclByEntityDefault = this.groupACLsByEntity(aclsList);
            this.aclByEntity = this.groupACLsByEntity(aclsList);
        }
        catch (e) {
            Notifier.error(e);
        }
        this.isFetching = false;
    };
    findUserByEmail = (email) => this.userAPI.fetchUserByEmail(email).then((res) => res.content);
    addACLByAssignee = (options, dataAccess, recursive) => {
        options.forEach((option) => {
            Object.keys(this.aclByEntity).forEach((key) => {
                this.aclByEntity[key].push(new ACLByEntity({
                    id: key,
                    assignee: option.assignee,
                    dataAccess: dataAccess,
                    recursive,
                }));
            });
            // in case when we remove and then add same assignee again
            this.aclByEntityToRemove = this.aclByEntityToRemove.filter((acl) => acl.assignee.id !== option.value);
        });
    };
    updateACLByEntity = (assigneeId, dataAccess) => {
        Object.keys(this.aclByEntity).forEach((key) => {
            this.aclByEntity[key] = this.aclByEntity[key].map((acl) => {
                if (acl.assignee.id === assigneeId) {
                    acl.dataAccess = dataAccess;
                }
                return acl;
            });
        });
    };
    removeACLByAssignee = (assigneeId) => {
        const aclListToRemove = this.aclByEntityToRemove;
        Object.keys(this.aclByEntity).forEach((key) => {
            this.aclByEntity[key] = this.aclByEntity[key].filter((acl) => {
                if (acl.assignee.id === assigneeId) {
                    aclListToRemove.push(acl);
                    return false;
                }
                return true;
            });
        });
        this.aclByEntityToRemove = aclListToRemove;
    };
    updateACl = async () => {
        this.isUpdating = true;
        const toUpdate = [];
        Object.keys(this.aclByEntity).forEach((key) => {
            this.aclByEntity[key].forEach((acl) => {
                const currentACL = this.aclByEntityDefault[key].find(({ assignee }) => assignee.id === acl.assignee.id);
                // send only new and updated ACLs
                if (!currentACL || currentACL.dataAccess !== acl.dataAccess) {
                    toUpdate.push({
                        id: key,
                        assignee: acl.assignee.id,
                        dataAccess: acl.dataAccess,
                        type: this.type,
                        recursive: Boolean(acl.recursive),
                    });
                }
            });
        });
        const toRemove = this.aclByEntityToRemove.map((aclByEntity) => ({
            id: aclByEntity.id,
            assignee: aclByEntity.assignee.id,
            dataAccess: aclByEntity.dataAccess,
            type: this.type,
        }));
        const requests = [];
        if (toUpdate.length > 0) {
            requests.push(this.securityAPI.updateACL(toUpdate));
        }
        if (toRemove.length > 0) {
            requests.push(this.securityAPI.deleteACL(toRemove));
        }
        try {
            const [updateACLResponse, deleteACLResponse] = await Promise.all(requests);
            const response = [...(updateACLResponse?.content ?? []), ...(deleteACLResponse?.content ?? [])];
            if (response.every(({ state }) => state === 'ok')) {
                Notifier.success('Updated successfully');
            }
            else if (response.every(({ state }) => state === 'error')) {
                Notifier.error('Fail to update');
            }
            else {
                Notifier.warn('Partially updated');
            }
            await this.getPrivateAccessData();
            this.isUpdating = false;
            return this.entities.map((entity) => {
                entity.acl = this.aclByEntity[entity.id] ?? [];
                return entity;
            });
        }
        catch (e) {
            Notifier.error(e);
            this.isUpdating = false;
            return [];
        }
    };
    reset = () => {
        this.aclByEntity = this.aclByEntityDefault;
        this.aclByEntityToRemove = [];
    };
    dispose = () => {
        this.assigneeList = [];
        this.aclByEntityDefault = {};
        this.aclByEntityToRemove = [];
        this.aclByEntity = {};
    };
    groupACLsByUser = (aclList) => {
        const groupedACL = [];
        aclList.forEach((acl) => {
            const targetACLIndex = groupedACL.findIndex(({ assignee }) => assignee.id === acl.assignee.id);
            if (targetACLIndex === -1) {
                groupedACL.push({
                    assignee: new ACLAssignee(acl.assignee),
                    dataAccess: [acl.dataAccess],
                });
            }
            else if (groupedACL[targetACLIndex].dataAccess.length === 0) {
                groupedACL[targetACLIndex].dataAccess = [acl.dataAccess];
            }
            else {
                groupedACL[targetACLIndex].dataAccess.push(acl.dataAccess);
            }
        });
        return groupedACL;
    };
    groupACLsByEntity = (aclList) => {
        const groupedACL = {};
        this.entities.forEach(({ id }) => {
            groupedACL[id] = [];
        });
        aclList.forEach((acl) => {
            if (groupedACL[acl.id].length === 0) {
                groupedACL[acl.id] = [new ACLByEntity(acl)];
            }
            else {
                groupedACL[acl.id].push(new ACLByEntity(acl));
            }
        });
        return groupedACL;
    };
}
__decorate([
    observable
], PrivateAccessStore.prototype, "entities", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "assigneeList", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "aclByEntityDefault", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "aclByEntityToRemove", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "aclByEntity", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "isUpdating", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "isFetching", void 0);
__decorate([
    observable
], PrivateAccessStore.prototype, "type", void 0);
__decorate([
    computed
], PrivateAccessStore.prototype, "aclByEntityDiff", null);
__decorate([
    computed
], PrivateAccessStore.prototype, "assigneeOptions", null);
__decorate([
    computed
], PrivateAccessStore.prototype, "entitiesCount", null);
__decorate([
    computed
], PrivateAccessStore.prototype, "users", null);
export const usePrivateAccessStore = () => useStore(PrivateAccessStore);
