import { generateSearchQuery, parseSearchQuery, } from '@cutwise/utils';
import { NEXTBOX_DOMAIN } from '@/netbox/constants/network';
export default class URLBuilder {
    static buildUrl(path, params) {
        let url = `${window.location.origin}/${path}`;
        if (!url.includes(NEXTBOX_DOMAIN)) {
            /*
             * Below we need to check if site opened on subdomain (e.g. develop.cutwise.com, staging.cutwise.com),
             * if Yes api should be like "api-*.cutwise.com", if No - "api.cutwise.com"
             *
             * This is because we can't have domain with 4 levels because of certificates
             *
             * Examples
             *   - cutwise.com -> api.cutwise.com
             *   - develop.cutwise.com -> api-develop.cutwise.com
             *   - staging.cutwise.com -> api-staging.cutwise.com
             * */
            const subdomainSearch = /\w+\.cutwise\.(com|local|link)/gi.exec(window.location.hostname);
            const subdomain = subdomainSearch?.[0] ?? null;
            url = window.location.protocol;
            url += `//${subdomain ? `api-${subdomain}` : `api.${window.location.hostname}`}/${path}`;
        }
        return `${url}${URLBuilder.buildQueryString(params)}`;
    }
    static buildQueryString(params, options = {}) {
        return generateSearchQuery(params, { encode: true, ...options });
    }
    static parseQueryString(search, options = {}) {
        return parseSearchQuery(decodeURIComponent(search), options);
    }
    static replace(path) {
        window.history.replaceState({}, '', path);
    }
    static buildRelativeUrl(path) {
        return `${window.location.origin}${path}`;
    }
}
