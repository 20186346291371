import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CULET_MM = {
    key: 'culetMma',
    label: 'Culet',
    fullLabel: 'Culet, mm',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-culet',
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.culet !== null ? `${p.reportData.culet} mm` : null;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.culet !== null ? p.reportData.culet : null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
