import { isBrowser, isChrome, isDesktop, isEdge } from 'react-device-detect';
import { logger } from '@cutwise/utils';
import { eyeTracking } from '@/common/services/eye-tracking';
export function isRemotePresentationAvailable() {
    // PresentationRequest is browser API and available only with `https`
    return isDesktop && (isChrome || isEdge) && isBrowser && typeof window.PresentationRequest !== 'undefined';
}
export function isDetectScreenInfoAvailable() {
    // `getScreenDetails` is available in `window` only in `https` mode,
    return 'getScreenDetails' in window;
}
export async function availableScreens() {
    try {
        if (isDetectScreenInfoAvailable() && window.screen.isExtended) {
            const { state } = await navigator.permissions.query({
                name: 'window-management',
            });
            if (state !== 'denied') {
                return await window.getScreenDetails();
            }
            throw new Error('"window-management" denied');
        }
    }
    catch (e) {
        logger.error(e.message, e);
    }
    return {};
}
export async function isSecondMonitorAvailable() {
    try {
        const screens = await availableScreens();
        if (!screens) {
            return false;
        }
        return (
        // Presentation API works only for two monitors
        screens?.screens && screens.screens.length > 1);
    }
    catch (e) {
        throw new Error(e);
    }
}
export function isStereoPresentationAvailable(args = {}) {
    try {
        /*
         * If we want to use Presentation API, make additional checks that this technology is available
         * */
        if (args.detectPresentationAPI) {
            /*
             * When API to detect two screens is not available, because it is experimental technology and only available under HTTPS:
             * https://developer.mozilla.org/en-US/docs/Web/API/Window/getScreenDetails
             * */
            if (!isDetectScreenInfoAvailable()) {
                logger.error('"isDetectScreenInfoAvailable()" returns false');
                return false;
            }
            if (!isRemotePresentationAvailable()) {
                logger.error('"isRemotePresentationAvailable()" returns false');
                return false;
            }
        }
        /*
         * "isExtended" will be false, when monitors are not extended (duplicated or selected only one to show)
         * */
        if (!window.screen.isExtended) {
            logger.error('"window.screen.isExtended" returns false');
            return false;
        }
        if (args.detectEyeTracking && eyeTracking.state !== 'started') {
            logger.error('Eye tracking extensions is not available!');
            return false;
        }
        return true;
    }
    catch (e) {
        return false;
    }
}
export class RemotePresentation {
    started = false;
    presentationRequest;
    presentationConnection;
    constructor(url) {
        if (!isRemotePresentationAvailable()) {
            return;
        }
        this.presentationRequest = new PresentationRequest([url]);
        // Make this presentation the default one when using the "Cast" browser menu.
        navigator.presentation.defaultRequest = this.presentationRequest;
    }
    isStarted() {
        return this.started;
    }
    async start() {
        if (this.isStarted()) {
            return this.presentationConnection;
        }
        const connection = await this.presentationRequest.start();
        if (!connection) {
            return null;
        }
        this.started = true;
        this.presentationConnection = connection;
        this.presentationConnection.addEventListener('close', () => {
            this.started = false;
        });
        this.presentationConnection.addEventListener('terminate', () => {
            this.started = false;
        });
        return connection;
    }
    send(message) {
        this.presentationConnection.send(JSON.stringify(message));
    }
    terminate() {
        this.presentationConnection.terminate();
    }
}
