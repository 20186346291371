export default class ReportImage {
  /** @type {?string} */
  type = null;

  /** @type {?string} */
  url = null;

  /** @type {?string} */
  title = null;

  /**
   *
   * @param {?string} type
   * @param {?string} url
   * @param {?string} title
   */
  constructor({type, url, title}) {
    this.type = type;
    this.url = url;
    this.title = title;
  }
}
