import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Product from '../../../entity/Product';
import Label, {PRIMARY, SIZE_MEDIUM, SIZE_XLARGE} from '../../../../common/components/Label/Label';
import Diamond from '../../../entity/Diamond';

@observer
export default class IsPhotorealLabel extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    block: PropTypes.bool,
    size: PropTypes.oneOf([SIZE_MEDIUM, SIZE_XLARGE]),
  };

  static defaultProps = {
    block: false,
    size: SIZE_MEDIUM,
  };

  render() {
    const product = this.props.product;
    if (!product || !(product instanceof Diamond) || !product.isPhotoreal) {
      return null;
    }

    return (
      <Label appearance={PRIMARY} size={this.props.size} block={this.props.block}>Photoreal</Label>
    );
  }
}
