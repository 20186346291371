import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CHROMA = {
    key: 'fChroma',
    label: 'Chroma',
    fullLabel: 'Chroma',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData.fChroma;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
