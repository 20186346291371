// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "product-block-grid";
var _d = "product-block-subgrid";
var _e = "productLabels";
var _f = "productLabelsCentered";
var _10 = "reset-button";
var _11 = "1200px";
var _12 = "992px";
var _13 = "1366px";
var _14 = "768px";
var _15 = "1475px";
var _16 = "576px";
var _17 = "1600px";
var _18 = "320px";
var _19 = "2500px";
var _1a = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "product-block-grid", _d as "product-block-subgrid", _e as "productLabels", _f as "productLabelsCentered", _10 as "reset-button", _11 as "screenlg", _12 as "screenmd", _13 as "screenml", _14 as "screensm", _15 as "screenxlg", _16 as "screenxs", _17 as "screenxxlg", _18 as "screenxxs", _19 as "screenxxxlg", _1a as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "product-block-grid": _c, "product-block-subgrid": _d, "productLabels": _e, "productLabelsCentered": _f, "reset-button": _10, "screenlg": _11, "screenmd": _12, "screenml": _13, "screensm": _14, "screenxlg": _15, "screenxs": _16, "screenxxlg": _17, "screenxxs": _18, "screenxxxlg": _19, "single-page-responsive-font-size": _1a }
