import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './Loader.module.less';
export const BLACK = 'black';
export const WHITE = 'white';
export const COLORED = 'colored';
const APPEARANCE_CLASS_MAP = {
    [BLACK]: styles.loaderBlack,
    [WHITE]: styles.loaderWhite,
    [COLORED]: styles.loaderColored,
};
export const LARGE = 'large';
export const MEDIUM = 'medium';
export const SMALL = 'small';
export const X_SMALL = 'x-small';
const SIZE_CLASS_MAP = {
    [LARGE]: styles.loaderLarge,
    [MEDIUM]: styles.loaderMedium,
    [SMALL]: styles.loaderSmall,
    [X_SMALL]: styles.loaderXSmall,
};
function Loader(props) {
    const { appearance = COLORED, size = LARGE, className } = props;
    return (_jsxs("div", { "aria-label": "Loader", className: cls(styles.loader, appearance && APPEARANCE_CLASS_MAP[appearance], size && SIZE_CLASS_MAP[size], className), children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }));
}
export default memo(Loader);
