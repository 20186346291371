export const ROLE_CUSTOM_GRADER = 'ROLE_CUSTOM_GRADER';
export const ROLE_NETBOX_USER = 'ROLE_NETBOX_USER';
export const ROLE_MANAGER = 'ROLE_MANAGER';
export const ROLE_LABGROWN = 'ROLE_LABGROWN';
export const ROLE_INTERNAL = 'ROLE_INTERNAL';
export const ROLE_RECUT_MANAGER = 'ROLE_RECUT_MANAGER';
export const ROLE_RENDERER = 'ROLE_RENDERER';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_METRICS_CONTROLLER = 'ROLE_METRICS_CONTROLLER';
// we do not use this role on frontend, but it exists in backend
// this role combine a few another roles and their permissions
export const ROLE_READONLY_MANAGER = 'ROLE_READONLY_MANAGER';
