import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { CUSTOM_GRADE_CUT_PERFORMANCE } from '@/customGrading/constants/customGradeCodes';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
export const FIELD_CUSTOM_CUT_PERFORMANCE = {
    label: 'Custom Cut Performance',
    fullLabel: 'Custom Cut Performance',
    key: 'customCutPerformance',
    type: FieldType.Scalar,
    spGroupId: SetupPresetGroupType.FireDiBOX,
    icon: 'metrics-optical-performance',
    getter(p) {
        return p.customGrades?.[CUSTOM_GRADE_CUT_PERFORMANCE]?.value?.title ?? null;
    },
    getterPlain(p) {
        return p.customGrades?.[CUSTOM_GRADE_CUT_PERFORMANCE]?.rawValue ?? null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        return p.customGrades?.[CUSTOM_GRADE_CUT_PERFORMANCE]?.value
            ? p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE].value.id * -1
            : null;
    },
    contexts: ALL_CONTEXTS,
    isCustomGrade: true,
};
