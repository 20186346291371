function extractWordsByLimit(words, limit) {
    const result = [];
    for (const word of words) {
        if (result.join().length >= limit) {
            break;
        }
        result.push(word);
    }
    return result;
}
export function shortyString(value, limit) {
    if (typeof value !== 'string' || !value) {
        return '';
    }
    // if whole string is less or equal than limit
    if (value.length <= limit) {
        return value;
    }
    const words = value.trim().split(' ');
    // if string has only one word
    if (words.length === 1) {
        return words[0];
    }
    /*
     * General idea:
     *   If we have string bigger than limit, we want to show X symbols from left and X symbols from right,
     *   everything in the middle should be cropped
     *
     *   X symbols of each half is "limit/2", then all together we will get string with length equal to limit
     *
     *   We also want not to crop word between letters, in such cases whole word should be in result string
     * */
    const half = limit / 2;
    const leftPart = extractWordsByLimit(words, half);
    // filter "words" to remove possible duplicates
    const rightPart = extractWordsByLimit(words.filter((word) => !leftPart.includes(word)).reverse(), half).reverse();
    const leftWords = leftPart.join(' ');
    const rightWords = rightPart.join(' ');
    // if result's length is same as "words" - do not add '...', as a result we will have same string as original string
    const isSameLengthAsOriginal = leftPart.length + rightPart.length === words.length;
    return `${leftWords}${isSameLengthAsOriginal ? ' ' : '...'}${rightWords}`;
}
