import {TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH} from '../../product/constants/productTypes';

const getURLPrefixForProductType = (productType) => {
  switch (productType) {
    case TYPE_JEWELRY:
      return 'jewelry';
    case TYPE_ROUGH:
    case TYPE_LGD_ROUGH:
      return 'rough';
    default:
      return 'diamond'; // ignore 'diamond-colorless' or 'diamond-fancy', just 'diamond'
  }
};

export default class URLProvider {
  /**
   * @param {Product} product
   * @returns {string}
   */
  static productEditPage(product) {
    return `/${product.entityType}/edit/${product.id}`;
  }

  /**
   * @param {?Product} product
   * @param {?SetupPreset} setupPreset
   * @return {string}
   */
  static productDetailPage(product = null, setupPreset = null) {
    if (!product || product.isReference) {
      return '';
    }

    if (product.id && product.type) {
      const urlPrefix = getURLPrefixForProductType(product.type);

      let link = `/${urlPrefix}/${product.id}`;

      if (setupPreset) {
        link += `?sp=${setupPreset.id}`;
      }

      if (window.location.search.includes('debug')) {
        link += link.includes('?') ? '&' : '?';
        link += 'debug';
      }

      return link;
    }

    return '';
  }

  /**
   * @param {number[]} ids
   * @param {string} productType
   * @return {string}
   */
  static comparisonPage(ids, productType) {
    const productsURIPart = ids ? ids.map(id => `id[]=${id}`).join('&') : '';

    return `/compare/${productType}${productsURIPart ? `?${productsURIPart}` : ''}`;
  }

  /**
   * @param {number} productId
   * @param {string} productType
   * @return {string}
   */
  static referenceComparisonPage(productId, productType) {
    return `/compare/reference/${productType}/${productId}`;
  }

  /**
   * @param {Project} project
   * @return {string}
   */
  static solutionsPage(project) {
    return `/projects/${project.id}/solutions`;
  }

  /**
   * @param {Project} project
   * @param {Solution} solution
   * @return {string}
   */
  static activeSolutionPage(project, solution) {
    if (!project.cbr) {
      return URLProvider.solutionsPage(project);
    }

    const id = solution.guid ? solution.guid : solution.id;

    return `/projects/${project.id}/solutions?activeSolutionId=${id}`;
  }

  /**
   * @return {string}
   */
  static projectsPage() {
    return '/projects';
  }

  /**
   * @return {string}
   */
  static sharedWithMeProjectsPage() {
    return '/shared-with-me-projects';
  }

  /**
   * @param {User} user
   * @return {string}
   */
  static profile(user) {
    if (user.isAnonymous) {
      return '';
    }

    return '/profile';
  }

  /**
   * @param {Collection} collection
   */
  static collectionDetailPage(collection) {
    return `/~${collection.id}`;
  }

  /**
   * @param {Collection} collection
   */
  static collectionEditPage(collection) {
    return `/~${collection.id}/edit`;
  }

  /**
   * @param {string} collectionId
   * @param {string} customGradingCode
   * @return {string}
   */
  static customGradingCollectionPage(collectionId, customGradingCode) {
    return `/grading/custom/${customGradingCode}?c=${collectionId}`;
  }

  /**
   *
   * @param {string} productType
   * @return {string}
   */
  static backOfficePage(productType) {
    return `/catalog/${productType}/edit`;
  }

  /**
   * @private
   * @param {string} url
   * @return {string}
   */
  static convertRelativeToAbsolute(url) {
    return `${window.location.origin}${url}`;
  }

  /**
   * @private
   */
  static convertToRelativeLink(absoluteLink) {
    return new URL(absoluteLink).pathname;
  }
}
