import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from 'react';
import { inject } from 'mobx-react';
import cls from 'classnames';
import { URL_PRODUCT_PERMISSIONS_DOC } from '@/catalog/constants/urls';
import Button, { NEUTRAL_NOT_SO_LIGHT, PRIMARY } from '@/common/components/Button/Button';
import ButtonContainer from '@/common/components/ButtonContainer/ButtonContainer';
import DocumentationLink from '@/common/components/DocumentationLink/DocumentationLink';
import Heading from '@/common/components/Heading/Heading';
import Icon from '@/common/components/Icon/Icon';
import Checkbox from '@/common/components/inputs/Checkbox/Checkbox';
import CheckboxGroup from '@/common/components/inputs/CheckboxGroup/CheckboxGroup';
import Label, { SIZE_MEDIUM } from '@/common/components/Label/Label';
import Modal, { CENTER } from '@/common/components/Modal/Modal';
import { ACCESS_TITLE_MAP, AccessLevel, getAccessById, getAccessByType, getAccessLabelAppearance, getAccessLabelIcon, } from '../../../constants/access';
import { DATA_ACCESS_TITLE_MAP, getDataAccessById, getDataAccessByType, getDataAccessIcon, ProductDataAccess, ProjectDataAccess, } from '../../../constants/dataAccess';
import AccessLabel from '../../AccessLabel/AccessLabel';
import styles from './AccessModalContent.less';
function AccessModalContent(props) {
    const { isPhone, isOpen, onClose, onSuccess, isUpdating, isRough, isJewelry, type, entitiesCount, accessValues, dataAccessValues, recursive, accessDiff, dataAccessDiff, setAccess, setDataAccess, setRecursive, updateAccessList, reset, } = props;
    const isPrivateAccess = accessValues[0] === AccessLevel.Restricted;
    function onChangeAccess(nextLevels) {
        setAccess(nextLevels[0]);
        setRecursive(true);
    }
    function onChangeDataAccess(nextDataLevels) {
        setDataAccess(nextDataLevels[0]);
        setRecursive(true);
    }
    function onUpdateAccess() {
        updateAccessList().then((entities) => {
            onCloseHandler();
            onSuccess?.(entities);
        });
    }
    function onCloseHandler() {
        reset();
        onClose();
    }
    const accessOptions = useMemo(() => getAccessByType(type).map((level) => ({
        id: level.id,
        title: (_jsxs("div", { children: [_jsxs("div", { className: styles.accessModalOption, children: [_jsx(AccessLabel, { access: level.id, className: styles.accessModalOptionIcon, type: type }), _jsx(Heading, { appearance: "h6", className: styles.accessModalOptionTitle, children: level.title })] }), _jsx("div", { className: styles.accessModalOptionDescription, children: level.description })] })),
        disabled: isUpdating,
    })), [isUpdating, type]);
    const dataAccessOptions = useMemo(() => getDataAccessByType(type).map((level) => {
        let disabled = false;
        if (isPrivateAccess) {
            if (type === 'product') {
                disabled = level.id !== ProductDataAccess.ShowAllData;
            }
            if (type === 'project') {
                disabled = level.id !== ProjectDataAccess.ShowAllData;
            }
        }
        if (isUpdating) {
            disabled = true;
        }
        return {
            id: level.id,
            title: (_jsxs("div", { children: [_jsxs("div", { className: styles.accessModalOption, children: [_jsx(Icon, { className: styles.accessModalOptionDataIcon, name: getDataAccessIcon(level.id, type) }), _jsx(Heading, { appearance: "h6", className: styles.accessModalOptionTitle, children: level.title })] }), _jsx("div", { className: styles.accessModalOptionDescription, children: level.description })] })),
            disabled,
        };
    }), [isUpdating, type, isPrivateAccess]);
    const recursiveDataAccessLevel = dataAccessValues[0] === ProjectDataAccess.ShowAllData
        ? ProductDataAccess.ShowAllData
        : ProductDataAccess.HideAllDesignData;
    const accessValuesSet = new Set(accessValues);
    const isAccessSelected = accessValuesSet.size === 1;
    const isRestricted = accessValuesSet.size === 1 && accessValues[0] === AccessLevel.Restricted;
    const isDataAccessSelected = accessValuesSet.size === 1;
    const isSelected = isAccessSelected && isDataAccessSelected;
    return (_jsx(Modal, { disableClose: isUpdating, isShowed: isOpen, onClose: onCloseHandler, position: CENTER, title: `Share Global Access${entitiesCount > 1 ? ` (${entitiesCount} ${type}s will be affected)` : ''}`, children: _jsxs("div", { children: [_jsxs("div", { className: styles.accessModal, children: [_jsxs("div", { className: cls(styles.accessModalColumn, styles.accessModalLeft), children: [_jsx(Heading, { appearance: "h3", className: styles.accessModalHeading, children: ACCESS_TITLE_MAP[type] }), _jsx(CheckboxGroup, { activeClassName: styles.accessModalCheckboxActive, onChange: onChangeAccess, optionClassName: styles.accessModalCheckbox, options: accessOptions, type: "radio", values: accessValues })] }), !isRough && !isJewelry && (_jsxs(Fragment, { children: [_jsx("div", { className: styles.accessModalSeparator }), _jsxs("div", { className: cls(styles.accessModalColumn, styles.accessModalRight), children: [_jsx(Heading, { appearance: "h3", className: styles.accessModalHeading, children: DATA_ACCESS_TITLE_MAP[type] }), isRestricted && (_jsxs("div", { className: cls(styles.restrictedDescription, styles.accessModalOptionDescription), children: [type === 'product' && (_jsx("span", { children: "All Cut Data, including model and reports is available to company\u2019 employees users." })), type === 'project' && (_jsx("span", { children: "HP Carbon source data file (.ox2z) is available to company\u2019 employees users. Solution parameters and proportions can be viewed in HP Carbon." }))] })), !isRestricted && (_jsx(CheckboxGroup, { activeClassName: isPrivateAccess ? styles.accessModalCheckboxActiveDisabled : styles.accessModalCheckboxActive, disabledClassName: styles.accessModalCheckboxDisabled, onChange: onChangeDataAccess, optionClassName: styles.accessModalCheckbox, options: dataAccessOptions, type: "radio", values: dataAccessValues }))] })] }))] }), type === 'project' && (_jsxs("div", { className: cls(styles.recursive, {
                        [styles.recursiveDisabled]: !isSelected,
                    }), children: [_jsx(Checkbox, { checked: recursive, disabled: isUpdating || !isSelected, label: (_jsx("div", { className: styles.recursiveLabel, children: "Update Access to Existing Solutions, Planned Diamonds and Scan" })), onChange: () => setRecursive(!recursive), type: "checkbox" }), _jsxs("div", { className: cls(styles.recursiveDescription, {
                                [styles.recursiveDescriptionHidden]: !recursive || !isSelected,
                            }), children: [_jsxs("div", { className: styles.recursiveDescriptionColumn, children: [_jsx("div", { className: styles.recursiveDescriptionLabel, children: "General" }), _jsx("div", { className: styles.recursiveDescriptionInput, children: isAccessSelected && (_jsxs(Fragment, { children: [_jsx(Label, { appearance: getAccessLabelAppearance(accessValues[0]), className: styles.recursiveDescriptionIcon, size: SIZE_MEDIUM, title: getAccessById(accessValues[0], type)?.title, children: _jsx(Icon, { className: styles.icon, name: getAccessLabelIcon(accessValues[0]) }) }), _jsx("span", { children: getAccessById(accessValues[0], type)?.title })] })) })] }), _jsxs("div", { className: styles.recursiveDescriptionColumn, children: [_jsx("div", { className: styles.recursiveDescriptionLabel, children: "Cut Data" }), isRestricted && (_jsx("div", { className: styles.recursiveRestricted, children: "All design data is available to company\u2019 employees users" })), !isRestricted && (_jsx("div", { className: styles.recursiveDescriptionInput, children: isDataAccessSelected && (_jsxs(Fragment, { children: [_jsx(Icon, { className: styles.recursiveDescriptionSecurityIcon, name: getDataAccessIcon(recursiveDataAccessLevel, 'product') }), _jsx("span", { children: getDataAccessById(recursiveDataAccessLevel, 'product')?.title })] })) }))] })] })] })), _jsxs("div", { className: styles.accessModalFooter, children: [_jsx(DocumentationLink, { href: URL_PRODUCT_PERMISSIONS_DOC }), _jsxs(ButtonContainer, { children: [_jsx(Button, { color: NEUTRAL_NOT_SO_LIGHT, disabled: isUpdating, onClick: onCloseHandler, wide: !isPhone, children: "Cancel" }), _jsx(Button, { color: PRIMARY, disabled: !(recursive || accessDiff || dataAccessDiff), loading: isUpdating, onClick: onUpdateAccess, wide: !isPhone, children: "Ok" })] })] })] }) }));
}
export default inject(({ accessStore, appStore }) => ({
    isPhone: appStore.isPhone,
    isUpdating: accessStore.isUpdating,
    isRough: accessStore.isRough,
    isJewelry: accessStore.isJewelry,
    type: accessStore.type,
    entitiesCount: accessStore.entitiesCount,
    accessValues: accessStore.accessValues,
    dataAccessValues: accessStore.dataAccessValues,
    recursive: accessStore.recursive,
    accessDiff: accessStore.accessDiff,
    dataAccessDiff: accessStore.dataAccessDiff,
    setAccess: accessStore.setAccess,
    setDataAccess: accessStore.setDataAccess,
    setRecursive: accessStore.setRecursive,
    updateAccessList: accessStore.updateAccessList,
    reset: accessStore.reset,
}))(AccessModalContent);
