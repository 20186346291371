import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingTaskList.module.css';
import NetBoxStore from '../../stores/NetBoxStore';
import Icon from '../../../common/components/Icon/Icon';
import Heading from '../../../common/components/Heading/Heading';

@inject('netBoxStore')
@observer
export default class LoadingTaskList extends Component {
  static propTypes = {
    netBoxStore: PropTypes.instanceOf(NetBoxStore).isRequired,
  };

  render() {
    if (!this.props.netBoxStore.tasks || this.props.netBoxStore.tasks.length === 0) {
      return null;
    }

    return (
      <>
        <div className={styles.title}>
          <Heading appearance="h3">Downloads</Heading>
        </div>
        {this.props.netBoxStore.tasks.map(task => (
          <div key={task.id} className={`${styles.loadingTask}`}>
            <div className={styles.loadingTaskHeader}>
              <div className={styles.loadingTaskTitle}>{task.title}</div>
              <div className={styles.loadingTaskStatus}>
                {task.isCompleted ? (
                  <Icon name="check" />
                ) : (
                  <Icon name="swap" className={styles.loadingTaskIconLoading} />
                )}
              </div>
            </div>
            {!task.isCompleted && (
              <div
                className={styles.loadingTaskPercent}
                style={{
                  '--progress': `${task.percentageOfCompletion.toFixed()}%`,
                }}
              />
            )}
          </div>
        ))}
      </>
    );
  }
}
