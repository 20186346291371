import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Provider } from 'mobx-react';
import { AccessStore } from '../../stores/AccessStore';
import AccessModalContent from './AccessModalContent/AccessModalContent';
function AccessModal(props) {
    const { entities, type, isOpen, ...rest } = props;
    const [accessStore, setAccessStore] = useState(null);
    useEffect(() => {
        setAccessStore(isOpen ? new AccessStore(entities, type) : null);
    }, [isOpen]);
    if (!accessStore) {
        return null;
    }
    return (_jsx(Provider, { accessStore: accessStore, children: _jsx(AccessModalContent, { isOpen: isOpen, ...rest }) }));
}
export default AccessModal;
