import {observable} from 'mobx';
import {CUSTOM_GRADE_BRILLIANCE, CUSTOM_GRADE_COLOR, CUSTOM_GRADE_GIRDLE_BEAUTY} from '../constants/customGradeCodes';
import dictSet130 from '../constants/customGradeDicts/girdleBeauty/dictSet130';
import dictSet135 from '../constants/customGradeDicts/girdleBeauty/dictSet135';
import dictSet140 from '../constants/customGradeDicts/girdleBeauty/dictSet140';
import dictSet145 from '../constants/customGradeDicts/girdleBeauty/dictSet145';
import dictSet150 from '../constants/customGradeDicts/girdleBeauty/dictSet150';
import dictSet155 from '../constants/customGradeDicts/girdleBeauty/dictSet155';
import CustomGradingDictEntry from './CustomGradingDictEntry';

/**
 * @param {number} p1
 * @param {number} p2
 * @return {number}
 */
const getDistance = (p1, p2) => {
  return Number(Math.abs(p1 - p2).toFixed(3));
};

/**
 * @param {[number]} points
 * @param {number} value
 * @return {number}
 */
const findMinPointByDistance = (points, value) => {
  let result = points[0];
  let minDistance = getDistance(value, points[0]);

  for (let i = 1; i < points.length; i++) {
    const distance = getDistance(value, points[i]);
    if (distance <= minDistance) {
      minDistance = distance;
      result = points[i];
    }
  }

  return result;
};

const REFERENCE_RECEIVING_CUSTOM_STRATEGIES = {
  /**
   * @param {Diamond} product
   * @return {[CustomGradingDictEntry]}
   */
  [CUSTOM_GRADE_GIRDLE_BEAUTY]: (product) => {
    const diameterRatio = product.reportData ? product.reportData.rt : null;
    const point = findMinPointByDistance([1.3, 1.35, 1.4, 1.45, 1.5, 1.55], diameterRatio);
    const dictMap = {
      1.3: dictSet130,
      1.35: dictSet135,
      1.4: dictSet140,
      1.45: dictSet145,
      1.5: dictSet150,
      1.55: dictSet155,
    };

    return dictMap[point];
  },
};

const SCALE_CUSTOM_NAMING_STRATEGIES = {
  /**
   * @param {Diamond} product
   * @return {?string}
   */
  [CUSTOM_GRADE_GIRDLE_BEAUTY]: (activeProduct) => {
    const diameterRatio = activeProduct.reportData ? activeProduct.reportData.rt : null;
    const point = findMinPointByDistance([1.3, 1.35, 1.4, 1.45, 1.5, 1.55], diameterRatio);
    const nameMap = {
      1.3: '1.3',
      1.35: '1.35',
      1.4: '1.4',
      1.45: '1.45',
      1.5: '1.5',
      1.55: '1.55',
    };

    return nameMap[point];
  },
};

const INITIAL_GRADE_RECEIVING_CUSTOM_STRATEGIES = {
  /**
   * @param {Diamond} product
   * @param {Diamond[]} references
   * @param {CustomGradingDictEntry[]} dict
   * @return {CustomGradingDictEntry}
   */
  [CUSTOM_GRADE_GIRDLE_BEAUTY]: (product, references, dict) => {
    return dict[7];
  },
  /**
   * @param {Diamond} product
   * @param {Diamond[]} references
   * @param {CustomGradingDictEntry[]} dict
   * @return {CustomGradingDictEntry}
   */
  [CUSTOM_GRADE_BRILLIANCE]: (product, references, dict) => {
    return dict[7];
  },
  /**
   * @param {Diamond} product
   * @param {Diamond[]} references
   * @param {CustomGradingDictEntry[]} dict
   * @return {CustomGradingDictEntry}
   */
  [CUSTOM_GRADE_COLOR]: (product, references, dict) => {
    if (!product.colorMetric) {
      return dict[Math.ceil(dict.length / 2)];
    }

    const productColor = product.colorMetric.val;

    let referenceOnLeftSide = null; // reference on the left side of product on the grading scale
    let referenceOnRightSide = null; // reference on the right side of product on the grading scale

    for (let i = 0; i < references.length; i++) {
      const reference = references[i];
      const referenceColor = reference.colorMetric.val;
      if (productColor < referenceColor) {
        referenceOnRightSide = references[i];
        break;
      }

      referenceOnLeftSide = reference;
    }

    if (!referenceOnLeftSide) {
      return dict[0];
    }

    if (!referenceOnRightSide) {
      return dict[dict.length - 1];
    }

    const referenceOnLeftSideIndex = dict.findIndex(d => d.refId === referenceOnLeftSide.id);
    const referenceOnRightSideIndex = dict.findIndex(d => d.refId === referenceOnRightSide.id);

    return dict[Math.floor((referenceOnLeftSideIndex + referenceOnRightSideIndex) / 2)];
  },
};

export default class CustomGradeAttribute {
  /**
   * @type {string}
   */
  id;

  /**
   * @type {?[CustomGradingDictEntry]}
   */
  @observable
  dict;

  /**
   * @type {number}
   */
  @observable
  spId;

  /**
   * @type {string}
   */
  @observable
  title;

  constructor({
    id, dict, spId, title,
  }) {
    this.id = id;
    this.dict = dict ? dict.map(d => new CustomGradingDictEntry(d)) : null;
    this.spId = spId;
    this.title = title;
  }

  /**
   * @param {Diamond} product
   * @return {[CustomGradingDictEntry]}
   */
  getDictForProduct(product) {
    const customStrategy = REFERENCE_RECEIVING_CUSTOM_STRATEGIES[this.id];

    if (customStrategy) {
      return customStrategy(product);
    }

    return this.dict;
  }

  /**
   * @param {Diamond} product
   * @return {[number]}
   */
  getReferencesIdsForProduct(product) {
    const dict = this.getDictForProduct(product);

    return dict.map(d => d.refId).filter(refId => refId !== null);
  }

  /**
   * @param {Product} activeProduct
   * @return {?string}
   */
  getScaleTitle = (activeProduct) => {
    const customStrategy = SCALE_CUSTOM_NAMING_STRATEGIES[this.id];
    if (customStrategy) {
      return customStrategy(activeProduct);
    }

    return null;
  };

  /**
   * @param {Diamond} product
   * @param {Diamond[]} references
   * @param {[CustomGradingDictEntry]} dict
   * @return {CustomGradingDictEntry}
   */
  getDefaultInitialGrade(product, references, dict) {
    const customStrategy = INITIAL_GRADE_RECEIVING_CUSTOM_STRATEGIES[this.id];

    if (customStrategy) {
      return customStrategy(product, references, dict);
    }

    return dict[Math.ceil(dict.length / 2)];
  }
}
