import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
import MetricScaleContainer from '@/metric/components/MetricScaleContainer/MetricScaleContainer';
import OptMetricBar from '@/metric/components/OptMetricBar/OptMetricBar';
import { METRIC_DATA_DECORATOR_ROUND } from '@/metric/constants/dataDecorators';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CUT_PERFORMANCE = {
    key: 'integral.val',
    label: 'Global Perf.',
    fullLabel: 'Global Performance',
    type: FieldType.Metric,
    spGroupId: SetupPresetGroupType.FireDiBOX,
    icon: 'metrics-optical-performance',
    getter(p) {
        if (p instanceof Diamond && p.integralMetric) {
            return p.integralMetric.hr;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.integralMetric) {
            return p.integralMetric.val;
        }
        return null;
    },
    decorator: (_, p, { ratio, size, label, icon } = {}) => {
        if (p instanceof Diamond) {
            return (_jsx(OptMetricBar, { cutShapeId: p.cutShapeId, metric: p.integralMetric, metricDataDecorator: METRIC_DATA_DECORATOR_ROUND, ratio: ratio, type: "integral", children: (scale, abs) => (_jsx(MetricScaleContainer, { abs: abs, icon: icon, isMetricCalculated: Boolean(p.integralMetric), label: label, scale: scale, size: size })) }));
        }
        return null;
    },
    iteratee(p) {
        if (p instanceof Diamond && p.integralMetric) {
            return p.integralMetric.val;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
