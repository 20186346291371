import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './DocumentationLink.module.css';
import Link, {NAV} from '../Link/Link';
import Icon from '../Icon/Icon';

export default class DocumentationLink extends Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    iconOnly: PropTypes.bool,
  };

  static defaultProps = {
    iconOnly: false,
  };

  render() {
    return (
      <Link className={styles.documentationLink} href={this.props.href} external appearance={NAV}>
        <Icon className={styles.documentationLinkIcon} name="help" />

        <span>{this.props.iconOnly === false ? 'Help' : '' }</span>
      </Link>
    );
  }
}
