export default class ScintillationMetric {
  /**
   * @type {string[]}
   */
  ag;

  /**
   * @type {number}
   */
  d;

  /**
   * @type {string}
   */
  hr;

  /**
   * @type {?string}
   */
  map;

  /**
   * @type {number}
   */
  rg;

  /**
   * @type {string}
   */
  val;

  /**
   * @type {string}
   */
  ver;

  /**
   * Contains calculated value for radar,
   * value in range in between 0 and 1
   *
   * @type {number}
   */
  gmc;

  /**
   * @param {string[]} ag
   * @param {number} d
   * @param {string} hr
   * @param {?string} map
   * @param {number} rg
   * @param {number} val
   * @param {string} ver
   * @param {number} gmc
   */
  constructor({
    ag, d, hr, map, rg, val, ver, gmc,
  }) {
    this.ag = ag;
    this.d = d;
    this.hr = hr;
    this.map = map;
    this.rg = rg;
    this.val = val.toFixed(2);
    this.ver = ver;
    this.gmc = gmc;
  }
}
