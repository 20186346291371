export default class IdentityMap {
  constructor(maxItemsCount = 100) {
    this.bucket = new Map();
    this.maxItemsCount = maxItemsCount;
    this.insertOrder = [];
  }

  /**
   * @param {string|number} id
   * @param {Object} entry
   */
  add(id, entry) {
    this.bucket.set(id, entry);
    this.insertOrder.push(id);
  }

  /**
   * @param {string|number} id
   */
  get(id) {
    return this.bucket.get(id);
  }

  /**
   * @param {string|number} id
   * @return {boolean}
   */
  has(id) {
    return this.bucket.has(id);
  }

  /**
   * @param {string|number} id
   */
  remove(id) {
    this.bucket.delete(id);
  }

  clear() {
    if (this.maxItemsCount >= this.length) {
      return;
    }

    const countToDelete = this.length - this.maxItemsCount;
    const removedIds = this.insertOrder.slice(0, countToDelete);
    this.insertOrder = this.insertOrder.slice(countToDelete, this.length);

    removedIds.forEach(id => this.bucket.delete(id));
  }

  get length() {
    return this.insertOrder.length;
  }
}
