import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_GIRDLE_PC = {
    key: 'girdlePc',
    label: 'Girdle',
    fullLabel: 'Girdle, %',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-girdle',
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.girdleBezelPc !== null ? `${p.reportData.girdleBezelPc}%` : null;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.girdleBezelPc !== null ? p.reportData.girdleBezelPc : null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
