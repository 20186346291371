import { ACLAssignee } from './ACLAssignee';
export class ACLModel {
    id;
    assignee;
    dataAccess;
    code;
    type;
    state;
    error;
}
export class ACLByEntity extends ACLModel {
    recursive;
    constructor(model) {
        super();
        Object.keys(this).forEach((key) => {
            switch (key) {
                case 'assignee':
                    this.assignee = new ACLAssignee(model[key]);
                    break;
                default:
                    this[key] = model[key];
                    break;
            }
        });
    }
}
