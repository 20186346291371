import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import cls from 'classnames';
import styles from './LinearProgress.less';
const MIN_PROGRESS_VALUE = 0;
const MAX_PROGRESS_VALUE = 100;
export const LinearProgress = forwardRef((props, ref) => {
    const { value, color = 'purple', className, ...rest } = props;
    const widthValue = useMemo(() => {
        // if pass value that isn't a number
        if (Number.isNaN(+value)) {
            return 0;
        }
        // if pass value less than min value
        if (+value < MIN_PROGRESS_VALUE) {
            return MIN_PROGRESS_VALUE;
        }
        // if pass value greater than min value
        if (+value > MAX_PROGRESS_VALUE) {
            return MAX_PROGRESS_VALUE;
        }
        return +value;
    }, [value]);
    const containerClasses = useMemo(() => {
        const classes = [styles.linearProgress];
        switch (color) {
            default:
            case 'purple':
                classes.push(styles.purpleLinearProgress);
                break;
            case 'orange':
                classes.push(styles.orangeLinearProgress);
                break;
            case 'grey':
                classes.push(styles.greyLinearProgress);
                break;
        }
        return cls(classes);
    }, [color, className]);
    return (_jsx("div", { ref: ref, className: containerClasses, ...rest, children: _jsx("div", { className: styles.linearProgressValue, style: { width: `${widthValue}%` } }) }));
});
