import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { CUSTOM_GRADE_BRILLIANCE } from '@/customGrading/constants/customGradeCodes';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
export const FIELD_CUSTOM_BRILLIANCE = {
    label: 'Custom Brilliance',
    fullLabel: 'Custom Brilliance',
    key: 'customBrilliance',
    type: FieldType.Scalar,
    spGroupId: SetupPresetGroupType.Office,
    icon: 'metrics-brilliance',
    getter(p) {
        return p.customGrades?.[CUSTOM_GRADE_BRILLIANCE]?.value?.title ?? null;
    },
    getterPlain(p) {
        return p.customGrades?.[CUSTOM_GRADE_BRILLIANCE]?.rawValue ?? null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        return p.customGrades?.[CUSTOM_GRADE_BRILLIANCE]?.value
            ? p.customGrades[CUSTOM_GRADE_BRILLIANCE].value.id * -1
            : null;
    },
    contexts: ALL_CONTEXTS,
    isCustomGrade: true,
};
