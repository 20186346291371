import round from 'lodash/round';
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_DEAD_ZONE = {
    label: 'Dead zones',
    fullLabel: 'Dead zones',
    key: 'dz',
    type: FieldType.Scalar,
    getter(p) {
        if (p instanceof Diamond && p.brightnessMetric) {
            return round(p.brightnessMetric.dz, 2);
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.brightnessMetric) {
            return p.brightnessMetric.dz;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
