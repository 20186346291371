// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "metricScaleContainerActive";
var _d = "metricScaleContainerInactive";
var _e = "metricScaleContainerLabel";
var _f = "metricScaleContainerLarge";
var _10 = "metricScaleContainerLargeABS";
var _11 = "metricScaleContainerLargeIcon";
var _12 = "metricScaleContainerLargeScale";
var _13 = "metricScaleContainerLargeTitle";
var _14 = "metricScaleContainerMedium";
var _15 = "metricScaleContainerMediumIcon";
var _16 = "metricScaleContainerMediumScale";
var _17 = "metricScaleContainerMediumTitle";
var _18 = "metricScaleContainerMediumWrapper";
var _19 = "metricScaleContainerSmall";
var _1a = "metricScaleContainerSmallIcon";
var _1b = "metricScaleContainerSmallTitle";
var _1c = "metricScaleContainerXSmall";
var _1d = "metricScaleContainerXSmallABS";
var _1e = "metricScaleContainerXSmallScale";
var _1f = "product-block-grid";
var _20 = "product-block-subgrid";
var _21 = "reset-button";
var _22 = "1200px";
var _23 = "992px";
var _24 = "1366px";
var _25 = "768px";
var _26 = "1475px";
var _27 = "576px";
var _28 = "1600px";
var _29 = "320px";
var _2a = "2500px";
var _2b = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "metricScaleContainerActive", _d as "metricScaleContainerInactive", _e as "metricScaleContainerLabel", _f as "metricScaleContainerLarge", _10 as "metricScaleContainerLargeABS", _11 as "metricScaleContainerLargeIcon", _12 as "metricScaleContainerLargeScale", _13 as "metricScaleContainerLargeTitle", _14 as "metricScaleContainerMedium", _15 as "metricScaleContainerMediumIcon", _16 as "metricScaleContainerMediumScale", _17 as "metricScaleContainerMediumTitle", _18 as "metricScaleContainerMediumWrapper", _19 as "metricScaleContainerSmall", _1a as "metricScaleContainerSmallIcon", _1b as "metricScaleContainerSmallTitle", _1c as "metricScaleContainerXSmall", _1d as "metricScaleContainerXSmallABS", _1e as "metricScaleContainerXSmallScale", _1f as "product-block-grid", _20 as "product-block-subgrid", _21 as "reset-button", _22 as "screenlg", _23 as "screenmd", _24 as "screenml", _25 as "screensm", _26 as "screenxlg", _27 as "screenxs", _28 as "screenxxlg", _29 as "screenxxs", _2a as "screenxxxlg", _2b as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "metricScaleContainerActive": _c, "metricScaleContainerInactive": _d, "metricScaleContainerLabel": _e, "metricScaleContainerLarge": _f, "metricScaleContainerLargeABS": _10, "metricScaleContainerLargeIcon": _11, "metricScaleContainerLargeScale": _12, "metricScaleContainerLargeTitle": _13, "metricScaleContainerMedium": _14, "metricScaleContainerMediumIcon": _15, "metricScaleContainerMediumScale": _16, "metricScaleContainerMediumTitle": _17, "metricScaleContainerMediumWrapper": _18, "metricScaleContainerSmall": _19, "metricScaleContainerSmallIcon": _1a, "metricScaleContainerSmallTitle": _1b, "metricScaleContainerXSmall": _1c, "metricScaleContainerXSmallABS": _1d, "metricScaleContainerXSmallScale": _1e, "product-block-grid": _1f, "product-block-subgrid": _20, "reset-button": _21, "screenlg": _22, "screenmd": _23, "screenml": _24, "screensm": _25, "screenxlg": _26, "screenxs": _27, "screenxxlg": _28, "screenxxs": _29, "screenxxxlg": _2a, "single-page-responsive-font-size": _2b }
