import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import Icon from '@/common/components/Icon/Icon';
import Link, { NONE } from '@/common/components/Link/Link';
import MenuItem from '@/common/components/MenuList/MenuItem/MenuItem';
import MenuList from '@/common/components/MenuList/MenuList';
import Popover from '@/common/components/Popover/Popover';
import { useToggle } from '@/common/hooks/useToggle/useToggle';
import ProductPlaceholder, { COLOR_OUTLINE, COLOR_OUTLINE_GREEN, } from '@/media/components/ProductPlaceholder/ProductPlaceholder';
import { SHAPE_PEAR, SHAPE_ROUND } from '@/product/entity/Cutshape';
import styles from '../Header.less';
function DiamondsNavItem(props) {
    const { title, isActive, colorlessLink, navClassName, fancyLink, roughLink } = props;
    const [isOpen, , close, toggle] = useToggle();
    return (_jsx(Popover, { body: (_jsxs(MenuList, { withRoundedCorners: true, children: [_jsx(MenuItem, { className: styles.headerProductTypeMenuWrapper, children: _jsxs(Link, { activeClassName: "active", appearance: NONE, className: styles.headerProductTypeMenuItem, exact: true, onClick: close, role: "menuitem", to: colorlessLink, children: [_jsx("div", { className: styles.headerProductTypeIcon, children: _jsx(ProductPlaceholder, { bold: true, color: COLOR_OUTLINE, shape: SHAPE_ROUND }) }), "Polished Colorless"] }) }), _jsx(MenuItem, { className: styles.headerProductTypeMenuWrapper, children: _jsxs(Link, { activeClassName: "active", appearance: NONE, className: styles.headerProductTypeMenuItem, exact: true, onClick: close, role: "menuitem", to: fancyLink, children: [_jsx("div", { className: styles.headerProductTypeIcon, children: _jsx(ProductPlaceholder, { bold: true, color: COLOR_OUTLINE_GREEN, shape: SHAPE_PEAR }) }), "Polished Fancy Color"] }) }), _jsx(MenuItem, { className: styles.headerProductTypeMenuWrapper, children: _jsxs(Link, { activeClassName: "active", appearance: NONE, className: styles.headerProductTypeMenuItem, exact: true, onClick: close, role: "menuitem", to: roughLink, children: [_jsx("div", { className: styles.headerProductTypeIcon, children: _jsx(ProductPlaceholder, { bold: true, color: COLOR_OUTLINE, shape: "rough" }) }), "Rough"] }) })] })), className: "Popover--transparent", isOpen: isOpen, onOuterAction: close, preferPlace: "below", children: _jsxs("div", { className: cls('header-link', 'header-link--desktop', 'header-link--dropdown', navClassName, {
                [styles.headerLinkActive]: isActive,
            }), onClick: toggle, children: [_jsxs("span", { children: [title, " "] }), _jsx(Icon, { className: cls('header-link__triangle', { 'header-link__triangle--opened': isOpen }), name: "arrow-lt-down" })] }) }));
}
export default observer(DiamondsNavItem);
