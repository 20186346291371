/**
 * @param {Query} query
 * @return {Query}
 */
export default function ContentType(query) {
  const contentType = query.serializer.contentType();
  if (contentType) {
    query.addHeader('Content-Type', contentType);
  }

  return query;
}
