import CutwiseAPIClient, { METHOD_GET } from '../network/CutwiseAPIClient';
export const PATH_CONSTANTS = 'api/v2/constants/web';
export const PATH_VIEWER_PRESETS = 'api/v2/constants/cv';
export default class ConstantsAPI {
    fetchAll() {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_CONSTANTS);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    fetchViewerPresets() {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_VIEWER_PRESETS);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
