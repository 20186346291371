import { EyeTrackingClient, logger } from '@cutwise/utils';
class EyeTracking {
    state = 'pending';
    #client;
    constructor() {
        this.#client = new EyeTrackingClient();
        this.#start();
    }
    close() {
        this.#client.disconnect();
    }
    #start() {
        this.#client.onError = () => {
            logger.error('Eye tracking extensions is not available!');
            this.state = 'failed';
        };
        this.#client.onSuccess = () => {
            logger.info('Eye tracking extensions is started!');
            this.state = 'started';
        };
        this.#client.connect();
    }
}
const eyeTracking = new EyeTracking();
export { eyeTracking };
