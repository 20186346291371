import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CUT = {
    label: 'Cut',
    fullLabel: 'Cut Quality',
    key: 'cutQuality',
    type: FieldType.Scalar,
    icon: 'metrics-cut',
    getter(p, short = false) {
        if (p instanceof Diamond && p.cutQuality) {
            return short ? p.cutQuality?.short : p.cutQuality?.title;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.cutQuality) {
            return p.cutQuality?.position;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.cutQuality) {
            return p.cutQuality.position * -1;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
