// extracted by mini-css-extract-plugin
var _1 = "btn";
var _2 = "btn--active";
var _3 = "btn--active-ambient";
var _4 = "btn--active-default";
var _5 = "btn--block";
var _6 = "btn--disabled";
var _7 = "btn--icon";
var _8 = "btn--image";
var _9 = "btn--m";
var _a = "btn--s";
var _b = "btn--silent";
var _c = "btn--text-eillipsis";
var _d = "btn--without-paddings";
var _e = "btn--xl";
var _f = "btn--xs";
var _10 = "btnContentWrapper";
var _11 = "btnHideChildren";
var _12 = "btnLoader";
var _13 = "btnLoading";
var _14 = "btnWide";
var _15 = "buttonAccent";
var _16 = "buttonBordered";
var _17 = "buttonDefault";
var _18 = "buttonGhost";
var _19 = "buttonGhostWarning";
var _1a = "buttonIconLeft";
var _1b = "buttonIconRight";
var _1c = "buttonNeutralNotSoLight";
var _1d = "buttonPrimary";
var _1e = "buttonQuaternary";
var _1f = "buttonSecondary";
var _20 = "buttonTertiary";
var _21 = "buttonTextColorDark";
var _22 = "buttonTextColorLight";
var _23 = "buttonTransparent";
var _24 = "#76F366";
var _25 = "#EB8632";
var _26 = "#ECC834";
var _27 = "#21FC87";
var _28 = "#EA4A30";
var _29 = "#C7EA45";
var _2a = "#272B2B";
var _2b = "#BDBDBD";
var _2c = "#7D7D7D";
var _2d = "#4A90E2";
var _2e = "#ffffff";
var _2f = "cw-icon";
var _30 = "product-block-grid";
var _31 = "product-block-subgrid";
var _32 = "reset-button";
var _33 = "1200px";
var _34 = "992px";
var _35 = "1366px";
var _36 = "768px";
var _37 = "1475px";
var _38 = "576px";
var _39 = "1600px";
var _3a = "320px";
var _3b = "2500px";
var _3c = "single-page-responsive-font-size";
export { _1 as "btn", _2 as "btn--active", _3 as "btn--active-ambient", _4 as "btn--active-default", _5 as "btn--block", _6 as "btn--disabled", _7 as "btn--icon", _8 as "btn--image", _9 as "btn--m", _a as "btn--s", _b as "btn--silent", _c as "btn--text-eillipsis", _d as "btn--without-paddings", _e as "btn--xl", _f as "btn--xs", _10 as "btnContentWrapper", _11 as "btnHideChildren", _12 as "btnLoader", _13 as "btnLoading", _14 as "btnWide", _15 as "buttonAccent", _16 as "buttonBordered", _17 as "buttonDefault", _18 as "buttonGhost", _19 as "buttonGhostWarning", _1a as "buttonIconLeft", _1b as "buttonIconRight", _1c as "buttonNeutralNotSoLight", _1d as "buttonPrimary", _1e as "buttonQuaternary", _1f as "buttonSecondary", _20 as "buttonTertiary", _21 as "buttonTextColorDark", _22 as "buttonTextColorLight", _23 as "buttonTransparent", _24 as "colorGradeEX", _25 as "colorGradeFR", _26 as "colorGradeGD", _27 as "colorGradeOU", _28 as "colorGradePR", _29 as "colorGradeVG", _2a as "colorNeutral", _2b as "colorNeutralLighest", _2c as "colorNeutralLight", _2d as "colorRegularLinks", _2e as "colorWhite", _2f as "cw-icon", _30 as "product-block-grid", _31 as "product-block-subgrid", _32 as "reset-button", _33 as "screenlg", _34 as "screenmd", _35 as "screenml", _36 as "screensm", _37 as "screenxlg", _38 as "screenxs", _39 as "screenxxlg", _3a as "screenxxs", _3b as "screenxxxlg", _3c as "single-page-responsive-font-size" }
export default { "btn": _1, "btn--active": _2, "btn--active-ambient": _3, "btn--active-default": _4, "btn--block": _5, "btn--disabled": _6, "btn--icon": _7, "btn--image": _8, "btn--m": _9, "btn--s": _a, "btn--silent": _b, "btn--text-eillipsis": _c, "btn--without-paddings": _d, "btn--xl": _e, "btn--xs": _f, "btnContentWrapper": _10, "btnHideChildren": _11, "btnLoader": _12, "btnLoading": _13, "btnWide": _14, "buttonAccent": _15, "buttonBordered": _16, "buttonDefault": _17, "buttonGhost": _18, "buttonGhostWarning": _19, "buttonIconLeft": _1a, "buttonIconRight": _1b, "buttonNeutralNotSoLight": _1c, "buttonPrimary": _1d, "buttonQuaternary": _1e, "buttonSecondary": _1f, "buttonTertiary": _20, "buttonTextColorDark": _21, "buttonTextColorLight": _22, "buttonTransparent": _23, "colorGradeEX": _24, "colorGradeFR": _25, "colorGradeGD": _26, "colorGradeOU": _27, "colorGradePR": _28, "colorGradeVG": _29, "colorNeutral": _2a, "colorNeutralLighest": _2b, "colorNeutralLight": _2c, "colorRegularLinks": _2d, "colorWhite": _2e, "cw-icon": _2f, "product-block-grid": _30, "product-block-subgrid": _31, "reset-button": _32, "screenlg": _33, "screenmd": _34, "screenml": _35, "screensm": _36, "screenxlg": _37, "screenxs": _38, "screenxxlg": _39, "screenxxs": _3a, "screenxxxlg": _3b, "single-page-responsive-font-size": _3c }
