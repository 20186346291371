import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import AltSkuField from '@/catalog/components/AltSkuField/AltSkuField';
import ProductLink from '../../../../../../product/components/ProductLink/ProductLink';
import ProductPrice from '../../../../../../product/components/ProductPrice/ProductPrice';
import styles from './ProductInfo.less';
function ProductInfo(props) {
    const { product, setupPreset, showPrice = true, showTitle = true, showB2BSid = true, showAltSku = false, isSmall = false, } = props;
    return (_jsxs("div", { className: cls(styles.productInfo, { [styles.productInfoSmall]: isSmall }), children: [_jsxs("div", { className: styles.productInfoDesc, children: [showB2BSid && (_jsx(ProductLink, { className: styles.productInfoShortView, product: product, setupPreset: setupPreset, text: product.b2bSid })), showTitle && (_jsx(ProductLink, { className: styles.productInfoShortView, product: product, setupPreset: setupPreset })), showAltSku && _jsx(AltSkuField, { value: product.altSku ?? '' })] }), showPrice && (_jsx("div", { className: styles.productInfoDescRight, children: _jsx(ProductPrice, { product: product }) }))] }));
}
export default observer(ProductInfo);
