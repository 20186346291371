import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from 'classnames';
import Icon from '@/common/components/Icon/Icon';
import Label from '@/common/components/Label/Label';
import { AccessLevel, getAccessLabelAppearance, getAccessLabelIcon } from '../../constants/access';
import { getDataAccessIcon } from '../../constants/dataAccess';
import styles from './AccessLabel.module.css';
function AccessLabel(props) {
    const { access, dataAccess, size, type, block, ...rest } = props;
    const isDataAccessPassed = typeof dataAccess !== 'undefined';
    const accessAppearance = getAccessLabelAppearance(access);
    return (_jsxs(Label, { appearance: accessAppearance, className: cls(styles.root, { [styles.block]: block }), size: size, ...rest, children: [_jsx(Icon, { name: getAccessLabelIcon(access) }), isDataAccessPassed && access !== AccessLevel.Restricted && _jsx(Icon, { name: getDataAccessIcon(dataAccess, type) })] }));
}
export default AccessLabel;
