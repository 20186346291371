export class ContactDataModel {
    id;
    address;
    company;
    email;
    person;
    phone;
    title;
    website;
    whatsapp;
}
export class ContactData extends ContactDataModel {
    constructor(modelData) {
        super();
        Object.keys(modelData).forEach((key) => {
            this[key] = modelData[key] ?? '';
        });
    }
}
