import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { getScanWeight } from '@/catalog/helpers/getScanWeight';
import { ALL_CONTEXTS } from '@/common/constants/context';
export const FIELD_CARAT_WEIGHT = {
    label: 'Weight',
    fullLabel: 'Carat Weight',
    key: 'carat',
    type: FieldType.Scalar,
    icon: 'metrics-carat',
    getter(p) {
        return p.carat ? `${getScanWeight(p.carat)}ct` : null;
    },
    getterPlain(p) {
        return p.carat ? getScanWeight(p.carat) : null;
    },
    decorator: emptyDecorator,
    iteratee: (p) => p.carat || null,
    contexts: ALL_CONTEXTS,
};
