import Query from './Query';

export default class QueryBuilder {
  query = new Query();

  /**
   * @param {string} method
   * @return {QueryBuilder}
   */
  method(method) {
    this.query.method = method;

    return this;
  }

  /**
   * @param {string} path
   * @return {QueryBuilder}
   */
  path(path) {
    this.query.path = path;

    return this;
  }

  /**
   * @param {*} requestBody
   * @return {QueryBuilder}
   */
  body(requestBody) {
    this.query.requestBody = requestBody;

    return this;
  }

  /**
   * @param {string} key
   * @param {*} value
   * @return {QueryBuilder}
   */
  addQueryStringParam(key, value) {
    this.query.addQueryStringParam(key, value);

    return this;
  }

  /**
   * @param {string} key
   * @param {*} value
   * @return {QueryBuilder}
   */
  addHeader(key, value) {
    this.query.addHeader(key, value);

    return this;
  }

  /**
   * @param {?number} limit
   * @return {QueryBuilder}
   */
  limit(limit) {
    this.query.addQueryStringParam('limit', limit);

    return this;
  }

  /**
   * @param {string} securityCheck
   * @return {QueryBuilder}
   */
  securityCheck(securityCheck) {
    this.query.setSecurityCheck(securityCheck);

    return this;
  }

  /**
   * @param {?number} offset
   * @return {QueryBuilder}
   */
  offset(offset) {
    this.query.addQueryStringParam('offset', offset);

    return this;
  }

  /**
   * @return {QueryBuilder}
   */
  shortResponseData() {
    this.query.addQueryStringParam('list', true);

    return this;
  }

  /**
   * @return {QueryBuilder}
   */
  indexResponseData() {
    this.query.addQueryStringParam('index', true);

    return this;
  }

  /**
   * @param {ToJSONSerializationStrategy|ToFormDataSerializationStrategy} serializer
   * @return {QueryBuilder}
   */
  serializer(serializer) {
    this.query.serializer = serializer;

    return this;
  }

  /**
   * @return {QueryBuilder}
   */
  suppressErrorNotifications() {
    this.query.suppressErrorNotifications = true;

    return this;
  }

  /**
   * @param {function} middleware
   * @return {QueryBuilder}
   */
  useMiddlewareOnRequest(middleware) {
    this.query.addRequestMiddleware(middleware);

    return this;
  }

  /**
   * @param {function} middleware
   * @return {QueryBuilder}
   */
  useMiddlewareOnResponse(middleware) {
    this.query.addResponseMiddleware(middleware);

    return this;
  }

  /**
   * @param {?number} failSettings.requestTimeout
   * @param {?number} failSettings.retryTimeout
   * @return {QueryBuilder}
   */
  useRepeatOnFail(failSettings) {
    this.query.addFailConfiguration(failSettings);

    return this;
  }

  /**
   * @param {function} hydrator
   * @return {QueryBuilder}
   */
  hydration(hydrator) {
    this.query.hydrator = hydrator;

    return this;
  }

  /**
   * @return {QueryBuilder}
   */
  asJSON() {
    this.query.responseType = 'json';

    return this;
  }

  /**
   * @return {QueryBuilder}
   */
  asText() {
    this.query.responseType = 'text';

    return this;
  }

  /**
   * @return {QueryBuilder}
   */
  asBlob() {
    this.query.responseType = 'blob';

    return this;
  }

  /**
   * @return {Query}
   */
  getQuery() {
    return this.query;
  }
}
