// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "1200px";
var _d = "992px";
var _e = "1366px";
var _f = "768px";
var _10 = "1475px";
var _11 = "576px";
var _12 = "1600px";
var _13 = "320px";
var _14 = "2500px";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "screenlg", _d as "screenmd", _e as "screenml", _f as "screensm", _10 as "screenxlg", _11 as "screenxs", _12 as "screenxxlg", _13 as "screenxxs", _14 as "screenxxxlg" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "screenlg": _c, "screenmd": _d, "screenml": _e, "screensm": _f, "screenxlg": _10, "screenxs": _11, "screenxxlg": _12, "screenxxs": _13, "screenxxxlg": _14 }
