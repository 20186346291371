import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EMPTY_VALUE_DASH } from '../../constants/app';
import useMoney from '../../hooks/useMoney/useMoney';
import { LinearProgress } from '../LinearProgress/LinearProgress';
import styles from './ExpensesProgress.less';
export function ExpensesProgress(props) {
    const { value, amount, onExpand } = props;
    const { formatMoney } = useMoney();
    return (_jsxs("div", { className: "d-flex flex-align-center flex-justify-space-between", children: [_jsx("div", { className: styles.expensesProgress, children: _jsx(LinearProgress, { color: "grey", value: value }) }), typeof amount !== 'undefined' && amount !== null ? (_jsx("button", { className: styles.expensesExpandLink, onClick: onExpand, type: "button", children: formatMoney(amount) })) : (_jsx("span", { children: EMPTY_VALUE_DASH }))] }));
}
