import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './HighlightedHpoColor.less';
import parseHpoColor from '../../services/parseHpoColor';
import getHpoColorDifferenceBy from '../../services/getHpoColorDifferenceBy';

export default class HighlightedHPOColor extends Component {
  static propTypes = {
    hpoColor: PropTypes.string.isRequired,
    hpoColorToCompare: PropTypes.string,
  }

  static defaultProps = {
    hpoColorToCompare: null,
  }

  render() {
    const hpoColor = parseHpoColor(this.props.hpoColor);
    if (!hpoColor) {
      return '—';
    }

    const pavHpoColorFormatted = hpoColor.parts
      .map((prt) => {
        if (prt.isMain()) {
          return <strong className={styles.highlightedHpoColor}>{prt.format()}</strong>;
        }

        return prt.format();
      });

    const mainPart = pavHpoColorFormatted[0];
    const secondaryPart = pavHpoColorFormatted[1];
    const separator = secondaryPart ? ' / ' : '';
    let hpoColorDifference = '';

    if (this.props.hpoColorToCompare) {
      const hpoColorToCompare = parseHpoColor(this.props.hpoColorToCompare);
      if (hpoColorToCompare) {
        hpoColorDifference = getHpoColorDifferenceBy(hpoColor, hpoColorToCompare);
      }
    }

    return <span>{mainPart} {separator} {secondaryPart} {hpoColorDifference}</span>;
  }
}
