import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_FLUOR_STRENGTH = {
    label: 'Fluorescence',
    fullLabel: 'Fluorescence',
    key: 'fluorescenceStrength',
    type: FieldType.Scalar,
    icon: 'metrics-fluor',
    getter(p, short = false) {
        if (p instanceof Diamond && p.fluorescenceStrength) {
            const result = [short ? p.fluorescenceStrength.short : p.fluorescenceStrength.title];
            if (p.fluorescenceColor) {
                result.push(short ? p.fluorescenceColor.short : p.fluorescenceColor.title);
            }
            return result.join(' ');
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.fluorescenceStrength) {
            return p.fluorescenceStrength.position;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
