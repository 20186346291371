var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import omitBy from 'lodash/omitBy';
import { pureStructure } from '@cutwise/utils';
import { FIELD_FILTER_AT_LEAST_ONE } from '@/catalog/constants/fieldFilters';
import { CARD_FIELDS_SORT, ONLY_INTERNAL_USER_FIELDS } from '@/catalog/constants/fieldGroups';
import { FieldPresetType } from '@/catalog/constants/fieldPresetTypes';
import { FIELD_ALT_SKU } from '@/catalog/constants/fields/altSku';
import { FIELD_PRICE } from '@/catalog/constants/fields/price';
import { FIELD_SHORT_VIEW } from '@/catalog/constants/fields/shortView';
import { FIELD_SKU } from '@/catalog/constants/fields/sku';
import { DefaultViewTemplate } from '@/collection/constants/defaultViewTemplates';
import { viewTemplateRepository } from '@/collection/repository/ViewTemplateRepository';
import { INTERNAL, securityManager } from '@/common/services/SecurityManager';
import { View } from '@/comparison/constants/views';
import { MediaSize } from '@/media/constants/sizeCodes';
import { MEDIA_SIZE_MAP } from '@/media/constants/sizeCodeToPx';
import { setupPresetRepository } from '@/media/repository/SetupPresetRepository';
import { METRIC_DATA_DECORATOR_ROUND } from '@/metric/constants/dataDecorators';
import { ViewType } from '@/product/constants/viewType';
import ViewTemplate from '../../collection/entity/ViewTemplate';
import diff from '../../common/helpers/diff';
import Sort from '../../common/state/Sort';
// todo remove when replace values everywhere
export const VIEW_TYPE_GRID = ViewType.Grid;
export const VIEW_TYPE_TABLE = ViewType.Table;
export const VIEW_TYPE_COMBO = ViewType.Combo;
export default class ProductViewSettings {
    viewTemplate = null;
    isHiddenDesc = null;
    isSingleScale = null;
    isGrayscale = null;
    isSaturation = null;
    cardFields = null;
    setupPreset = null;
    type = ViewType.Grid;
    mediaSize = MediaSize.Small;
    fps = null;
    forcedSettings;
    sort = null;
    combo = {
        selectedView: View.List,
        isMediaModeEnabled: false,
        isExtendedFieldSet: false,
        isReferenceComparison: false,
        fieldFilter: FIELD_FILTER_AT_LEAST_ONE,
        isMobileMode: false,
        fullscreenSetupPresetGroup: null,
        isSolutionsListMode: false,
        isCollectionMode: false,
    };
    isAutoplayEnabled = false;
    metricDataDecorator = METRIC_DATA_DECORATOR_ROUND;
    gridColumnsNumber = null;
    isSweetLine = null;
    initial = {};
    constructor(modelData = {}, forcedSettings = {}) {
        this.forcedSettings = forcedSettings;
        this.initial = pureStructure(modelData);
        if (modelData.viewTemplate instanceof ViewTemplate) {
            this.viewTemplate = modelData.viewTemplate;
        }
        else {
            // todo pass default setting preset
            this.viewTemplate = viewTemplateRepository.findViewTemplateById(modelData.viewTemplate ? Number(modelData.viewTemplate) : DefaultViewTemplate.Colorless);
        }
        if (!isNil(modelData.fps)) {
            this.fps = modelData.fps;
        }
        if (!isNil(modelData.isHiddenDesc)) {
            this.isHiddenDesc = modelData.isHiddenDesc;
        }
        if (!isNil(modelData.isSingleScale)) {
            this.isSingleScale = modelData.isSingleScale;
        }
        if (!isNil(modelData.isGrayscale)) {
            this.isGrayscale = modelData.isGrayscale;
        }
        if (!isNil(modelData.isSaturation)) {
            this.isSaturation = modelData.isSaturation;
        }
        if (!isNil(modelData.sort)) {
            this.sort = modelData.sort instanceof Sort ? modelData.sort : Sort.fromJSON(modelData.sort);
        }
        if (!isNil(modelData.cardFields)) {
            this.cardFields = modelData.cardFields;
        }
        if (!isNil(modelData.setupPreset)) {
            this.setupPreset = modelData.setupPreset;
        }
        if (isString(this.setupPreset) || isNumber(this.setupPreset)) {
            this.setupPreset = setupPresetRepository.findSetupPresetById(Number(this.setupPreset));
        }
        if (!isNil(modelData.mediaSize)) {
            this.mediaSize = modelData.mediaSize;
        }
        if (!isNil(modelData.type)) {
            this.type = modelData.type;
        }
        if (!isNil(modelData.isAutoplayEnabled)) {
            this.isAutoplayEnabled = modelData.isAutoplayEnabled;
        }
        if (!isNil(modelData.gridColumnsNumber)) {
            this.gridColumnsNumber = modelData.gridColumnsNumber;
        }
        if (!isNil(modelData.isSweetLine)) {
            this.isSweetLine = modelData.isSweetLine;
        }
        if (this.type === ViewType.Table) {
            this.mediaSize = null;
        }
    }
    get resultedSetupPresets() {
        if (!isNil(this.forcedSettings.setupPreset)) {
            return [this.forcedSettings.setupPreset];
        }
        if (this.setupPreset) {
            return [this.setupPreset];
        }
        if (this.viewTemplate) {
            if (this.viewTemplate.setupPresetGroup) {
                return this.viewTemplate.setupPresetGroup.sp;
            }
            if (this.viewTemplate.setupPreset) {
                return [this.viewTemplate.setupPreset];
            }
        }
        return null;
    }
    get resultedSort() {
        if (!isNil(this.forcedSettings.sort)) {
            return this.forcedSettings.sort;
        }
        if (this.sort) {
            return this.sort;
        }
        if (this.viewTemplate?.sort) {
            return this.viewTemplate.sort;
        }
        return null;
    }
    get resultedCardFields() {
        if (!isNil(this.forcedSettings.cardFields)) {
            return this.forcedSettings.cardFields;
        }
        if (this.cardFields) {
            return this.cardFields;
        }
        if (this.viewTemplate?.cardFields) {
            return this.viewTemplate.cardFields;
        }
        return null;
    }
    get displayedFieldObjects() {
        if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
            return null;
        }
        return CARD_FIELDS_SORT.filter((f) => securityManager.isGranted(INTERNAL) || !ONLY_INTERNAL_USER_FIELDS.includes(f))
            .map((f) => (this.resultedCardFields.includes(f.key) ? f : null))
            .filter((f) => Boolean(f));
    }
    get resultedIsSingleScale() {
        if (!isNil(this.forcedSettings.isSingleScale)) {
            return this.forcedSettings.isSingleScale;
        }
        if (!isNil(this.isSingleScale)) {
            return this.isSingleScale;
        }
        if (this.viewTemplate) {
            return this.viewTemplate.isSingleScale;
        }
        return false;
    }
    get resultedIsGrayscale() {
        if (!isNil(this.forcedSettings.isGrayscale)) {
            return this.forcedSettings.isGrayscale;
        }
        if (!isNil(this.isGrayscale)) {
            return this.isGrayscale;
        }
        if (this.viewTemplate) {
            return this.viewTemplate.isGrayscale;
        }
        return false;
    }
    get resultedIsSaturation() {
        if (!isNil(this.forcedSettings.isSaturation)) {
            return this.forcedSettings.isSaturation;
        }
        if (!isNil(this.isSaturation)) {
            return this.isSaturation;
        }
        if (this.viewTemplate) {
            return this.viewTemplate.isSaturation;
        }
        return false;
    }
    get resultedViewTemplate() {
        if (!this.viewTemplate) {
            return null;
        }
        if (this.setupPreset !== this.viewTemplate.setupPreset && isNil(this.forcedSettings.setupPreset)) {
            return null;
        }
        if (!this.resultedCardFields) {
            return null;
        }
        const areCardFieldsEqual = isEqual(this.resultedCardFields.slice().sort(), this.viewTemplate.cardFields.slice().sort());
        if (!areCardFieldsEqual && isNil(this.forcedSettings.cardFields)) {
            return null;
        }
        if (this.resultedIsGrayscale !== this.viewTemplate.isGrayscale && isNil(this.forcedSettings.isGrayscale)) {
            return null;
        }
        if (this.resultedIsSingleScale !== this.viewTemplate.isSingleScale && isNil(this.forcedSettings.isSingleScale)) {
            return null;
        }
        // todo remove sort from view template
        if (!this.resultedSort || (!this.viewTemplate.sort.isEqual(this.resultedSort) && isNil(this.forcedSettings.sort))) {
            return null;
        }
        return this.viewTemplate;
    }
    get hasSKUField() {
        if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
            return false;
        }
        return this.resultedCardFields.includes(FIELD_SKU.key);
    }
    get hasAltSKUField() {
        if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
            return false;
        }
        return this.resultedCardFields.includes(FIELD_ALT_SKU.key);
    }
    get hasPriceField() {
        if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
            return false;
        }
        return this.resultedCardFields.includes(FIELD_PRICE.key);
    }
    get hasShortViewField() {
        if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
            return false;
        }
        return this.resultedCardFields.includes(FIELD_SHORT_VIEW.key);
    }
    get mediaSizeInPX() {
        // we do not expect anything but number here
        return MEDIA_SIZE_MAP[this.mediaSize];
    }
    get comboFieldPreset() {
        if (this.combo.isSolutionsListMode && this.combo.isMediaModeEnabled) {
            return FieldPresetType.SolutionsMedia;
        }
        if (this.combo.isSolutionsListMode) {
            return FieldPresetType.Solutions;
        }
        if (this.combo.isCollectionMode) {
            return FieldPresetType.CollectionCombo;
        }
        if (this.combo.isMediaModeEnabled || this.combo.selectedView === View.Visual) {
            return FieldPresetType.MediaOnly;
        }
        if (!this.combo.isReferenceComparison && this.combo.isMobileMode) {
            return FieldPresetType.Mobile;
        }
        if (this.combo.isReferenceComparison && this.combo.isMobileMode) {
            return FieldPresetType.MobileReferenceComparison;
        }
        if (this.combo.isReferenceComparison) {
            return FieldPresetType.ReferenceComparison;
        }
        return FieldPresetType.Full;
    }
    setMetricDataDecorator(metricDataDecorator) {
        this.metricDataDecorator = metricDataDecorator;
    }
    setMediaSize = (mediaSize) => {
        this.mediaSize = mediaSize;
    };
    setGridColumnsNumber = (gridColumnsNumber) => {
        this.gridColumnsNumber = gridColumnsNumber;
    };
    setIsSweetLine = (isSweetLine) => {
        this.isSweetLine = isSweetLine;
    };
    applyViewTemplate(viewTemplate) {
        this.viewTemplate = viewTemplate;
        this.setupPreset = null;
        this.isGrayscale = null;
        this.isSaturation = null;
        this.isSingleScale = null;
        this.cardFields = null;
        this.sort = null;
    }
    applySort(sort) {
        this.sort = sort;
    }
    getSetupPresetForProduct(product) {
        if (product.mediaCollection) {
            if (this.resultedSetupPresets) {
                return product.mediaCollection.getSuitableSPFromSPList(this.resultedSetupPresets);
            }
            return product.defaultMedia?.setupPreset ?? null;
        }
        return null;
    }
    serialize() {
        const json = this.sanitizeValues();
        if (json.sort) {
            json.sort = json.sort.transform();
        }
        return json;
    }
    sanitizeValues() {
        const customSP = this.setupPreset?.id ?? null;
        const customViewTemplate = this.viewTemplate?.id ?? null;
        const sanitizedStruct = {
            setupPreset: customSP,
            cardFields: this.resultedCardFields ? [...this.resultedCardFields].sort() : null,
            isSingleScale: this.resultedIsSingleScale,
            isGrayscale: this.resultedIsGrayscale,
            isSaturation: this.resultedIsSaturation,
            isHiddenDesc: this.isHiddenDesc,
            sort: this.resultedSort,
            mediaSize: this.mediaSize,
            type: this.type,
            viewTemplate: customViewTemplate,
            isAutoplayEnabled: this.isAutoplayEnabled,
            gridColumnsNumber: this.gridColumnsNumber,
            isSweetLine: this.isSweetLine,
        };
        if (this.viewTemplate) {
            if (this.viewTemplate.sort.isEqual(this.resultedSort)) {
                sanitizedStruct.sort = null;
            }
            if (this.resultedIsSingleScale === this.viewTemplate.isSingleScale) {
                sanitizedStruct.isSingleScale = null;
            }
            if (this.resultedIsGrayscale === this.viewTemplate.isGrayscale) {
                sanitizedStruct.isGrayscale = null;
            }
            if (this.resultedIsSaturation === this.viewTemplate.isSaturation) {
                sanitizedStruct.isSaturation = null;
            }
            if (this.resultedCardFields === this.viewTemplate.cardFields) {
                sanitizedStruct.cardFields = null;
            }
            if (this.isHiddenDesc === this.viewTemplate.isHiddenDesc) {
                sanitizedStruct.isHiddenDesc = null;
            }
        }
        return omitBy(sanitizedStruct, (v) => isNil(v));
    }
    isEqual(object) {
        return isEqual(this.sanitizeValues(), object.sanitizeValues());
    }
    diff(otherObject) {
        return omitBy(diff(otherObject.sanitizeValues(), this.sanitizeValues()), (v) => isNil(v));
    }
}
__decorate([
    observable
], ProductViewSettings.prototype, "viewTemplate", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "isHiddenDesc", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "isSingleScale", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "isGrayscale", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "isSaturation", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "cardFields", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "setupPreset", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "type", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "mediaSize", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "fps", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "forcedSettings", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "sort", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "combo", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "isAutoplayEnabled", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "metricDataDecorator", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "gridColumnsNumber", void 0);
__decorate([
    observable
], ProductViewSettings.prototype, "isSweetLine", void 0);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedSetupPresets", null);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedSort", null);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedCardFields", null);
__decorate([
    computed
], ProductViewSettings.prototype, "displayedFieldObjects", null);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedIsSingleScale", null);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedIsGrayscale", null);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedIsSaturation", null);
__decorate([
    computed
], ProductViewSettings.prototype, "resultedViewTemplate", null);
__decorate([
    computed
], ProductViewSettings.prototype, "hasSKUField", null);
__decorate([
    computed
], ProductViewSettings.prototype, "hasAltSKUField", null);
__decorate([
    computed
], ProductViewSettings.prototype, "hasPriceField", null);
__decorate([
    computed
], ProductViewSettings.prototype, "hasShortViewField", null);
__decorate([
    computed
], ProductViewSettings.prototype, "mediaSizeInPX", null);
__decorate([
    computed
], ProductViewSettings.prototype, "comboFieldPreset", null);
__decorate([
    action
], ProductViewSettings.prototype, "setMetricDataDecorator", null);
__decorate([
    action
], ProductViewSettings.prototype, "setMediaSize", void 0);
__decorate([
    action
], ProductViewSettings.prototype, "setGridColumnsNumber", void 0);
__decorate([
    action
], ProductViewSettings.prototype, "setIsSweetLine", void 0);
export const SHORT_LIST_VIEW = new ProductViewSettings({
    viewTemplate: null,
    mediaSize: MediaSize.Small,
    cardFields: [FIELD_SHORT_VIEW.key],
    isSingleScale: true,
});
export const TABLE_ROW_VIEW = new ProductViewSettings({
    viewTemplate: null,
    mediaSize: MediaSize.XSmall,
    isHiddenDesc: true,
});
