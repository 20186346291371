import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_DEPTH_COMPOSED = {
    key: 'totalHeight',
    label: 'Depth',
    fullLabel: 'Depth',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            if (p.reportData?.heightPc !== null && p.reportData?.height !== null) {
                return `${p.reportData.heightPc}% (${p.reportData.height} mm)`;
            }
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            if (p.reportData?.heightPc !== null && p.reportData?.height !== null) {
                return p.reportData.heightPc;
            }
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
