// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "product-block-grid";
var _d = "product-block-subgrid";
var _e = "productInfo";
var _f = "productInfoDesc";
var _10 = "productInfoDescRight";
var _11 = "productInfoShortView";
var _12 = "productInfoSmall";
var _13 = "reset-button";
var _14 = "1200px";
var _15 = "992px";
var _16 = "1366px";
var _17 = "768px";
var _18 = "1475px";
var _19 = "576px";
var _1a = "1600px";
var _1b = "320px";
var _1c = "2500px";
var _1d = "single-page-responsive-font-size";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "product-block-grid", _d as "product-block-subgrid", _e as "productInfo", _f as "productInfoDesc", _10 as "productInfoDescRight", _11 as "productInfoShortView", _12 as "productInfoSmall", _13 as "reset-button", _14 as "screenlg", _15 as "screenmd", _16 as "screenml", _17 as "screensm", _18 as "screenxlg", _19 as "screenxs", _1a as "screenxxlg", _1b as "screenxxs", _1c as "screenxxxlg", _1d as "single-page-responsive-font-size" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "product-block-grid": _c, "product-block-subgrid": _d, "productInfo": _e, "productInfoDesc": _f, "productInfoDescRight": _10, "productInfoShortView": _11, "productInfoSmall": _12, "reset-button": _13, "screenlg": _14, "screenmd": _15, "screenml": _16, "screensm": _17, "screenxlg": _18, "screenxs": _19, "screenxxlg": _1a, "screenxxs": _1b, "screenxxxlg": _1c, "single-page-responsive-font-size": _1d }
