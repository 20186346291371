import { jsx as _jsx } from "react/jsx-runtime";
import { HOME_PAGE } from '@/common/constants/pages';
import ErrorPage from '@/common/pages/ErrorPage/ErrorPage';
import Storage, { KEY_AUTH_TOKEN, KEY_URI_AFTER_AUTH } from '@/common/services/Storage';
import AppError from '@/common/state/AppError';
function Auth() {
    const hash = new URLSearchParams(window.location.hash.substring(1))?.get('access_token');
    if (hash) {
        Storage.saveObject(KEY_AUTH_TOKEN, hash, Storage.STORAGE_LOCAL);
        const redirectURI = Storage.getObject(KEY_URI_AFTER_AUTH, Storage.STORAGE_SESSION);
        Storage.removeObject(KEY_URI_AFTER_AUTH, Storage.STORAGE_SESSION);
        window.location.href = redirectURI || HOME_PAGE;
        return null;
    }
    return _jsx(ErrorPage, { error: AppError.createUnauthorizedError() });
}
export default Auth;
