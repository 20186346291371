var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable, reaction } from 'mobx';
import useStore from '@/common/hooks/useStore';
import { MANAGER, securityManager } from '@/common/services/SecurityManager';
import Storage, { KEY_USER } from '@/common/services/Storage';
import UserAPI from '../api/UserAPI';
import { User } from '../entity/User';
import Token from '../services/token';
export default class UserStore {
    userAPI;
    // user undefined only before initial fetch call
    // (after it anon user represented by User object with empty fields)
    user;
    companyUsers = [];
    constructor() {
        this.userAPI = new UserAPI();
        reaction(() => this.user, () => {
            securityManager.setUser(this.user);
        });
    }
    /**
     * @return {boolean}
     */
    get isAuthorized() {
        return !!this.user && !this.user.isAnonymous;
    }
    /**
     * @return {Promise<QueryResult>}
     */
    fetch() {
        if (!Token.accessToken) {
            this.user = new User();
            return Promise.resolve(null);
        }
        return this.userAPI.fetchItem().then((res) => {
            Storage.saveObject(KEY_USER, res.content, Storage.STORAGE_LOCAL);
            this.setUser(new User(res.content));
        });
    }
    fetchCompanyUsers() {
        if (securityManager.isGranted(MANAGER)) {
            this.userAPI.fetchCompanyUsers().then((res) => {
                if (!res.content || !Array.isArray(res.content)) {
                    return;
                }
                this.companyUsers = res.content.map((companyUser) => new User(companyUser));
            });
        }
    }
    setUser(user) {
        this.user = user;
    }
}
__decorate([
    observable.ref
], UserStore.prototype, "user", void 0);
__decorate([
    observable
], UserStore.prototype, "companyUsers", void 0);
__decorate([
    computed
], UserStore.prototype, "isAuthorized", null);
__decorate([
    action
], UserStore.prototype, "fetch", null);
__decorate([
    action
], UserStore.prototype, "fetchCompanyUsers", null);
__decorate([
    action
], UserStore.prototype, "setUser", null);
export const useUserStore = () => useStore(UserStore);
export const userStore = new UserStore();
