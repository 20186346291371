import sortBy from 'lodash/sortBy';
import slice from 'lodash/slice';

export const COLOR_FILTER_SIZE = 9;
export const CLARITY_FILTER_SIZE = 9;
export const CUT_QUALITY_FROM = 1;
export const SYMMETRY_FROM = 1;
export const POLISH_FROM = 1;

/**
 * @param {Object} dictionary
 * @param {?number} from
 * @param {?number} to
 * @return {Array}
 */
export const mergeExtraDictionaryItems = (dictionary, from, to) => {
  const sortedItems = sortBy(dictionary, item => item.position);

  sortedItems.forEach((item, i) => {
    sortedItems[i] = {...item, value: item.id};
  });

  if (from || (to && sortedItems.length > to)) {
    if (from) {
      const firstElementIndex = from;
      // todo
      sortedItems[firstElementIndex].value = slice(sortedItems, 0, firstElementIndex + 1).map(item => item.value);
    }

    if (to) {
      const lastElementIndex = to - 1;
      // todo
      sortedItems[lastElementIndex].value = slice(sortedItems, lastElementIndex).map(item => item.value);
      sortedItems[lastElementIndex].title += '+';
      sortedItems[lastElementIndex].short += '+';
    }

    return slice(sortedItems, from, to);
  }

  return sortedItems;
};

export default class DictionaryConverter {
  /**
   * @param {Object} dictionariesData
   * @return {Object}
   */
  static convert(dictionariesData) {
    dictionariesData.colorSanitized = mergeExtraDictionaryItems(dictionariesData.color, 0, COLOR_FILTER_SIZE);
    dictionariesData.claritySanitized = mergeExtraDictionaryItems(dictionariesData.clarity, 0, CLARITY_FILTER_SIZE);
    dictionariesData.cutQualitySanitized = mergeExtraDictionaryItems(dictionariesData.cutQuality, CUT_QUALITY_FROM);
    dictionariesData.symmetrySanitized = mergeExtraDictionaryItems(dictionariesData.symmetry, SYMMETRY_FROM);
    dictionariesData.girdleThicknessGradeSanitized = mergeExtraDictionaryItems(dictionariesData.girdleThicknessGrade);
    dictionariesData.culetSizeGradeSanitized = mergeExtraDictionaryItems(dictionariesData.culet);
    dictionariesData.polishSanitized = mergeExtraDictionaryItems(dictionariesData.polish, POLISH_FROM);
    dictionariesData.fancyGradeSanitized = mergeExtraDictionaryItems(dictionariesData.fancyGrade, 0, 7);
    dictionariesData.fluorIntensitySanitized = mergeExtraDictionaryItems(dictionariesData.fluorIntensity);
    dictionariesData.setupPresetsSelectData = dictionariesData.setupPresets.map(sp => ({ id: sp.id, title: sp.title }));

    return dictionariesData;
  }
}
