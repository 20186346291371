export default class ColorMetric {
  /**
   * @type {number}
   */
  val;

  /**
   * @type {string}
   */
  ver;

  /**
   * @param {number} val
   * @param {string} ver
   */
  constructor({
    val, ver,
  }) {
    this.val = val;
    this.ver = ver;
  }
}
