import createConfirmation from 'react-confirm/lib/createConfirmation';
import React from 'react';
import PublicCollectionConfirmationContent
  from '../../collection/components/PublicCollectionConfirmationContent/PublicCollectionConfirmationContent';
import {PRIMARY, TERTIARY} from '../components/Button/Button';
import Confirmation from '../components/inputs/Confirmation';

const defaultConfirmation = createConfirmation(Confirmation);

export function confirm(confirmation, options = {}) {
  return defaultConfirmation({ confirmation, ...options });
}

export default class ConfirmationFactory {
  /**
   * @param {string} entityName
   * @param {boolean} isPlural
   * @return {Promise}
   */
  static makeDeleteConfirmation(entityName, isPlural = false) {
    return confirm(`This action cannot be undone. This will permanently delete the ${entityName}${isPlural ? 's' : ''}.`, {
      title: `Delete ${isPlural ? 'these' : 'this'} ${entityName}${isPlural ? 's' : ''}?`,
      okLabel: 'Delete',
      okColor: TERTIARY,
    });
  }

  /**
   * @return {Promise}
   */
  static makePublicCollectionConfirmation() {
    return confirm(<PublicCollectionConfirmationContent />,
      {
        title: 'Some products are hidden',
        okLabel: 'Publish Anyway',
        okColor: PRIMARY,
      });
  }

  /**
   * @return {Promise}
   */
  static makeB2BIdChangeConfirmation() {
    return confirm('Are you going to change b2bSid?', {
      title: 'Are you sure?',
    });
  }

  /**
   * @return {Promise}
   */
  static makeSKUChangeConfirmation() {
    return confirm('SKU was changed. Any previously shared link to this product will not be available after applying changes.', {
      title: 'Sensitive data changing confirmation',
    });
  }

  /**
   * @return {Promise}
   */
  static makeMediaSourceDeleteConfirmation() {
    return confirm('This action cannot be undone. This will permanently delete the media source.', {
      title: 'Remove this media source file?',
      okLabel: 'Delete',
      okColor: TERTIARY,
    });
  }

  /**
   * @return {Promise}
   */
  static makeDropChangesConfirmation() {
    return confirm('Your changes will be dropped', {
      title: 'Sensitive data changing confirmation',
    });
  }

  /**
   * @return {Promise}
   */
  static makeChangeCutShapeConfirmation() {
    return confirm('You have an open full access to the cut model data', {
      title: 'Attention!',
      showCancelButton: false,
    });
  }

  /**
   * @return {Promise}
   */
  static makeProjectRenderingConfirmation(productCount) {
    return confirm(`Do you want to render ${productCount} product(s)?`, {
      title: 'Are you sure?',
    });
  }
}
