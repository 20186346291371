import { jsx as _jsx } from "react/jsx-runtime";
import HtmlField from '@/catalog/components/HtmlField/HtmlField';
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
export const FIELD_COMBINED_COMMENT_AND_SELLER_NOTE = {
    label: 'Comment',
    fullLabel: 'Comment',
    key: 'commentAndSellerNote',
    type: FieldType.HtmlMarkup,
    getter(p) {
        return _jsx(HtmlField, { value: this.getterPlain(p) });
    },
    getterPlain(p) {
        return p.comment?.text || p.sellerNote ? [p.comment?.text, p.sellerNote].filter(Boolean).join('') : null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
