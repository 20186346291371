import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { CUSTOM_GRADE_GIRDLE_BEAUTY } from '@/customGrading/constants/customGradeCodes';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
export const FIELD_CUSTOM_GIRDLE_BEAUTY = {
    label: 'Shape Appeal',
    fullLabel: 'Shape Appeal',
    key: 'girdleBeauty',
    type: FieldType.Scalar,
    spGroupId: SetupPresetGroupType.Office,
    icon: 'metrics-girdle',
    getter(p) {
        return p.customGrades?.[CUSTOM_GRADE_GIRDLE_BEAUTY]?.value?.title ?? null;
    },
    getterPlain(p) {
        return p.customGrades?.[CUSTOM_GRADE_GIRDLE_BEAUTY]?.rawValue ?? null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        return p.customGrades?.[CUSTOM_GRADE_GIRDLE_BEAUTY]?.value
            ? p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY].value.id * -1
            : null;
    },
    contexts: ALL_CONTEXTS,
    isCustomGrade: true,
};
