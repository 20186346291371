export default function formatMoney(amount, formatMoneyParams) {
    const { currency, locale, fractionDigits, minFractionDigits = 2, maxFractionDigits = 4, showSymbol = true, signDisplay, } = formatMoneyParams;
    let amountValue = amount;
    if ((typeof amount !== 'string' && typeof amount !== 'number') ||
        (typeof amount === 'string' && amount.length === 0) ||
        Number.isNaN(+amount)) {
        amountValue = 0;
    }
    const options = {
        useGrouping: true,
        minimumFractionDigits: fractionDigits ?? minFractionDigits,
        maximumFractionDigits: fractionDigits ?? maxFractionDigits,
    };
    if (showSymbol) {
        options.style = 'currency';
        options.currency = currency;
    }
    else {
        options.style = 'decimal';
    }
    if (signDisplay) {
        if (signDisplay === true) {
            options.signDisplay = 'exceptZero';
        }
        else {
            options.signDisplay = signDisplay;
        }
    }
    /*
     * We have custom format of rendering amount values:
     * it should look like "9 999 999.99 $" for all countries and values (only currency symbol is different)
     *
     * We can't get it work with built-in tools, this is why we implement logic below
     * */
    const resultParts = new Intl.NumberFormat(locale, options).formatToParts(+amountValue);
    const resultCurrency = resultParts.find((part) => part.type === 'currency');
    let result = resultParts.reduce((acc, value) => {
        if (value.type === 'group') {
            return `${acc} `;
        }
        if (value.type === 'decimal') {
            return `${acc}.`;
        }
        if (value.type === 'integer' ||
            value.type === 'fraction' ||
            value.type === 'minusSign' ||
            value.type === 'plusSign') {
            return acc + value.value;
        }
        return `${acc}`;
    }, '');
    if (resultCurrency?.value) {
        result += ` ${resultCurrency.value}`;
    }
    return result;
}
