import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CROWN_COMPOSED = {
    key: 'crownComposed',
    label: 'Crown Height',
    fullLabel: 'Crown Height',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            if (p.reportData?.crnHeightPc !== null && p.reportData?.crnHeight !== null) {
                return `${p.reportData.crnHeightPc}% (${p.reportData.crnHeight} mm)`;
            }
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            if (p.reportData?.crnHeightPc !== null && p.reportData?.crnHeight !== null) {
                return p.reportData.crnHeightPc;
            }
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
