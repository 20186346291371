import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import memoize from 'lodash/memoize';
import CollectionsStore from '../../stores/CollectionsStore';
import Collection from '../../entity/Collection';
import './CollectionsList.less';
import Product from '../../../product/entity/Product';
import Button, {SIZE_SMALL} from '../../../common/components/Button/Button';
import Link from '../../../common/components/Link/Link';
import URLProvider from '../../../common/services/URLProvider';
import closePopover from '../../../common/helpers/closePopover';
import Label, {ACCENT, NEUTRAL_LIGHT} from '../../../common/components/Label/Label';
import Icon from '../../../common/components/Icon/Icon';

@inject('collectionsStore')
@observer
export default class CollectionsList extends Component {
  static propTypes = {
    collectionsStore: PropTypes.instanceOf(CollectionsStore).isRequired,
    collections: PropTypes.arrayOf(PropTypes.instanceOf(Collection)).isRequired,
    productContext: PropTypes.instanceOf(Product),
    onItemClick: PropTypes.func,
    withLinks: PropTypes.bool,
  };

  static defaultProps = {
    productContext: null,
    onItemClick: null,
    withLinks: false,
  };

  getOnClickHandler = memoize((c) => {
    return () => this.handleClick(c);
  });

  /**
   * @param {Collection} c
   */
  handleClick = (c) => {
    if (this.props.onItemClick) {
      this.props.onItemClick(c);
    }
  };

  handlePinClick(e, c) {
    e.preventDefault();
    e.stopPropagation();
    this.props.collectionsStore.toggleActive(c);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="wish-collections">
        {this.props.collections && this.props.collections.map((c) => {
          const isIdOfActiveCollection = this.props.collectionsStore.activeCollection && c.id === this.props.collectionsStore.activeCollection.id;

          return (
            <div
              className={`wish-collections__item ${isIdOfActiveCollection ? 'wish-collections__item--active' : ''} ${
                this.props.onItemClick ? 'wish-collections__item--hoverable' : ''
              } ${this.props.productContext && c.hasProduct(this.props.productContext) ? 'wish-collections__item--disabled' : ''}`}
              key={c.id}
              onClick={this.getOnClickHandler(c)}
            >
              <div className="board-item">
                <Button
                  className="board-item__active-ctrl"
                  active={isIdOfActiveCollection}
                  color={isIdOfActiveCollection ? 'default' : 'transparent'}
                  onClick={e => this.handlePinClick(e, c)}
                  withIcon
                  size={SIZE_SMALL}
                >
                  <Icon name="pin-on" />
                </Button>
                <div className="board-item__title">
                  {this.props.withLinks ? (
                    <Link to={URLProvider.collectionDetailPage(c)} onClick={closePopover}>
                      {c.title}
                    </Link>
                  ) : (
                    c.title
                  )}
                </div>
                {c.description && <div className="board-item__desc">{c.description}</div>}
                <div className="board-item__statuses">
                  <Label appearance={c.public === true ? ACCENT : NEUTRAL_LIGHT} className="board-item__status">
                    {c.readableStatus}
                  </Label>
                  <span className="board-item__products-amount">{c.products ? c.products.length : 0} product(s)</span>
                  <span className="board-item__separator">|</span>
                  <span className="board-item__date">{c.createdAtFormatted}</span>
                </div>
                <Button
                  color="transparent"
                  size={SIZE_SMALL}
                  className="board-item__remove"
                  withIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.collectionsStore.remove(c);
                  }}
                >
                  <Icon name="delete" />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
