import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { PRODUCT_EDIT, securityManager, VIEW_PRODUCT_STATUS } from '@/common/services/SecurityManager';
import ProductLabels from '@/product/components/ProductLabels/ProductLabels';
import Diamond from '@/product/entity/Diamond';
import Rough from '@/product/entity/Rough';
export const FIELD_LABELS_EXTENDED = {
    label: '',
    fullLabel: '',
    key: 'labels',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: '',
    getter(p) {
        const isProductValid = p instanceof Diamond || p instanceof Rough;
        if (!isProductValid) {
            return null;
        }
        if (p instanceof Rough) {
            return p.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p }) || null;
        }
        const hasAtLeastOneLabel = p.isLabGrown || p.isPhotoreal || p.isReference || securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p });
        return hasAtLeastOneLabel || null;
    },
    getterPlain(p) {
        const isProductValid = p instanceof Diamond || p instanceof Rough;
        if (!isProductValid) {
            return null;
        }
        if (p instanceof Rough) {
            return p.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p }) || null;
        }
        const hasAtLeastOneLabel = p.isLabGrown || p.isPhotoreal || p.isReference || securityManager.isGranted(VIEW_PRODUCT_STATUS, { product: p });
        return hasAtLeastOneLabel || null;
    },
    decorator(_, p, { centered } = {}) {
        return (_jsx(ProductLabels, { centered: centered, isCreatable: securityManager.isGranted(PRODUCT_EDIT, { product: p }), product: p, showAccess: true }));
    },
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
