import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from 'classnames';
import styles from './Alert.less';
export const ALERT_WARNING = 'warning';
export const ALERT_DANGER = 'danger';
export const ALERT_SUCCESS = 'success';
const TYPE_CLASS_MAP = {
    [ALERT_WARNING]: styles.alertWarning,
    [ALERT_DANGER]: styles.alertDanger,
    [ALERT_SUCCESS]: styles.alertSuccess,
};
export default function Alert(props) {
    const { title = '', type = ALERT_WARNING, className, children } = props;
    return (_jsxs("div", { className: cls(styles.alert, TYPE_CLASS_MAP[type], className), children: [title && _jsx("strong", { children: `${title} ` }), children] }));
}
