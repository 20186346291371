import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useMemo } from 'react';
import { inject } from 'mobx-react';
import Avatar from '@/common/components/Avatar/Avatar';
import Button, { SIZE_SMALL } from '@/common/components/Button/Button';
import Icon from '@/common/components/Icon/Icon';
import FancySelect from '@/common/components/inputs/FancySelect/FancySelect';
import { getDataAccessById, getDataAccessByType, getDataAccessIcon } from '../../../../constants/dataAccess';
import styles from './PrivateAccessUser.less';
function PrivateAccessUser(props) {
    const { user, isUpdating, type, shouldDisableDataAccess, updateACLByEntity, removeACLByAssignee, } = props;
    const dataAccessOptions = useMemo(() => {
        let accessOptions = getDataAccessByType(type).map((level) => ({
            value: level.id,
            label: level.title,
            icon: level.icon,
            description: level.description,
        }));
        accessOptions = accessOptions.filter((option) => {
            // do not render already selected options
            if (user.dataAccess.length === 1) {
                return option.value !== user.dataAccess[0];
            }
            return true;
        });
        accessOptions.push({
            value: 'remove',
            label: 'Remove Access',
            icon: 'close',
            className: styles.personalAccessOptionRemove,
        });
        return accessOptions;
    }, [type, JSON.stringify(user.dataAccess)]);
    const onChange = useCallback((option) => {
        if (!option) {
            return;
        }
        const value = option[0].value;
        if (value === 'remove') {
            removeACLByAssignee(user.assignee.id);
            return;
        }
        updateACLByEntity(user.assignee.id, option[0].value);
    }, [user.assignee.id]);
    return (_jsx(FancySelect, { className: styles.personalAccessUser, hideArrow: true, isDisabled: isUpdating, onChange: onChange, options: dataAccessOptions, renderPlaceholder: () => (_jsxs("div", { className: styles.personalAccessUserContent, children: [_jsx("div", { children: _jsx(Avatar, { className: styles.personalAccessAvatar, size: "large", username: user.assignee.name }) }), _jsxs("div", { className: styles.personalAccessDescription, children: [_jsx("div", { children: user.assignee.name }), !shouldDisableDataAccess && (_jsx("div", { className: styles.personalAccessDescriptionAccess, children: new Set(user.dataAccess).size === 1 ? (_jsxs(Fragment, { children: [_jsx(Icon, { className: styles.personalAccessDescriptionIcon, name: getDataAccessIcon(user.dataAccess[0], type) }), _jsx("span", { className: styles.personalAccessDescriptionName, children: getDataAccessById(user.dataAccess[0], type)?.title })] })) : (_jsx("span", { className: styles.personalAccessDescriptionName, children: "Mixed Security" })) }))] }), _jsx(Button, { className: styles.personalAccessMore, color: "transparent", size: SIZE_SMALL, withIcon: true, children: _jsx(Icon, { name: "more" }) })] })), styles: {
            valueContainer: (base) => ({
                ...base,
                padding: 0,
                flexWrap: 'nowrap',
            }),
        } }));
}
export default inject(({ privateAccessStore }) => ({
    isUpdating: privateAccessStore.isUpdating,
    type: privateAccessStore.type,
    shouldDisableDataAccess: privateAccessStore.shouldDisableDataAccess,
    updateACLByEntity: privateAccessStore.updateACLByEntity,
    removeACLByAssignee: privateAccessStore.removeACLByAssignee,
}))(PrivateAccessUser);
