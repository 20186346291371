import { MediaSize } from './sizeCodes';
import { MEDIA_SIZE_40, MEDIA_SIZE_80, MEDIA_SIZE_160, MEDIA_SIZE_320, MEDIA_SIZE_480, MEDIA_SIZE_640 } from './sizes';
export const MEDIA_SIZE_MAP = {
    [MediaSize.XXSSmall]: MEDIA_SIZE_40,
    [MediaSize.XSmall]: MEDIA_SIZE_80,
    [MediaSize.Small]: MEDIA_SIZE_160,
    [MediaSize.Medium]: MEDIA_SIZE_320,
    [MediaSize.Large]: MEDIA_SIZE_480,
    [MediaSize.XLarge]: MEDIA_SIZE_640,
    [MediaSize.Full]: MediaSize.Full,
};
export const mediaSizeToPx = (mediaSize) => {
    if (mediaSize === MediaSize.Full) {
        throw new Error('MediaSize.Full not supported');
    }
    return MEDIA_SIZE_MAP[mediaSize];
};
