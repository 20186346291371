export function filterProductsWithStereo(products, sp) {
    if (!products || products.length === 0) {
        return [];
    }
    return products
        .filter((product) => {
        if (!product.mediaCollection) {
            return null;
        }
        const media = product.mediaCollection.getMediaByMultipleSetupPresets(sp || []);
        if (!media || !media.stereo) {
            return null;
        }
        return media.stereo.isStereoAvailable;
    })
        .filter((p) => Boolean(p));
}
