import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import cls from 'classnames';
import { SIZE_LARGE, SIZE_MEDIUM } from '@/common/components/Label/Label';
import { useToggle } from '@/common/hooks/useToggle/useToggle';
import { AccessLevel } from '@/security/constants/access';
import { ProductDataAccess, ProjectDataAccess } from '@/security/constants/dataAccess';
import AccessLabel from '../AccessLabel/AccessLabel';
import AccessModal from '../AccessModal/AccessModal';
import styles from './AccessStatus.module.css';
const SIZE_CLASS_MAP = {
    [SIZE_MEDIUM]: styles.rootMedium,
    [SIZE_LARGE]: styles.rootLarge,
};
function AccessStatus(props) {
    const { product, project, size = SIZE_LARGE, isCreatable = false, onUpdate, className } = props;
    const [isAccessModalOpen, openAccessModal, closeAccessModal] = useToggle();
    const entity = (product ?? project);
    if (!entity) {
        return null;
    }
    const type = product ? 'product' : 'project';
    const access = product?.access ?? project?.access;
    const dataAccess = product?.dataAccess ?? project?.dataAccess;
    if (typeof access === 'undefined') {
        return null;
    }
    function onClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!isCreatable) {
            return;
        }
        openAccessModal();
    }
    function getStatusTitle() {
        if (type === 'project') {
            if (access === AccessLevel.Restricted) {
                return 'Project is not available outside the company.';
            }
            if (access === AccessLevel.Public) {
                if (dataAccess === ProjectDataAccess.HideProjectSource) {
                    return 'Project is available to anyone. HPC source is hidden.';
                }
                if (dataAccess === ProjectDataAccess.ShowAllData) {
                    return 'Project is available to anyone. HPC source is available.';
                }
            }
        }
        if (type === 'product') {
            if (access === AccessLevel.Restricted) {
                return 'Product is not available outside the company.';
            }
            if (access === AccessLevel.Unlisted) {
                if (dataAccess === ProductDataAccess.HideAllDesignData) {
                    return 'Product is not available in Catalog (only by direct link). All cut design data is hidden.';
                }
                if (dataAccess === ProductDataAccess.HideModelReports) {
                    return 'Product is not visible in Catalog (only by direct link). 3D model and reports are hidden.';
                }
                if (dataAccess === ProductDataAccess.ShowAllData) {
                    return 'Product is not visible in Catalog (only by direct link). All cut design data is available.';
                }
            }
            if (access === AccessLevel.Public) {
                if (dataAccess === ProductDataAccess.HideAllDesignData) {
                    return 'Product is available to anyone. All cut design data is hidden.';
                }
                if (dataAccess === ProductDataAccess.HideModelReports) {
                    return 'Product is available to anyone. 3D models and reports are hidden.';
                }
                if (dataAccess === ProductDataAccess.ShowAllData) {
                    return 'Product is available to anyone. All cut design data is available.';
                }
            }
        }
        return '';
    }
    return (_jsxs(Fragment, { children: [_jsx("div", { className: cls(styles.root, { [styles.interactive]: isCreatable }, SIZE_CLASS_MAP[size], className), onClick: onClickHandler, title: getStatusTitle(), children: _jsx(AccessLabel, { access: access, block: true, dataAccess: dataAccess, size: size, type: type }) }), _jsx(AccessModal, { entities: [entity], isOpen: isAccessModalOpen, onClose: closeAccessModal, onSuccess: onUpdate, type: type })] }));
}
export default AccessStatus;
