// extracted by mini-css-extract-plugin
var _1 = "BSXiktdPmA4QXIodX9aj";
var _2 = "aiEmQqUu8eVUXtGMb4Mq";
var _3 = "AwlAbdjj5rMOstVOS3uc";
var _4 = "cbpiuybYj53EMRTEMQlf";
var _5 = "cXUQhidZfi_GeEAAHjvx";
var _6 = "p9HT7j7woyVW_O6O4MPO";
var _7 = "xsGwcLUPhmZBDhRTUOwO";
var _8 = "OjqUpXNJc48ppCT3FqXg";
var _9 = "x1ZyRI67sjDYwsywM9VK";
var _a = "ZDzRUovMbE9rv0uiJLzr";
var _b = "ro4VIKfdI1TwwF06boFs";
export { _1 as "avatar", _2 as "avatarContainer", _3 as "avatarFirstLetter", _4 as "avatarImageContainer", _5 as "avatarLarge", _6 as "avatarMedium", _7 as "avatarName", _8 as "avatarOnlineBadge", _9 as "avatarSmall", _a as "companyName", _b as "userName" }
export default { "avatar": _1, "avatarContainer": _2, "avatarFirstLetter": _3, "avatarImageContainer": _4, "avatarLarge": _5, "avatarMedium": _6, "avatarName": _7, "avatarOnlineBadge": _8, "avatarSmall": _9, "companyName": _a, "userName": _b }
