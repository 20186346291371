export default class Expenses {
    nextAmount;
    nextDays;
    expenses;
    constructor({ nextAmount, nextDays, expenses }) {
        this.nextAmount = nextAmount;
        this.nextDays = nextDays;
        this.expenses = expenses;
    }
}
