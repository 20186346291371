import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CULET_COMPOSED = {
    key: 'culetComposed',
    label: 'Culet',
    fullLabel: 'Culet',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-culet',
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            if (p.reportData?.culetPc !== null && p.reportData?.culet !== null) {
                return `${p.reportData.culetPc}% (${p.reportData.culet} mm)`;
            }
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.culetPc !== null ? p.reportData.culetPc : null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
