import CutwiseAPIClient, { METHOD_DELETE, METHOD_GET, METHOD_PATCH } from '@/common/network/CutwiseAPIClient';
export const PATH_SECURITY_ACL = 'api/security/acl';
export const PATH_SECURITY_ACL_PRODUCT = 'api/security/acl/product';
export const PATH_SECURITY_ACL_PROJECT = 'api/security/acl/project';
export const PATH_SECURITY_ACCESS = 'api/security/access';
export const PATH_SECURITY_ASSIGNEE_LIST = 'api/security/assignee/list';
export default class SecurityAPI {
    getAssigneeList() {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_SECURITY_ASSIGNEE_LIST);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    getACLList(ids, type) {
        const path = type === 'project' ? PATH_SECURITY_ACL_PROJECT : PATH_SECURITY_ACL_PRODUCT;
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(path).addQueryStringParam('id', ids);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    updateACL(data) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_PATCH).path(PATH_SECURITY_ACL).body(data);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    deleteACL(data) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_DELETE).path(PATH_SECURITY_ACL).body(data);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
    updateAccess(data) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_PATCH).path(PATH_SECURITY_ACCESS).body(data);
        return CutwiseAPIClient.sendQuery(qb.getQuery());
    }
}
