import sortBy from 'lodash/sortBy';
import { action, computed, observable } from 'mobx';
import {
  I_FACEUP_FLUOR_DARK, I_OFFICE, I_SIDE_FLUOR_DARK, OFFICE,
} from './SetupPreset';
import {setupPresetRepository} from '../repository/SetupPresetRepository';
import Media from './Media';
import MediaSourcesAPI from '../api/MediaSourcesAPI';
import {AVAILABLE_POLISHES, POLISHES_SORTING} from './MediaSubFormat';

const BLOCKED_SETUP_PRESETS = [I_FACEUP_FLUOR_DARK, I_SIDE_FLUOR_DARK];

// todo maybe mediaItems and polishImages should be in specific components to reduce dependencies in component tree
export default class MediaCollection {
  /**
   * @type Product
   */
  product;

  /**
   * @type {Media[]}
   */
  @observable
  items;

  /**
   * @type {MediaSourcesAPI}
   */
  mediaSourcesAPI;

  /**
   * @param {Object[]} modelData
   * @param {Product} product
   */
  constructor(modelData, product) {
    this.product = product;

    const setupPresetOrder = setupPresetRepository.getSetupPresetOrder();
    const filteredData = modelData.filter(d => !BLOCKED_SETUP_PRESETS.includes(d.sp)); // prevent displaying FLUOR_DARK preset on frontend

    const mediaCollection = filteredData.map(mediaModelData => new Media(mediaModelData, this.product));
    this.items = sortBy(mediaCollection, (media) => {
      const index = setupPresetOrder.findIndex(spId => spId === media.setupPreset.id);

      if (index < 0) {
        return Infinity;
      }

      return index;
    });

    this.mediaSourcesAPI = new MediaSourcesAPI();
  }

  /**
   * @returns {Media[]}
   */
  @computed
  get mediaItems() {
    return this.items ? this.items.filter(item => (item.isImage || item.isVideo) && !item.isEmpty) : [];
  }

  /**
   * @returns {Media[]}
   */
  @computed
  get mediaItemsWithoutCustom() {
    return this.mediaItems ? this.mediaItems.filter(m => m.setupPreset.isStandard === true) : [];
  }

  /**
   * @returns {Media[]}
   */
  @computed
  get mediaItemsWithEmptySources() {
    return this.items ? this.items.filter(item => item.isImage || item.isVideo) : [];
  }

  /**
   * @returns {Object[]}
   */
  @computed
  get polishImages() {
    const polishMedias = this.items ? this.items.filter(item => item.isPolish) : [];

    if (polishMedias.length === 0 || polishMedias[0].mediaSources.length === 0 || polishMedias[0].mediaSources[0].mediaSubFiles.length === 0) {
      return [];
    }

    return this.getPolishImagesFromMedias(polishMedias);
  }

  /**
   * @return {Media[]}
   */
  @computed
  get dmcModels() {
    return this.items ? this.items.filter(item => item.is3DModel) : [];
  }

  /**
   * @return {Media[]}
   */
  @computed
  get i3dReports() {
    return this.items ? this.items.filter(item => item.isI3DReport) : [];
  }

  /**
   * @return {Media[]}
   */
  @computed
  get i3dComparativeReports() {
    return this.items ? this.items.filter(item => item.isI3DComparativeReport) : [];
  }

  /**
   * @return {Media[]}
   */
  @computed
  get htmlReports() {
    return this.items ? this.items.filter(item => item.isPolish) : [];
  }

  /**
   * @return {SetupPreset[]}
   */
  @computed
  get allMediaSetupPresets() {
    if (!this.mediaItems) {
      return [];
    }

    return [...new Set(this.mediaItems.map(m => m.setupPreset))];
  }

  /**
   * @type {MediaSource} mediaSourceToRemove
   * @return {Promise}
   */
  @action
  removeMediaSource(mediaSourceToRemove) {
    if (this.items) {
      this.items.forEach((media) => {
        media.mediaSources = media.mediaSources.filter(mediaSource => mediaSource.id !== mediaSourceToRemove.id);
      });
    }

    // TODO: MOVE TO STORE
    return this.mediaSourcesAPI.remove(mediaSourceToRemove.id);
  }

  /**
   * @type {SetupPreset} setupPreset
   * @return {?Media} media
   */
  getMediaBySetupPreset(setupPreset) {
    if (!setupPreset || !this.items || !this.items.length) {
      return null;
    }

    return this.items.find(m => m.setupPreset.id === setupPreset.id);
  }

  /**
   * @param {SetupPresetGroup} spg
   * @return {?Media}
   */
  getMediaBySetupPresetGroup(spg) {
    return this.getMediaByMultipleSetupPresets(spg.sp);
  }

  /**
   * Looking for first suitable SP depending on spIds list. It's used for SP grouping
   * @type {SetupPreset[]} sps
   * @return {?SetupPreset}
   */
  getSuitableSPFromSPList(sps) {
    const media = this.getMediaByMultipleSetupPresets(sps);
    if (!media) {
      return null;
    }

    return media.setupPreset;
  }

  /**
   * @param {SetupPreset[]} sps
   * @return {?Media}
   */
  getMediaByMultipleSetupPresets(sps) {
    if (!sps || sps.length === 0) {
      return null;
    }

    for (let i = 0; i < sps.length; i++) {
      const media = this.getMediaBySetupPreset(sps[i]);
      if (media && !media.isEmpty) {
        return media;
      }
    }

    return null;
  }

  /**
   * @param {Media[]} polishMedias
   * @return {Object[]}
   */
  getPolishImagesFromMedias(polishMedias) {
    if (!polishMedias || !polishMedias.length) {
      return [];
    }

    const subFiles = polishMedias[0].mediaSources[0].mediaSubFiles;

    const filteredSubFiles = subFiles ? subFiles.filter(item => item.mediaSubFormat && AVAILABLE_POLISHES.includes(item.mediaSubFormat.id)) : [];
    const sortedPolishes = POLISHES_SORTING.map(polishId => filteredSubFiles.find(polish => polish.mediaSubFormat.id === polishId));

    return sortedPolishes.filter(Boolean); // returns only not-falsy values
  }

  /**
   * @return {boolean}
   */
  @computed
  get hasSpreadMedia() {
    const hasOfficeImage = Boolean(this.getMediaBySetupPreset(setupPresetRepository.findSetupPresetById(I_OFFICE)));
    const hasOfficeVideo = Boolean(this.getMediaBySetupPreset(setupPresetRepository.findSetupPresetById(OFFICE)));

    return hasOfficeImage || hasOfficeVideo;
  }

  /**
   * @param {string} id
   * @return {?MediaSource}
   */
  findMediaSourceById(id) {
    if (!this.mediaItems || this.mediaItems.length === 0) {
      return null;
    }

    for (let i = 0; i < this.mediaItems.length; i++) {
      if (!this.mediaItems[i].mediaSources || this.mediaItems[i].mediaSources.length === 0) {
        continue;
      }

      const mediaSource = this.mediaItems[i].mediaSources.find(ms => ms.id === id);

      if (mediaSource) {
        return mediaSource;
      }
    }

    return null;
  }
}
