import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
import Diamond from '@/product/entity/Diamond';
export const FIELD_FACE_BRIGHTNESS = {
    label: 'Face Brightness',
    fullLabel: 'Face-up Brightness',
    key: 'brightness.faceup',
    type: FieldType.Scalar,
    spGroupId: SetupPresetGroupType.Office,
    icon: 'metrics-brightness-faceup',
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.brightnessMetric) {
            return p.brightnessMetric.faceup;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.brightnessMetric) {
            return p.brightnessMetric.faceup;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
