// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "pageError";
var _d = "pageErrorCode";
var _e = "pageErrorDescription";
var _f = "pageErrorDescriptionHasLinks";
var _10 = "pageErrorDescriptionLinks";
var _11 = "pageErrorImage";
var _12 = "pageErrorWrapper";
var _13 = "product-block-grid";
var _14 = "product-block-subgrid";
var _15 = "reset-button";
var _16 = "1200px";
var _17 = "992px";
var _18 = "1366px";
var _19 = "768px";
var _1a = "1475px";
var _1b = "576px";
var _1c = "1600px";
var _1d = "320px";
var _1e = "2500px";
var _1f = "single-page-responsive-font-size";
var _20 = "withMarginBottom";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "pageError", _d as "pageErrorCode", _e as "pageErrorDescription", _f as "pageErrorDescriptionHasLinks", _10 as "pageErrorDescriptionLinks", _11 as "pageErrorImage", _12 as "pageErrorWrapper", _13 as "product-block-grid", _14 as "product-block-subgrid", _15 as "reset-button", _16 as "screenlg", _17 as "screenmd", _18 as "screenml", _19 as "screensm", _1a as "screenxlg", _1b as "screenxs", _1c as "screenxxlg", _1d as "screenxxs", _1e as "screenxxxlg", _1f as "single-page-responsive-font-size", _20 as "withMarginBottom" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "pageError": _c, "pageErrorCode": _d, "pageErrorDescription": _e, "pageErrorDescriptionHasLinks": _f, "pageErrorDescriptionLinks": _10, "pageErrorImage": _11, "pageErrorWrapper": _12, "product-block-grid": _13, "product-block-subgrid": _14, "reset-button": _15, "screenlg": _16, "screenmd": _17, "screenml": _18, "screensm": _19, "screenxlg": _1a, "screenxs": _1b, "screenxxlg": _1c, "screenxxs": _1d, "screenxxxlg": _1e, "single-page-responsive-font-size": _1f, "withMarginBottom": _20 }
