export default class SpreadFormatter {
  /**
   * @param {number} value
   * @param {number} fractionNumber
   * @param {string} postfix
   * @return {string}
   */
  static format(value, fractionNumber, postfix) {
    if (value === null) {
      return '';
    }

    if (value === 0) {
      return `0${postfix}`;
    }

    return `${value > 0 ? '+' : ''}${value.toFixed(fractionNumber)}${postfix}`;
  }
}
