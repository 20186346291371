var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
import Analytics, { DEFAULT_CATEGORY } from '../services/Analytics';
export default class AnalyticsStore {
    category = DEFAULT_CATEGORY;
    changeCategory = (category) => {
        this.category = category;
    };
    sendEventWithContext = (eventName, dimensions = {}) => {
        Analytics.sendEvent(this.category, eventName, dimensions);
    };
    sendEventWithProductAsDimensions = (eventName, product) => {
        const dimensions = product ? { id: product.id, b2bId: product.sellerId, b2bSid: product.b2bSid } : {};
        Analytics.sendEvent(this.category, eventName, dimensions);
    };
    sendPageView = (page, dimensions = {}) => {
        Analytics.sendPageview({ page: page }, dimensions);
    };
}
__decorate([
    observable
], AnalyticsStore.prototype, "category", void 0);
__decorate([
    action
], AnalyticsStore.prototype, "changeCategory", void 0);
export const useAnalyticsStore = () => useStore(AnalyticsStore);
export const analyticsStore = new AnalyticsStore();
