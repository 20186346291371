import shareAccessHelp from 'assets/share-access-help.png';
import React from 'react';
import styles from './PublicCollectionConfirmationContent.less';

export default function PublicCollectionConfirmationContent() {
  return (
    <>
      This collection contains products that have one of the next statuses:
      Restricted (products are not published and not available) or Unlisted (only via direct Widget or DiLog links).
      To configure access to these products use the Share Products Access button.
      <img
        width={497}
        height={255}
        className={styles.publicCollectionConfirmationContentImg}
        src={shareAccessHelp}
        loading="lazy"
      />
    </>
  );
}
