import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import AppError from '../../state/AppError';

@withRouter
export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error: error };
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname || nextProps.children !== this.props.children) {
      this.setState({
        error: null,
      });
    }
  }

  componentDidCatch(error, info) {
    // todo: error logging
    console.warn(error, info);
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage error={this.state.error instanceof AppError ? this.state.error : AppError.createDefaultError()} />
      );
    }
    return this.props.children;
  }
}
