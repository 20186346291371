export var ProductType;
(function (ProductType) {
    ProductType["Diamond"] = "diamond";
    ProductType["DiamondColorless"] = "diamond-colorless";
    ProductType["DiamondFancy"] = "diamond-fancy";
    ProductType["LGD"] = "lgd";
    ProductType["LGDColorless"] = "lgd-colorless";
    ProductType["LGDFancy"] = "lgd-fancy";
    ProductType["Rough"] = "rough";
    ProductType["LGDRough"] = "lgd-rough";
    ProductType["Jewelry"] = "jewelry";
})(ProductType || (ProductType = {}));
export const TYPE_DIAMOND = ProductType.Diamond;
export const TYPE_DIAMOND_COLORLESS = ProductType.DiamondColorless;
export const TYPE_DIAMOND_FANCY = ProductType.DiamondFancy;
export const TYPE_LGD = ProductType.LGD;
export const TYPE_LGD_COLORLESS = ProductType.LGDColorless;
export const TYPE_LGD_FANCY = ProductType.LGDFancy;
export const TYPE_ROUGH = ProductType.Rough;
export const TYPE_LGD_ROUGH = ProductType.LGDRough;
export const TYPE_JEWELRY = ProductType.Jewelry;
export const COMPARE_DIAMOND_GROUP = new Set([
    ProductType.DiamondColorless,
    ProductType.DiamondFancy,
    ProductType.LGDColorless,
    ProductType.LGDFancy,
]);
export const COMPARE_ROUGH_GROUP = new Set([ProductType.Rough, ProductType.LGDRough]);
export const ALL_PRODUCT_TYPES = [
    ProductType.Diamond,
    ProductType.DiamondColorless,
    ProductType.DiamondFancy,
    ProductType.LGD,
    ProductType.LGDColorless,
    ProductType.LGDFancy,
    ProductType.LGDRough,
    ProductType.Jewelry,
    ProductType.Rough,
];
export const reverseProductType = (productType) => {
    switch (productType) {
        case ProductType.DiamondColorless:
            return ProductType.LGDColorless;
        case ProductType.DiamondFancy:
            return ProductType.LGDFancy;
        case ProductType.Rough:
            return ProductType.LGDRough;
        case ProductType.LGDColorless:
            return ProductType.DiamondColorless;
        case ProductType.LGDFancy:
            return ProductType.DiamondFancy;
        case ProductType.LGDRough:
            return ProductType.Rough;
        default:
            return productType;
    }
};
