import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_COLOR_GRADE = {
    label: 'Color',
    fullLabel: 'Color',
    key: 'fancyColor',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-color',
    getter(p) {
        if (p instanceof Diamond) {
            return p.colorGrade;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond) {
            if (p.color) {
                return p.color.position * -1;
            }
            return p.colorGrade ?? null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.color) {
            return p.color.position * -1;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
