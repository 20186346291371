import memoize from 'lodash/memoize';
import {observable} from 'mobx';
import {MAIN_SHAPES_IDS, SHAPE_OTHER} from '../../product/entity/Cutshape';
import DictionaryConverter from '../services/DictionaryConverter';

/**
 * @typedef {Object} DictionaryItem
 * @property {number} id
 * @property {string} title
 * @property {string} short
 * @property {string} pricingLink
 * @property {number} position
 * @property {DictionaryItem[]} children
 */

export default class DictionaryRepository {
  @observable.ref
  dictionaries;

  /**
   * @private
   * @param {DictionaryItem[]} colorHue
   * @return {DictionaryItem[]}
   */
  static modifyFancyColors(colorHue) {
    const result = [];
    colorHue.forEach((item) => {
      result.push(item);
      if (item.children && item.children.length) {
        item.children.forEach((child) => {
          result.push(child);
        });
      }
    });

    return result;
  }

  /**
   * @see https://octonus-teams.com/jira/browse/CUT-7019
   * @private
   * @param {DictionaryItem[]} shapes
   * @return {DictionaryItem[]}
   */
  static modifyShapes(shapes) {
    const shapeOther = shapes.find(s => s.id === SHAPE_OTHER);
    shapeOther.children = shapes.filter(s => MAIN_SHAPES_IDS.includes(s.id) === false); // add all rare cutshapes to 'Other' as children

    return shapes.filter(s => MAIN_SHAPES_IDS.includes(s.id) === true);
  }

  initDictionaries(dictionariesData) {
    dictionariesData.colorHueAll = DictionaryRepository.modifyFancyColors(dictionariesData.colorHue);
    dictionariesData.cutShape = DictionaryRepository.modifyShapes(dictionariesData.cutShape);
    this.dictionaries = DictionaryConverter.convert(dictionariesData);
  }

  constructor() {
    this.getColorById = memoize(this.getColorById);
    this.getClarityById = memoize(this.getClarityById);
    this.getCutQualityById = memoize(this.getCutQualityById);
    this.getSymmetryById = memoize(this.getSymmetryById);
    this.getPolishById = memoize(this.getPolishById);
    this.getFluorescenceStrengthById = memoize(this.getFluorescenceStrengthById);
    this.getFancyGradeById = memoize(this.getFancyGradeById);
    this.getFancyColorDescHueById = memoize(this.getFancyColorDescHueById);
    this.getColorModifierById = memoize(this.getColorModifierById);
    this.getGirdleThicknessGradeById = memoize(this.getGirdleThicknessGradeById);
    this.getFluorescenceColorById = memoize(this.getFluorescenceColorById);
    this.getLaboratoryById = memoize(this.getLaboratoryById);
    this.getCuletSizeGradeById = memoize(this.getCuletSizeGradeById);
    this.getColorByTitle = memoize(this.getColorByTitle);
    this.getCutShapeById = memoize(this.getCutShapeById);
  }

  /**
   * @param {number} id
   * @return {number}
   */
  getFancyGradePercentGrade(id) {
    return (this.dictionaries.fancyGrade.length - this.dictionaries.fancyGrade.findIndex(i => i.id === id)) * (100 / this.dictionaries.fancyGrade.length);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getColorById(id) {
    return this.findItemInDictionaryById('color', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getClarityById(id) {
    return this.findItemInDictionaryById('clarity', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getCutQualityById(id) {
    return this.findItemInDictionaryById('cutQuality', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getSymmetryById(id) {
    return this.findItemInDictionaryById('symmetry', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getPolishById(id) {
    return this.findItemInDictionaryById('polish', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getFluorescenceStrengthById(id) {
    return this.findItemInDictionaryById('fluorIntensity', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getFancyGradeById(id) {
    return this.findItemInDictionaryById('fancyGrade', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getFancyColorDescHueById(id) {
    return this.findItemInDictionaryById('colorHueAll', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getColorModifierById(id) {
    return this.findItemInDictionaryById('colorModifier', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getGirdleThicknessGradeById(id) {
    return this.findItemInDictionaryById('girdleThicknessGrade', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getFluorescenceColorById(id) {
    return this.findItemInDictionaryById('fluorColor', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getLaboratoryById(id) {
    return this.findItemInDictionaryById('laboratory', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getCuletSizeGradeById(id) {
    return this.findItemInDictionaryById('culet', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getCutShapeById(id) {
    return this.findItemInDictionaryById('cutShape', id);
  }

  /**
   * @param {string} title
   * @return {?DictionaryItem}
   */
  getColorByTitle(title) {
    return this.findItemInDictionaryByTitle('color', title);
  }

  /**
   * @param {string} title
   * @return {?number}
   */
  getIndexByColorTitle(title) {
    return this.findIndexInDictionaryByTitle('color', title);
  }

  /**
   * @param {string} id
   * @return {?DictionaryItem}
   */
  getMilkyById(id) {
    return this.findItemInDictionaryById('milky', id);
  }

  /**
   * @param {string} id
   * @return {?DictionaryItem}
   */
  getTreatmentById(id) {
    return this.findItemInDictionaryById('treatment', id);
  }

  /**
   * @param {number} id
   * @return {?DictionaryItem}
   */
  getServiceById(id) {
    return this.findItemInDictionaryById('services', id);
  }

  /**
   * @param {string} dictName
   * @param {number} id
   * @return {?DictionaryItem}
   */
  findItemInDictionaryById(dictName, id) {
    if (!this.dictionaries || !this.dictionaries[dictName]) {
      return null;
    }

    return this.dictionaries[dictName].find(i => i.id === id);
  }

  /**
   * @param {string} dictName
   * @param {string} title
   * @return {?DictionaryItem}
   */
  findItemInDictionaryByTitle(dictName, title) {
    if (!this.dictionaries || !this.dictionaries[dictName]) {
      return null;
    }

    return this.dictionaries[dictName].find(i => i.title.toUpperCase() === title.toUpperCase());
  }

  /**
   * @param {string} dictName
   * @param {string} title
   * @return {?number}
   */
  findIndexInDictionaryByTitle(dictName, title) {
    if (!this.dictionaries || !this.dictionaries[dictName]) {
      return null;
    }

    return this.dictionaries[dictName].findIndex(i => i.title.toUpperCase() === title.toUpperCase());
  }
}

export const dictionaryRepository = new DictionaryRepository();
