import { NETBOX_KEY } from '@/common/network/CutwiseAPIClient';
import storage from '@/common/services/Storage';
import { NEXTBOX_DOMAIN } from '@/netbox/constants/network';
export function shouldConvertToNetbox(url) {
    if (!url) {
        return false;
    }
    /*
     * If url already contains netbox prefix - do not change it again and do not rewrite request
     * */
    if (url.includes(NEXTBOX_DOMAIN)) {
        return false;
    }
    const isCDN = url.includes('files-cdn.cutwise.com');
    const isCloudfront = url.includes('cloudfront.net');
    const isScale = /scale.*cutwise/g.test(url);
    const isDMCloud = /dmcloud.*cutwise/g.test(url);
    const isExt = /ext.*cutwise/g.test(url);
    const isGAnalytic = url.includes('google') && url.includes('analytics');
    return isCDN || isCloudfront || isScale || isDMCloud || isExt || isGAnalytic;
}
export function netboxifyURL(targetURL) {
    if (!shouldConvertToNetbox(targetURL)) {
        return targetURL;
    }
    /*
     * "localStorage" doesn't work on MS Edge in service worker,
     * this is why we have to functions for convertation
     * */
    if (!storage.getObject(NETBOX_KEY, storage.STORAGE_LOCAL)) {
        return targetURL;
    }
    return convertToNetboxURL(targetURL);
}
// should be used only in sw.js (service worker)
export function convertToNetboxURL(targetURL) {
    if (!targetURL) {
        return targetURL;
    }
    /*
     * Find only first match with separator, because URL might contain two separators, e.g.
     * https://dmcloud.cutwise.com/carbon-viewer/?modelPath=https://cutwise.com/api/v2/projects/cvconfig/75f0760f-22fe-40cb-b3df-9f36b7cee688?hide_toolbar=1&disable_rmouse=1'
     * */
    const separator = '://';
    const [prefix, url] = [targetURL.split(separator, 1).toString(), targetURL.split(separator).slice(1).join(separator)];
    return `${prefix}${separator}${NEXTBOX_DOMAIN}/resource/${url}`;
}
