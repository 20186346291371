export var SetupPresetGroupType;
(function (SetupPresetGroupType) {
    SetupPresetGroupType[SetupPresetGroupType["Aset"] = 1] = "Aset";
    SetupPresetGroupType[SetupPresetGroupType["TableColorLab"] = 4] = "TableColorLab";
    SetupPresetGroupType[SetupPresetGroupType["PavColorLab"] = 5] = "PavColorLab";
    SetupPresetGroupType[SetupPresetGroupType["Macbeth"] = 6] = "Macbeth";
    SetupPresetGroupType[SetupPresetGroupType["Darkfield"] = 7] = "Darkfield";
    SetupPresetGroupType[SetupPresetGroupType["Office"] = 11] = "Office";
    SetupPresetGroupType[SetupPresetGroupType["FlourTable"] = 12] = "FlourTable";
    SetupPresetGroupType[SetupPresetGroupType["FlourSide"] = 13] = "FlourSide";
    SetupPresetGroupType[SetupPresetGroupType["Swinging"] = 14] = "Swinging";
    SetupPresetGroupType[SetupPresetGroupType["FullRotation"] = 15] = "FullRotation";
    SetupPresetGroupType[SetupPresetGroupType["ViBOX"] = 16] = "ViBOX";
    SetupPresetGroupType[SetupPresetGroupType["FireDiBOX"] = 17] = "FireDiBOX";
    SetupPresetGroupType[SetupPresetGroupType["GrETag"] = 18] = "GrETag";
    SetupPresetGroupType[SetupPresetGroupType["TableColorUVFree"] = 19] = "TableColorUVFree";
    SetupPresetGroupType[SetupPresetGroupType["PavColorUVFree"] = 20] = "PavColorUVFree";
    SetupPresetGroupType[SetupPresetGroupType["CustomLightings"] = 22] = "CustomLightings";
})(SetupPresetGroupType || (SetupPresetGroupType = {}));
export const SETUP_PRESET_GROUP_ASET = SetupPresetGroupType.Aset;
export const SETUP_PRESET_GROUP_MACBETH = SetupPresetGroupType.Macbeth;
export const SETUP_PRESET_GROUP_SWINGING = SetupPresetGroupType.Swinging;
export const SETUP_PRESET_GROUP_FULL_ROTATION = SetupPresetGroupType.FullRotation;
export const SETUP_PRESET_GROUP_VIBOX = SetupPresetGroupType.ViBOX;
export const SETUP_PRESET_GROUP_CUSTOM_LIGHTINGS = SetupPresetGroupType.CustomLightings;
export const SETUP_PRESET_GROUP_OFFICE = SetupPresetGroupType.Office;
export const SETUP_PRESET_GROUP_FIRE_DIBOX = SetupPresetGroupType.FireDiBOX;
export const SETUP_PRESET_GROUP_DARKFIELD = SetupPresetGroupType.Darkfield;
export const SETUP_PRESET_GROUP_TABLE_COLOR_LAB = SetupPresetGroupType.TableColorLab;
export const SETUP_PRESET_GROUP_PAV_COLOR_LAB = SetupPresetGroupType.PavColorLab;
export const SETUP_PRESET_GROUP_TABLE_COLOR_UVFREE = SetupPresetGroupType.TableColorUVFree;
export const SETUP_PRESET_GROUP_PAV_COLOR_UVFREE = SetupPresetGroupType.PavColorUVFree;
export const SETUP_PRESET_GROUP_FLUOR_TABLE = SetupPresetGroupType.FlourTable;
export const SETUP_PRESET_GROUP_FLUOR_SIDE = SetupPresetGroupType.FlourSide;
export const SETUP_PRESET_GROUP_GRETAG = SetupPresetGroupType.GrETag;
export const IGNORED_FOR_REFERENCE_COMPARISON = [
    SetupPresetGroupType.FullRotation,
    SetupPresetGroupType.ViBOX,
    SetupPresetGroupType.Darkfield,
    SetupPresetGroupType.TableColorLab,
    SetupPresetGroupType.PavColorLab,
    SetupPresetGroupType.FlourTable,
    SetupPresetGroupType.FlourSide,
];
export const SPG_ORDER_SOLUTION_LIST_DZ = [
    SetupPresetGroupType.Office,
    SetupPresetGroupType.TableColorUVFree,
    SetupPresetGroupType.PavColorUVFree,
    SetupPresetGroupType.Aset,
];
export const SPG_ORDER_SOLUTION_LIST_FANCY_COLOR = [
    SetupPresetGroupType.Office,
    SetupPresetGroupType.TableColorUVFree,
    SetupPresetGroupType.PavColorUVFree,
    SetupPresetGroupType.Aset,
];
export class SetupPresetGroupModel {
    id;
    title;
    // List of related SetupPresets
    sp;
    // Indicates whether the SetupPresetGroup should be displayed with exact context
    // (for example, different combo tables could have different fields. So we have to hide certain SetupPresetGroups)
    scope;
    // Indicates whether the Power component is present.
    position;
}
class SetupPresetGroupConstructor extends SetupPresetGroupModel {
    // @ts-ignore
    sp;
}
/**
 * Entity for composition of different setup presets.
 *
 * For example, we merge Fire and Fire Live Photo in one SetupPresetGroup.
 *
 * If product doesn't have Fire video then Fire Live Photo will be displayed
 */
export default class SetupPresetGroup extends SetupPresetGroupConstructor {
    sp = [];
    constructor(modelData) {
        super();
        this.id = modelData.id;
        this.position = modelData.position;
        this.scope = modelData.scope;
        this.title = modelData.title;
        this.sp = modelData.sp;
    }
}
