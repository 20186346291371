// extracted by mini-css-extract-plugin
var _1 = "Q3801htlaXLFMEFJugOI";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#4A90E2";
var _c = "#ffffff";
var _d = "GBdS3oFjA_JXmAobc1Cp";
var _e = "vaj8GMEs7JdTBEdRWjLi";
var _f = "hMf9RIbXonqcMjja3BcH";
var _10 = "XJ_GNvHLaNz6ZLPHKq4f";
var _11 = "v2ERFUQugiDSuX3jtnH1";
var _12 = "Jdc0p1QameeiCs_ofBEs";
var _13 = "Qjbw_ocHQ0CtDCDXukSj";
var _14 = "wlqQCNVNd4hHRJkE0KfQ";
var _15 = "ZvehZ9ZazAIZHPBbz0KR";
var _16 = "GQbylf9Q76cG0V9_inKv";
var _17 = "f0vCoQywbi8BgACXHMbO";
var _18 = "1200px";
var _19 = "992px";
var _1a = "1366px";
var _1b = "768px";
var _1c = "1475px";
var _1d = "576px";
var _1e = "1600px";
var _1f = "320px";
var _20 = "2500px";
var _21 = "_v8J3ETtmtSbh7AZiB_2";
export { _1 as "bounce", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorRegularLinks", _c as "colorWhite", _d as "loader", _e as "loaderBlack", _f as "loaderColored", _10 as "loaderLarge", _11 as "loaderMedium", _12 as "loaderSmall", _13 as "loaderWhite", _14 as "loaderXSmall", _15 as "product-block-grid", _16 as "product-block-subgrid", _17 as "reset-button", _18 as "screenlg", _19 as "screenmd", _1a as "screenml", _1b as "screensm", _1c as "screenxlg", _1d as "screenxs", _1e as "screenxxlg", _1f as "screenxxs", _20 as "screenxxxlg", _21 as "single-page-responsive-font-size" }
export default { "bounce": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorRegularLinks": _b, "colorWhite": _c, "loader": _d, "loaderBlack": _e, "loaderColored": _f, "loaderLarge": _10, "loaderMedium": _11, "loaderSmall": _12, "loaderWhite": _13, "loaderXSmall": _14, "product-block-grid": _15, "product-block-subgrid": _16, "reset-button": _17, "screenlg": _18, "screenmd": _19, "screenml": _1a, "screensm": _1b, "screenxlg": _1c, "screenxs": _1d, "screenxxlg": _1e, "screenxxs": _1f, "screenxxxlg": _20, "single-page-responsive-font-size": _21 }
