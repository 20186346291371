import CutwiseAPIClient, {METHOD_DELETE} from '../../common/network/CutwiseAPIClient';

export const PATH_MEDIASOURCES = 'api/v2/mediasources';

export default class MediaSourcesAPI {
  /**
   * @type {string} mediaSourceId
   * @returns {Promise<QueryResult>}
   */
  remove(mediaSourceId) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_DELETE)
      .path(`${PATH_MEDIASOURCES}/${mediaSourceId}`);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }
}
