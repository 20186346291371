export default class IntegralMetric {
  /**
   * @type {string[]}
    */
  ag;

  /**
   * @type {number}
   */
  d;

  /**
   * @type {string}
   */
  hr;

  /**
   * @type {number}
   */
  rg;

  /**
   * @type {string}
   */
  val;

  /**
   * @type {string}
   */
  ver;

  /**
   * Contains calculated value for radar,
   * value in range in between 0 and 1
   *
   * @type {number}
   */
  gmc;

  /**
   * Contains calculated value for radar of shape normalized metric,
   * value in range in between 0 and 1
   *
   * @type {number}
   */
  smc;

  /**
   * @param {string[]} ag
   * @param {number} d
   * @param {string} hr
   * @param {number} rg
   * @param {number} val
   * @param {string} ver
   * @param {number} gmc
   * @param {number} smc
   */
  constructor({
    ag, d, hr, rg, val, ver, gmc, smc,
  }) {
    this.ag = ag;
    this.d = d;
    this.hr = hr;
    this.rg = rg;
    this.val = val.toFixed(2);
    this.ver = ver;
    this.gmc = gmc;
    this.smc = smc;
  }
}
