import CustomGradingDictEntry from '../../../entity/CustomGradingDictEntry';

export default [
  new CustomGradingDictEntry({
    id: 1, title: 'P-', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 2, title: 'P', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 3, title: 'P+', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 4, title: 'F-', refId: 54979,
  }),
  new CustomGradingDictEntry({
    id: 5, title: 'F', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 6, title: 'F+', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 7, title: 'G-', refId: 54975,
  }),
  new CustomGradingDictEntry({
    id: 8, title: 'G', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 9, title: 'G+', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 10, title: 'VG-', refId: 54974,
  }),
  new CustomGradingDictEntry({
    id: 11, title: 'VG', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 12, title: 'VG+', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 13, title: 'EX-', refId: 54978,
  }),
  new CustomGradingDictEntry({
    id: 14, title: 'EX', refId: null,
  }),
  new CustomGradingDictEntry({
    id: 15, title: 'EX+', refId: null,
  }),
];
