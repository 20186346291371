import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './ShapeWithPatents.less';
import Diamond from '../../entity/Diamond';
import Popover from '../../../common/components/Popover/Popover';
import PatentList from './PatentList/PatentList';

export default class ShapeWithPatents extends Component {
  static propTypes = {
    cutShape: PropTypes.string.isRequired,
    product: PropTypes.instanceOf(Diamond).isRequired,
  };

  state = {
    isPopoverOpened: false,
  };

  openPopover = () => {
    this.setState({
      isPopoverOpened: true,
    });
  };

  closePopover = () => {
    this.setState({
      isPopoverOpened: false,
    });
  };

  render() {
    if (!this.props.product.cutShapePatents || !this.props.product.cutShapePatents.length) {
      return this.props.cutShape;
    }

    return (
      <Popover
        isOpen={this.state.isPopoverOpened}
        preferPlace="below"
        onOuterAction={this.closePopover}
        body={<PatentList cutShapePatents={this.props.product.cutShapePatents} />}
        tipSize={0}
        className="Popover--with-padding"
      >
        <span className={styles.shapeWithPatents} onClick={this.openPopover}>
          {this.props.cutShape}
        </span>
      </Popover>
    );
  }
}
