import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import ProductInfo from '../../components/CatalogContent/ProductListContainer/ProductListCombo/ProductInfo/ProductInfo';
export const FIELD_SHORT_VIEW = {
    label: 'Short View',
    fullLabel: 'Short View',
    key: 'shortView',
    type: FieldType.Scalar,
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        return p.shortView;
    },
    decorator: (_, p) => _jsx(ProductInfo, { product: p, showB2BSid: true, showPrice: true, showTitle: true }),
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
