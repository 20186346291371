import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_TABLE_COMPOSED = {
    key: 'table',
    label: 'Table',
    fullLabel: 'Table',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            if (p.reportData?.tablePc !== null && p.reportData?.table !== null) {
                return `${p.reportData.tablePc}% (${p.reportData.table} mm)`;
            }
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.tablePc !== null ? p.reportData.tablePc : null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
