import ToFormDataSerializationStrategy from '@/common/network/serialization/ToFormDataSerializationStrategy';
import CutwiseAPIClient, { METHOD_DELETE, METHOD_GET, METHOD_POST } from '../../common/network/CutwiseAPIClient';
export const PATH_COLLECTIONS = 'api/contactdata/';
export const PATH_COLLECTIONS_LIST = 'api/contactdata/list';
export const PATH_COLLECTIONS_LINK = 'api/contactdata/link';
export default class ContactsAPI {
    getUserContacts() {
        const query = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_COLLECTIONS_LIST).getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    create(data) {
        const query = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .path(PATH_COLLECTIONS)
            .body(data)
            .serializer(ToFormDataSerializationStrategy)
            .getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    edit(data) {
        const { id, ...body } = data;
        const query = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .path(`${PATH_COLLECTIONS}${id}`)
            .body(body)
            .serializer(ToFormDataSerializationStrategy)
            .getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    remove(id) {
        const query = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_DELETE)
            .path(`${PATH_COLLECTIONS}${id}`)
            .getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    linkCollection(collectionId, contactId) {
        const body = {
            contactDataId: contactId,
            collection: collectionId,
        };
        const query = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_POST)
            .path(PATH_COLLECTIONS_LINK)
            .body(body)
            .serializer(ToFormDataSerializationStrategy)
            .getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    unlink(linkId) {
        const query = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_DELETE)
            .path(`${PATH_COLLECTIONS_LINK}/${linkId}`)
            .getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
