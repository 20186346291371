import { pureStructure } from '@cutwise/utils';
import { EMPTY_VALUE_DASH } from '@/common/constants/app';
import { formatDate } from '@/common/helpers/formatDate';
import { OrderStatus } from '../constants/status';
export class ConversionOrderModel {
    status;
    links;
    name;
    uploadDate;
    expiresIn;
    weight;
    sum;
}
export class ConversionOrder extends ConversionOrderModel {
    constructor(model) {
        super();
        Object.keys(model).forEach((key) => {
            this[key] = model[key];
        });
    }
    get date() {
        return this.uploadDate ? formatDate.toFullHumanDateTime(this.uploadDate) : EMPTY_VALUE_DASH;
    }
    get weightValue() {
        return this.weight ? `${this.weight}ct` : EMPTY_VALUE_DASH;
    }
    get appearance() {
        switch (this.status) {
            case OrderStatus.Expired:
            case OrderStatus.Failed:
                return 'tertiary';
            case OrderStatus.Processing:
                return 'neutral';
            case OrderStatus.ReadyForPayment:
                return 'secondary';
            default:
                return 'accent';
        }
    }
    get receiptLink() {
        return this.links?.find(({ action }) => !action);
    }
    get actionLink() {
        return this.links?.find(({ action }) => action);
    }
    toJS() {
        return pureStructure({ ...this });
    }
}
