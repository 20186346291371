import { configurationStore } from '@/common/stores/ConfigurationStore';
export function serializeDimensions(dimensions) {
    const dimensionGA = {
        dimension9: window.location.pathname,
    };
    if (configurationStore.isNetbox) {
        dimensionGA.dimension10 = true;
    }
    if (!dimensions) {
        return dimensionGA;
    }
    const { b2bId, id, b2bSid, collectionId, ...rest } = dimensions;
    if (b2bId) {
        dimensionGA.dimension1 = b2bId.toString();
    }
    if (id) {
        dimensionGA.dimension2 = id.toString();
    }
    if (b2bSid) {
        dimensionGA.dimension7 = b2bSid.toString();
    }
    if (collectionId) {
        dimensionGA.dimension8 = collectionId.toString();
    }
    return {
        ...dimensionGA,
        ...rest,
    };
}
