import {dictionaryRepository} from '../../common/repositories/DictionaryRepository';
import HpoColorPart from '../models/HpoColorPart';
import HpoColor from '../models/HpoColor';

/**
 * @param {string} value
 * @return {?HpoColor}
 */

export default (value) => {
  if (!value) {
    return null;
  }

  const items = value.split('/');
  if (!items || items.length === 0) {
    return null;
  }

  const parts = items
    .map((p) => {
      const percentMatch = p.match(/(\d+)/);

      if (!percentMatch || percentMatch.length === 0) {
        return null;
      }

      const percent = percentMatch[0];
      if (!percent) {
        return null;
      }

      const colorTitle = p.replace(percent, '').split('').join('-');
      if (!colorTitle) {
        return null;
      }

      const dictGrade = dictionaryRepository.getColorByTitle(colorTitle);
      if (!dictGrade) {
        return null;
      }

      return new HpoColorPart({ dictGrade, percent: Number(percent) });
    })
    .filter(Boolean)
    .sort((a, b) => {
      return a.getIndex() - b.getIndex();
    });

  if (!parts || parts.length === 0) {
    return null;
  }

  if (items.length !== parts.length) {
    return null;
  }

  return (new HpoColor())
    .setParts(parts);
};
