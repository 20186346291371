export default class ToJSONSerializationStrategy {
  /**
   * @param {Object} data
   */
  static serialize(data) {
    if (!data) {
      return null;
    }

    return JSON.stringify(data);
  }

  /**
   * @return {string}
   */
  static contentType() {
    return 'application/json; charset=UTF-8';
  }
}
