import { HOME_PAGE } from '@/common/constants/pages';
import locationReload from '@/common/helpers/locationReload';
import { NETBOX_KEY } from '@/common/network/CutwiseAPIClient';
import Notifier from '@/common/services/Notifier';
import Storage, { KEY_ACCESS_TOKEN, KEY_ACCESS_TOKEN_LEGACY, KEY_AUTH_TOKEN, KEY_CONSTANTS, KEY_TOKEN, KEY_URI_AFTER_AUTH, KEY_USER, KEY_WAS_LOGGED_OUT, } from '@/common/services/Storage';
import config from '@/config';
import Token from '../token';
export default class Auth {
    static clearAuthData() {
        Storage.removeObject(KEY_AUTH_TOKEN, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_USER, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_CONSTANTS, Storage.STORAGE_LOCAL);
        // remove deprecated keys values, in cases when we update app version, but some users still have
        Storage.removeObject(KEY_TOKEN, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_ACCESS_TOKEN, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_ACCESS_TOKEN_LEGACY, Storage.STORAGE_LOCAL);
    }
    static redirectToLoginPage() {
        Storage.saveObject(KEY_URI_AFTER_AUTH, window.location.href, Storage.STORAGE_SESSION);
        Storage.removeObject(KEY_AUTH_TOKEN, Storage.STORAGE_LOCAL);
        window.location.href = Storage.getObject(NETBOX_KEY, Storage.STORAGE_LOCAL)
            ? Auth.generateNextboxAuthUrl()
            : Auth.generateAuthUrl();
    }
    // logout and redirects to main listing page
    static logout() {
        Auth.clearAuthData();
        window.location.href = `${window.location.origin}${HOME_PAGE}`;
    }
    // removes invalid token and redirects to backend login page
    static logoutAfterSessionExpiration() {
        if (!Token.isAuthorized) {
            return;
        }
        Storage.saveObject(KEY_WAS_LOGGED_OUT, true, Storage.STORAGE_SESSION);
        Auth.clearAuthData();
        locationReload();
    }
    // generates auth server url
    static generateAuthUrl() {
        return `${config.AUTH_URI}/api/oauth/v2/auth?client_id=${config.CLIENT_ID}&grant_type=token&redirect_uri=${config.REDIRECT_URI}&response_type=token`;
    }
    // generates logout server url
    static generateLogoutUrl() {
        const path = Storage.getObject(NETBOX_KEY, Storage.STORAGE_LOCAL) ? config.NETBOX_AUTH_URI : config.AUTH_URI;
        return `${path}/logout`;
    }
    // generates auth server url
    static generateNextboxAuthUrl() {
        return `${config.NETBOX_AUTH_URI}/api/oauth/v2/auth?client_id=${config.NETBOX_CLIENT_ID}&grant_type=token&redirect_uri=${config.NETBOX_REDIRECT_URI}&response_type=token`;
    }
    // this function should be called after refreshing of page
    static showNoticeAboutSessionExpirationIfNeeded() {
        const wasLoggedOut = Storage.getObject(KEY_WAS_LOGGED_OUT, Storage.STORAGE_SESSION);
        if (wasLoggedOut) {
            Storage.removeObject(KEY_WAS_LOGGED_OUT, Storage.STORAGE_SESSION);
            Notifier.warn('You were logged out due to session expiration.', { delay: 10, toastId: null });
        }
    }
}
