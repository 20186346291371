/**
 * Removes spaces around B2BSid search string
 * @param {Query} query
 * @return {Query}
 */
export default (query) => {
  const filters = query.queryStringParams.filters;

  if (filters.b2bSid) {
    filters.b2bSid = filters.b2bSid.trim();
  }

  return filters;
};
