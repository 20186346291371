import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { Provider } from 'mobx-react';
import { PrivateAccessStore } from '../../stores/PrivateAccessStore';
import PrivateAccessModalContent from './PrivateAccessModalContent/PrivateAccessModalContent';
function PrivateAccessModal(props) {
    const { entities, type, isOpen, ...rest } = props;
    const [privateAccessStore, setPrivateAccessStore] = useState(null);
    useEffect(() => {
        setPrivateAccessStore(isOpen ? new PrivateAccessStore(entities, type) : null);
    }, [isOpen]);
    if (!privateAccessStore) {
        return null;
    }
    return (_jsx(Provider, { privateAccessStore: privateAccessStore, children: _jsx(PrivateAccessModalContent, { isOpen: isOpen, ...rest }) }));
}
export default memo(PrivateAccessModal);
