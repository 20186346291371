import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import ShapeWithPatents from '@/product/components/ShapeWithPatents/ShapeWithPatents';
import Diamond from '@/product/entity/Diamond';
export const FIELD_SHAPE = {
    label: 'Shape',
    fullLabel: 'Cut Shape',
    key: 'cutShape',
    type: FieldType.Scalar,
    icon: 'metrics-shape',
    getter(p) {
        if (p instanceof Diamond) {
            return p.cutShapeTitle;
        }
        return null;
    },
    getterPlain(p) {
        return p.cutShape || null;
    },
    decorator: (v, p) => (v ? _jsx(ShapeWithPatents, { cutShape: v, product: p }) : null),
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
