import { observable, action, toJS } from 'mobx';
import CutshapePatent from './CutshapePatent';

export const SHAPE_ROUND = 2;
export const SHAPE_PRINCESS = 4;
export const SHAPE_CUSHION = 9;
export const SHAPE_OVAL = 11;
export const SHAPE_MARQUISE = 80;
export const SHAPE_PEAR = 22;
export const SHAPE_RADIANT = 83;
export const SHAPE_EMERALD = 90;
export const SHAPE_HEART = 85;
export const SHAPE_OTHER = 113;
export const SHAPE_CUBOID = 'cuboid'; // we don't have such cut shape in system

export const MAIN_SHAPES_IDS = [
  SHAPE_ROUND,
  SHAPE_PRINCESS,
  SHAPE_CUSHION,
  SHAPE_OVAL,
  SHAPE_MARQUISE,
  SHAPE_PEAR,
  SHAPE_RADIANT,
  SHAPE_EMERALD,
  SHAPE_HEART,
  SHAPE_OTHER,
];

export default class Cutshape {
  /**
   * @type {number} id
   */
  id;

  /**
   * @type {string} title
   */
  @observable title = '';

  /**
   * @type {?Cutshape} parent
   */
  @observable parent;

  /**
   * @type {CutshapePatent[]} cutShapePatents
   */
  @observable cutShapePatents = [new CutshapePatent()];

  /**
   * @type {boolean} isPatented
   */
  @observable isPatented = false;

  constructor(modelData) {
    Object.assign(this, modelData);
  }

  @action
  addEmptyPatent = () => {
    this.cutShapePatents.push(new CutshapePatent());
  }

  /**
   * @param {number} index
   */
  @action
  removePatentByIndex(index) {
    this.cutShapePatents.splice(index, 1);
  }

  toDto() {
    return toJS({
      ...this,
      parent: this.parent.id,
    });
  }
}
