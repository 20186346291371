import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_CULET = {
    key: 'culetSizeGrade',
    label: 'Culet',
    fullLabel: 'Culet Grade',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-culet',
    getter(p, short = false) {
        if (p instanceof Diamond && p.culetSizeGrade) {
            return short ? p.culetSizeGrade.short : p.culetSizeGrade.title;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond) {
            return p.culetSizeGrade;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
