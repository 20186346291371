import Storage, { KEY_AUTH_TOKEN, KEY_USER } from '@/common/services/Storage';
export default class Token {
    static get accessToken() {
        return Storage.getObject(KEY_AUTH_TOKEN, Storage.STORAGE_LOCAL) || null;
    }
    static get userId() {
        const user = Storage.getObject(KEY_USER, Storage.STORAGE_LOCAL);
        return user?.id || null;
    }
    static get b2bId() {
        const user = Storage.getObject(KEY_USER, Storage.STORAGE_LOCAL);
        return user?.b2b?.id || null;
    }
    static get isAuthorized() {
        return Boolean(Token.userId);
    }
}
