import {observer} from 'mobx-react';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styles from './ProductLabels.less';
import Product from '../../entity/Product';
import Diamond from '../../entity/Diamond';
import {securityManager, VIEW_PRODUCT_STATUS} from '../../../common/services/SecurityManager';
import IsLGDLabel from './IsLGDLabel/IsLGDLabel';
import IsPhotorealLabel from './isPhotorealLabel/isPhotorealLabel';
import Rough from '../../entity/Rough';
import AccessStatus from '@/security/components/AccessStatus/AccessStatus';
import ACLStatus from '@/security/components/ACLStatus/ACLStatus';
import {SIZE_MEDIUM} from '@/common/components/Label/Label';

@observer
export default class ProductLabels extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    centered: PropTypes.bool,
    showAccess: PropTypes.bool,
    isCreatable: PropTypes.bool,
  };

  static defaultProps = {
    centered: false,
    showAccess: false,
    isCreatable: false,
  };

  render() {
    const product = this.props.product;
    const isProductValid = product instanceof Diamond || product instanceof Rough;
    if (!isProductValid) {
      return null;
    }

    let showProductLabels = false;
    if (product instanceof Rough) {
      showProductLabels = product.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: product});
    }

    if (product instanceof Diamond) {
      showProductLabels = (product.isLabGrown || product.isPhotoreal) || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: product});
    }

    if (!showProductLabels) {
      return null;
    }

    return (
      <div className={`${styles.productLabels} ${this.props.centered ? styles.productLabelsCentered : ''}`} aria-label="Product Labels">
        {this.props.showAccess && securityManager.isGranted(VIEW_PRODUCT_STATUS, {product}) && (
          <Fragment>
            <AccessStatus size={SIZE_MEDIUM} product={product} isCreatable={this.props.isCreatable} />

            <ACLStatus size="small" product={product} short isCreatable={this.props.isCreatable} />
          </Fragment>
        )}

        <IsLGDLabel short product={product} />
        <IsPhotorealLabel product={product} />
      </div>
    );
  }
}
