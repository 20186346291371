import isEqual from 'lodash/isEqual';

export default function (o1, o2, omitUndefined = false) {
  const d = {};

  const o1Keys = Object.keys(o1);

  o1Keys.forEach((k) => {
    const areValuesEqual = isEqual(o1[k], o2[k]);

    if (!areValuesEqual && (!omitUndefined || (o1[k] !== undefined && o2[k] !== undefined))) {
      d[k] = o1[k];
    }
  });

  return d;
}
