import { jsx as _jsx } from "react/jsx-runtime";
import { inject } from 'mobx-react';
import { RATIO_THICK } from '../../constants/ratio';
import MetricBar from '../MetricBar/MetricBar';
function FluMetricBar(props) {
    const { metric, children, ratio = RATIO_THICK, scaleBaseUri } = props;
    function renderMetricBar() {
        const params = new URLSearchParams({
            ratio: `${ratio}`,
        });
        if (metric) {
            const metrics = {
                vs: metric.vs / 100,
                s: metric.s / 100,
                m: metric.m / 100,
                f: metric.f / 100,
                n: metric.n / 100,
            };
            Object.keys(metrics).forEach((key) => {
                if (metrics[key]) {
                    params.append(key, metrics[key]);
                }
            });
        }
        return _jsx(MetricBar, { ratio: ratio, svg: `${scaleBaseUri}/fluor/fluor?${params.toString()}` });
    }
    return children ? children(renderMetricBar(), metric ? metric.compositeVal : null) : renderMetricBar();
}
export default inject(({ appStore }) => ({
    scaleBaseUri: appStore.configurationStore.scaleBaseUri,
}))(FluMetricBar);
