import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import cls from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { shortyString } from '@/common/helpers/shortyString';
import styles from './Avatar.module.css';
function Avatar(props) {
    const { username, user, withUsername, size = 'medium', className, color, title = '', online = false } = props;
    const id = useMemo(() => uuidv4(), []);
    const company = user?.b2bTitle;
    const email = user?.email;
    const name = username || user?.username;
    if (!name) {
        return null;
    }
    const avatarImage = (_jsxs("div", { className: cls(styles.avatar, {
            [styles.avatarSmall]: size === 'small',
            [styles.avatarMedium]: size === 'medium',
            [styles.avatarLarge]: size === 'large',
        }, className), title: title, children: [_jsxs("svg", { height: "100", style: { fill: color }, viewBox: "0 0 100 100", width: "100", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("circle", { cx: "50", cy: "50", mask: `url(#right-cut-${id})`, r: "50" }), _jsxs("mask", { id: `right-cut-${id}`, children: [_jsx("rect", { fill: "white", height: "100", width: "100", x: "0", y: "0" }), _jsx("circle", { cx: "-20", cy: "50", fill: "black", r: "50" })] })] }), _jsx("span", { className: styles.avatarFirstLetter, children: name.charAt(0).toUpperCase() }), online && _jsx("div", { className: styles.avatarOnlineBadge })] }));
    if (!withUsername) {
        return avatarImage;
    }
    return (_jsxs("div", { className: cls(styles.avatarContainer, className), children: [_jsx("div", { className: styles.avatarImageContainer, children: avatarImage }), _jsxs("div", { className: styles.avatarName, children: [_jsx("div", { className: styles.companyName, children: company ? shortyString(company, 30) : user?.getFullName }), _jsx("div", { className: styles.userName, children: email || name })] })] }));
}
export default Avatar;
