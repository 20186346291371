import React, { Component } from 'react';
import PropTypes from 'prop-types';
import confirmable from 'react-confirm/lib/confirmable';
import Button from '../Button/Button';
import Modal, {CENTER} from '../Modal/Modal';
import ButtonContainer from '../ButtonContainer/ButtonContainer';

class Confirmation extends Component {
  static propTypes = {
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.node.isRequired,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    cancel: PropTypes.func, // called when cancel button is clicked.
    dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
    okColor: PropTypes.string,
    cancelColor: PropTypes.string,
    showCancelButton: PropTypes.bool,
    showOkButton: PropTypes.bool,
  };

  static defaultProps = {
    okLabel: 'OK',
    cancelLabel: 'Cancel',
    title: null,
    show: null,
    proceed: null,
    cancel: null,
    dismiss: null,
    okColor: 'default',
    cancelColor: 'default',
    showCancelButton: true,
    showOkButton: true,
  };

  render() {
    const {
      okLabel, okColor, cancelLabel, cancelColor, title, confirmation, show, proceed, dismiss, cancel, showCancelButton, showOkButton,
    } = this.props;

    return (
      <Modal
        isShowed={show}
        onClose={dismiss}
        title={title}
        position={CENTER}
        renderModalFooter={() => (
          <ButtonContainer>
            {showCancelButton && (
              <Button color={cancelColor} onClick={cancel}>
                {cancelLabel}
              </Button>
            )}
            {showOkButton && (
              <Button color={okColor} onClick={proceed}>
                {okLabel}
              </Button>
            )}
          </ButtonContainer>
        )}
      >
        {confirmation}
      </Modal>
    );
  }
}

export default confirmable(Confirmation);
