import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_PAVILION_ANGLE = {
    key: 'pavilionAngleDeg',
    label: 'Pav. Angle',
    fullLabel: 'Pavilion Angle',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.pavAn !== null ? `${p.reportData.pavAn}°` : null;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.pavAn !== null ? p.reportData.pavAn : null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
