import { jsx as _jsx } from "react/jsx-runtime";
import { inject } from 'mobx-react';
import SpreadFormatter from '../../../common/helpers/SpreadFormatter';
import { METRIC_DATA_DECORATOR_DEFAULT, METRIC_DATA_DECORATOR_FANCY } from '../../constants/dataDecorators';
import { RATIO_THICK } from '../../constants/ratio';
import MetricBar from '../MetricBar/MetricBar';
function SpreadMetricBar(props) {
    const { metric, children, ratio = RATIO_THICK, scaleBaseUri, cutShapeId, metricDataDecorator = METRIC_DATA_DECORATOR_DEFAULT, } = props;
    function renderMetricBar() {
        const params = new URLSearchParams({
            ratio: `${ratio}`,
        });
        if (metric) {
            params.append('pc', `${metric.pc}`);
            params.append('d', `${metric.d}`);
            params.append('ct', `${metric.ct}`);
            if (cutShapeId && metricDataDecorator === METRIC_DATA_DECORATOR_FANCY) {
                params.append('sh', `${cutShapeId}`);
            }
        }
        return _jsx(MetricBar, { ratio: ratio, svg: `${scaleBaseUri}/spread/spread?${params.toString()}` });
    }
    const abs = metric?.ct !== undefined ? SpreadFormatter.format(metric.ct, 2, 'ct') : null;
    return children ? children(renderMetricBar(), abs) : renderMetricBar();
}
export default inject(({ appStore }) => ({
    scaleBaseUri: appStore.configurationStore.scaleBaseUri,
}))(SpreadMetricBar);
