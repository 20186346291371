import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import HighlightedHPOColor from '@/metric/components/HighlightedHPOColor/HighlightedHPOColor';
import Diamond from '@/product/entity/Diamond';
export const FIELD_PAVILION_COLOR = {
    key: 'dzPavColor',
    label: 'Pavilion Color',
    fullLabel: 'Pavilion Color',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-color',
    getter(p) {
        if (p instanceof Diamond && p.reportData?.dzPavColor) {
            return _jsx(HighlightedHPOColor, { hpoColor: p.reportData.dzPavColor });
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.dzPavColor;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
