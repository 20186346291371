export var FieldType;
(function (FieldType) {
    FieldType["Metric"] = "metric";
    FieldType["Scalar"] = "scalar";
    FieldType["Media"] = "media";
    FieldType["HtmlMarkup"] = "HtmlMarkup";
    FieldType["Custom"] = "custom";
})(FieldType || (FieldType = {}));
export const TYPE_METRIC = FieldType.Metric;
export const TYPE_SCALAR = FieldType.Scalar;
export const TYPE_MEDIA = FieldType.Media;
export const TYPE_CUSTOM = FieldType.Custom;
export const TYPE_HTML_MARKUP = FieldType.HtmlMarkup;
