// extracted by mini-css-extract-plugin
var _1 = "#76F366";
var _2 = "#EB8632";
var _3 = "#ECC834";
var _4 = "#21FC87";
var _5 = "#EA4A30";
var _6 = "#C7EA45";
var _7 = "#272B2B";
var _8 = "#BDBDBD";
var _9 = "#7D7D7D";
var _a = "#4A90E2";
var _b = "#ffffff";
var _c = "personalAccessAdd";
var _d = "personalAccessContent";
var _e = "personalAccessFooter";
var _f = "personalAccessForm";
var _10 = "personalAccessHeading";
var _11 = "personalAccessLeft";
var _12 = "personalAccessModal";
var _13 = "personalAccessRight";
var _14 = "personalAccessRightContent";
var _15 = "personalAccessRightEmpty";
var _16 = "personalAccessRightLoader";
var _17 = "product-block-grid";
var _18 = "product-block-subgrid";
var _19 = "recursiveCheckbox";
var _1a = "recursiveCheckboxLabel";
var _1b = "recursiveDescriptionIcon";
var _1c = "recursiveDescriptionInput";
var _1d = "recursiveDescriptionInputHidden";
var _1e = "recursiveDescriptionSecurityIcon";
var _1f = "recursiveLabel";
var _20 = "reset-button";
var _21 = "1200px";
var _22 = "992px";
var _23 = "1366px";
var _24 = "768px";
var _25 = "1475px";
var _26 = "576px";
var _27 = "1600px";
var _28 = "320px";
var _29 = "2500px";
var _2a = "single-page-responsive-font-size";
var _2b = "userOption";
var _2c = "userOptionCompany";
var _2d = "userOptionDescription";
var _2e = "userOptionName";
var _2f = "userSelectedOption";
var _30 = "userSelectedOptionName";
export { _1 as "colorGradeEX", _2 as "colorGradeFR", _3 as "colorGradeGD", _4 as "colorGradeOU", _5 as "colorGradePR", _6 as "colorGradeVG", _7 as "colorNeutral", _8 as "colorNeutralLighest", _9 as "colorNeutralLight", _a as "colorRegularLinks", _b as "colorWhite", _c as "personalAccessAdd", _d as "personalAccessContent", _e as "personalAccessFooter", _f as "personalAccessForm", _10 as "personalAccessHeading", _11 as "personalAccessLeft", _12 as "personalAccessModal", _13 as "personalAccessRight", _14 as "personalAccessRightContent", _15 as "personalAccessRightEmpty", _16 as "personalAccessRightLoader", _17 as "product-block-grid", _18 as "product-block-subgrid", _19 as "recursiveCheckbox", _1a as "recursiveCheckboxLabel", _1b as "recursiveDescriptionIcon", _1c as "recursiveDescriptionInput", _1d as "recursiveDescriptionInputHidden", _1e as "recursiveDescriptionSecurityIcon", _1f as "recursiveLabel", _20 as "reset-button", _21 as "screenlg", _22 as "screenmd", _23 as "screenml", _24 as "screensm", _25 as "screenxlg", _26 as "screenxs", _27 as "screenxxlg", _28 as "screenxxs", _29 as "screenxxxlg", _2a as "single-page-responsive-font-size", _2b as "userOption", _2c as "userOptionCompany", _2d as "userOptionDescription", _2e as "userOptionName", _2f as "userSelectedOption", _30 as "userSelectedOptionName" }
export default { "colorGradeEX": _1, "colorGradeFR": _2, "colorGradeGD": _3, "colorGradeOU": _4, "colorGradePR": _5, "colorGradeVG": _6, "colorNeutral": _7, "colorNeutralLighest": _8, "colorNeutralLight": _9, "colorRegularLinks": _a, "colorWhite": _b, "personalAccessAdd": _c, "personalAccessContent": _d, "personalAccessFooter": _e, "personalAccessForm": _f, "personalAccessHeading": _10, "personalAccessLeft": _11, "personalAccessModal": _12, "personalAccessRight": _13, "personalAccessRightContent": _14, "personalAccessRightEmpty": _15, "personalAccessRightLoader": _16, "product-block-grid": _17, "product-block-subgrid": _18, "recursiveCheckbox": _19, "recursiveCheckboxLabel": _1a, "recursiveDescriptionIcon": _1b, "recursiveDescriptionInput": _1c, "recursiveDescriptionInputHidden": _1d, "recursiveDescriptionSecurityIcon": _1e, "recursiveLabel": _1f, "reset-button": _20, "screenlg": _21, "screenmd": _22, "screenml": _23, "screensm": _24, "screenxlg": _25, "screenxs": _26, "screenxxlg": _27, "screenxxs": _28, "screenxxxlg": _29, "single-page-responsive-font-size": _2a, "userOption": _2b, "userOptionCompany": _2c, "userOptionDescription": _2d, "userOptionName": _2e, "userSelectedOption": _2f, "userSelectedOptionName": _30 }
