import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { GO_TO_COMPARE } from '@/analytics/services/Analytics';
import { useAnalyticsStore } from '@/analytics/store/AnalyticsStore';
import Icon from '@/common/components/Icon/Icon';
import Label, { ACCENT, SIZE_MEDIUM } from '@/common/components/Label/Label';
import Link, { NONE } from '@/common/components/Link/Link';
import URLProvider from '@/common/services/URLProvider';
import { useAppStore } from '@/common/stores/AppStore';
import { useComparisonStore } from '@/comparison/stores/ComparisonStore';
function ComparisonNavItem() {
    const { sendEventWithContext } = useAnalyticsStore();
    const { productTypeContext } = useAppStore();
    const { productIds, productsCount } = useComparisonStore();
    const handleCompareClick = useCallback(() => {
        sendEventWithContext(GO_TO_COMPARE);
    }, [sendEventWithContext]);
    const comparisonPageURL = URLProvider.comparisonPage(productIds, productTypeContext);
    const isComparisonPageAccessible = productTypeContext && comparisonPageURL;
    return (_jsxs(Link, { appearance: NONE, "aria-disabled": isComparisonPageAccessible ? 'false' : 'true', "aria-label": "Link to comparison page", className: "header__link header__link--icon", onClick: handleCompareClick, to: isComparisonPageAccessible ? comparisonPageURL : '', children: [_jsx(Icon, { name: "compare" }), productsCount > 0 && (_jsx(Label, { appearance: ACCENT, "aria-label": "Number of products in comparison", "aria-live": "assertive", rounded: true, size: SIZE_MEDIUM, children: productsCount }))] }, 1));
}
export default observer(ComparisonNavItem);
