import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Diamond from '@/product/entity/Diamond';
export const FIELD_PAVILION_FIRST_ANGLE = {
    key: 'pav1An',
    label: 'Pav. F. Angle',
    fullLabel: 'Pavilion First Angle',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.pav1An !== null ? `${p.reportData.pav1An}°` : null;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.pav1An !== null ? p.reportData.pav1An : null;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
