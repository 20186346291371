import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import HighlightedHPOColor from '@/metric/components/HighlightedHPOColor/HighlightedHPOColor';
import Diamond from '@/product/entity/Diamond';
export const FIELD_TABLE_COLOR = {
    key: 'dzTableColor',
    label: 'Table Color',
    fullLabel: 'Table Color',
    type: FieldType.Scalar,
    spGroupId: null,
    icon: 'metrics-color-table',
    getter(p) {
        if (p instanceof Diamond && p.reportData?.dzTableColor) {
            return _jsx(HighlightedHPOColor, { hpoColor: p.reportData.dzTableColor, hpoColorToCompare: p.reportData.dzPavColor });
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.reportData) {
            return p.reportData?.dzTableColor;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
