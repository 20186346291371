export var MediaSize;
(function (MediaSize) {
    MediaSize["XXSSmall"] = "xxs";
    MediaSize["XSmall"] = "xs";
    MediaSize["Small"] = "s";
    MediaSize["Medium"] = "m";
    MediaSize["Large"] = "l";
    MediaSize["XLarge"] = "xl";
    MediaSize["Full"] = "full";
})(MediaSize || (MediaSize = {}));
export const MEDIA_SIZE_XXSMALL = MediaSize.XXSSmall;
export const MEDIA_SIZE_XSMALL = MediaSize.XSmall;
export const MEDIA_SIZE_SMALL = MediaSize.Small;
export const MEDIA_SIZE_MEDIUM = MediaSize.Medium;
export const MEDIA_SIZE_LARGE = MediaSize.Large;
export const MEDIA_SIZE_XLARGE = MediaSize.XLarge;
export const MEDIA_SIZE_FULL = MediaSize.Full;
