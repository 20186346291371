import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './MetricMap.less';
import Diamond from '../../../product/entity/Diamond';
import FireMetric from '../../entity/FireMetric';
import ScintillationMetric from '../../entity/ScintillationMetric';
import {MEDIA_SIZE_LARGE} from '../../../media/constants/sizeCodes';
import {MEDIA_SIZE_MAP} from '../../../media/constants/sizeCodeToPx';
import {setupPresetRepository} from '../../../media/repository/SetupPresetRepository';

@observer
export default class MetricMap extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Diamond).isRequired,
    mediaSize: PropTypes.string,
    maxScale: PropTypes.number,
    isSingleScaleEnabled: PropTypes.bool,
    setupPresetId: PropTypes.number.isRequired,
    metric: PropTypes.oneOfType([
      PropTypes.instanceOf(FireMetric),
      PropTypes.instanceOf(ScintillationMetric),
    ]).isRequired,
  };

  static defaultProps = {
    mediaSize: MEDIA_SIZE_LARGE,
    maxScale: null,
    isSingleScaleEnabled: false,
  };

  @computed
  get size() {
    const product = this.props.product;
    let size = MEDIA_SIZE_MAP[this.props.mediaSize];

    if (this.props.isSingleScaleEnabled && this.props.maxScale) {
      const sp = setupPresetRepository.findSetupPresetById(this.props.setupPresetId);
      const media = product.mediaCollection ? product.mediaCollection.getMediaBySetupPreset(sp) : null;
      const mainMediaSource = media ? media.mainMediaSource : null;

      if (mainMediaSource && mainMediaSource.scaleRatio) {
        size *= mainMediaSource.getRelScale(mainMediaSource.getMaxSubFileByMediaSize(size)) / this.props.maxScale;
      }
    }

    return size;
  }

  render() {
    const metric = this.props.metric;

    if (!metric || !metric.map) {
      return null;
    }

    return (
      <div className={styles.metricMap} style={{'--metric-map-size': `${this.size}px`}}>
        <img src={metric.map} />
      </div>
    );
  }
}
