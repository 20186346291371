import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import ProductLink from '@/product/components/ProductLink/ProductLink';
export const FIELD_SKU = {
    label: 'SKU',
    fullLabel: 'Product SKU',
    key: 'sku',
    type: FieldType.Scalar,
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        return p.b2bSid || null;
    },
    decorator: (_, p) => _jsx(ProductLink, { product: p, text: p.b2bSid }),
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
