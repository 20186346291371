import { computed, observable } from 'mobx';
import {customGradeAttributeStore} from '../stores/CustomGradeAttributeStore';

export default class CustomGradingResult {
  /**
   * @type {number}
   */
  @observable
  rawValue;

  /**
   * @type {string}
   */
  @observable
  customGradingCode;

  /**
   * @param {number} value
   * @param {string} customGradingCode
   */
  constructor({value, customGradingCode}) {
    this.rawValue = value;
    this.customGradingCode = customGradingCode;
  }

  /**
   * @return {?CustomGradingDictEntry}
   */
  @computed
  get value() {
    if (!this.customGradeAttribute || !this.customGradeAttribute.dict) {
      return null;
    }

    return this.customGradeAttribute.dict.find(d => d.id === this.rawValue);
  }

  /**
   * @return {?CustomGradeAttribute}
   */
  @computed
  get customGradeAttribute() {
    const customGradeAttribute = customGradeAttributeStore.attributes[this.customGradingCode];

    if (!customGradeAttribute) {
      customGradeAttributeStore.fetchItem(this.customGradingCode);
      return null;
    }

    return customGradeAttribute;
  }
}
