class AsyncProductLoader {
  /**
   * @type {ProductRepository}
   */
  repository;

  /**
   * @param {Product} product
   * @return {Promise.<Product>}
   */
  queue(product) {
    if (product.isLoaded) {
      return Promise.resolve(product);
    }

    return this.repository.getFullData(product);
  }

  setRepository(repository) {
    this.repository = repository;
  }
}

const asyncProductLoader = new AsyncProductLoader();

export default asyncProductLoader;
