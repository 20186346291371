import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ExpensesProgress } from '@/common/components/ExpensesProgress/ExpensesProgress';
import { ALL_CONTEXTS } from '@/common/constants/context';
export const FIELD_EXPENSES = {
    label: 'Expenses',
    fullLabel: 'Expenses',
    key: 'expenses',
    type: FieldType.Custom,
    icon: '',
    getter(p, _, extra) {
        const { largestExpenseOnPage, onToggle } = extra;
        const productExpenses = p.expenses?.expenses || p.expenses?.expenses === 0 ? +p.expenses.expenses : undefined;
        let progressValue = 0;
        if (productExpenses) {
            // here we calculate progress length of product expenses in comparison with the largest product expenses on page
            progressValue = (productExpenses / largestExpenseOnPage) * 100;
        }
        return _jsx(ExpensesProgress, { amount: productExpenses, onExpand: onToggle, value: progressValue });
    },
    getterPlain() {
        return null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
