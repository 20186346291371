import {Component} from 'react';
import PropTypes from 'prop-types';
import AppError, {CODE_ACCESS_UNAUTHORIZED} from '../../state/AppError';

class ErrorChecker extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    this.checkError();
  }

  checkError = () => {
    const errorData = window.CUTWISE_FORCE_ERROR;
    window.CUTWISE_FORCE_ERROR = null;

    if (errorData && errorData.code !== CODE_ACCESS_UNAUTHORIZED) {
      throw AppError.createErrorFromBackend(errorData);
    }
  };

  render() {
    return this.props.children;
  }
}

export default ErrorChecker;
