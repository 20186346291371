import CutwiseAPIClient, {METHOD_GET, METHOD_PATCH} from '../../common/network/CutwiseAPIClient';

export const PATH_PROFILE = 'api/v2/user/info';
export const PATH_USER_SETTINGS = 'api/v2/user/settings';
export const PATH_USER_SEARCH = 'api/v2/user/search';
export const PATH_COMPANY_USERS = 'api/v2/user/listMy';

export default class UserAPI {
  /**
   * @return {Promise<QueryResult>}
   */
  fetchItem() {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_PROFILE);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @return {Promise<QueryResult>}
   */
  fetchCompanyUsers() {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_COMPANY_USERS);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {string} key
   * @return {Promise<QueryResult>}
   */
  fetchSettingsByKey(key) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_USER_SETTINGS)
      .addQueryStringParam('key', key);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {Object} data
   * @return {Promise<QueryResult>}
   */
  patchSettings(data) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_PATCH)
      .path(PATH_USER_SETTINGS)
      .body(data);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {string} email
   * @return {Promise<QueryResult>}
   */
  fetchUserByEmail(email) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_USER_SEARCH)
      .addQueryStringParam('q', email);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }
}
