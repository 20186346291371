import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './FormLabel.less';

export default class FormLabel extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    renderBeforeLabelText: PropTypes.func,
    isFilterInputMetaEmpty: PropTypes.bool,
    areFiltersInputMetaActive: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    className: '',
    renderBeforeLabelText: null,
    isFilterInputMetaEmpty: false,
    areFiltersInputMetaActive: false,
  };

  render() {
    const isFilterInputMetaEmptyClassName = this.props.isFilterInputMetaEmpty ? '' : styles.formLabelActiveFilter;
    const areFiltersInputMetaActiveClassName = this.props.areFiltersInputMetaActive ? styles.formLabelNonActiveFilter : '';
    const filterItemStyles = isFilterInputMetaEmptyClassName === '' ? areFiltersInputMetaActiveClassName : isFilterInputMetaEmptyClassName;

    return (
      <div className={`
          ${styles.formLabel}
          ${this.props.className} 
          ${filterItemStyles}
       `}
      >
        {this.props.renderBeforeLabelText ? this.props.renderBeforeLabelText() : null}
        {this.props.children}
      </div>
    );
  }
}
