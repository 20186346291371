var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import { isArray, logger, throttle } from '@cutwise/utils';
import useStore from '@/common/hooks/useStore';
import { EVENT_KEYBOARD, EVENT_LOOSE_FOCUS } from '@/stereo/constants/events';
class SeparateWindowStore {
    isOpen = false;
    listeners = {
        message: [],
        keydown: [],
        close: [],
    };
    closeInterval;
    constructor() {
        this.onMessage = this.onMessage.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.subscribeToEvents();
    }
    get isParent() {
        return Boolean(window.SEPARATE_WINDOW);
    }
    get isChild() {
        return !this.isParent;
    }
    get href() {
        if (this.isParent) {
            return window.SEPARATE_WINDOW?.location.href;
        }
        return window.opener?.location.href;
    }
    async open(path, config = {}) {
        const { sameScreen = true } = config;
        if (this.isOpen) {
            if (window.SEPARATE_WINDOW) {
                window.SEPARATE_WINDOW.location.href = path;
            }
            return;
        }
        let features = 'popup';
        if (!sameScreen) {
            try {
                if ('getScreenDetails' in window && window.screen.isExtended) {
                    let targetScreen = window.screen;
                    const { state } = await navigator.permissions.query({
                        name: 'window-management',
                    });
                    if (state === 'granted') {
                        const screens = await window.getScreenDetails();
                        const secondScreen = screens.screens.find((screen) => screen !== screens.currentScreen);
                        if (secondScreen) {
                            targetScreen = secondScreen;
                        }
                        features = [
                            `left=${targetScreen.availLeft}`,
                            `top=${targetScreen.availTop}`,
                            `width=${targetScreen.availWidth}`,
                            `height=${targetScreen.availHeight}`,
                            'menubar=no',
                            'toolbar=no',
                            'location=no',
                            'status=no',
                            'resizable=yes',
                            'scrollbars=no',
                        ].join(',');
                    }
                }
            }
            catch (e) {
                logger.warn(e.message, e);
            }
        }
        window.SEPARATE_WINDOW = window.open(path, '_blank', features);
        if (window.SEPARATE_WINDOW) {
            window.SEPARATE_WINDOW.focus();
            /*
             * If "window.SEPARATE_WINDOW" is presented it means that child window is opened,
             * in this case check that child window is still opened
             * */
            clearInterval(this.closeInterval);
            this.closeInterval = setInterval(() => {
                if (window.SEPARATE_WINDOW?.closed) {
                    this.executeListener('close');
                    this.close(); // execute handlers if child window is closed
                }
            }, 300);
        }
        this.isOpen = true;
    }
    close() {
        if (this.isParent) {
            window.SEPARATE_WINDOW.close();
            window.SEPARATE_WINDOW.onmessage = null;
            window.SEPARATE_WINDOW = null;
            clearInterval(this.closeInterval);
            this.isOpen = false;
            return;
        }
        window.close();
    }
    on(event, callback) {
        if (isArray(this.listeners?.[event])) {
            this.listeners[event].push(callback);
        }
        else {
            this.listeners[event] = [callback];
        }
    }
    off(event, callback) {
        if (isArray(this.listeners?.[event])) {
            const index = this.listeners?.[event].findIndex((func) => func.toString() === callback.toString());
            if (index !== -1) {
                this.listeners[event].splice(index, 1);
            }
        }
    }
    send(action, data) {
        const message = JSON.stringify({ action, data });
        if (this.isParent) {
            window.SEPARATE_WINDOW?.postMessage(message);
        }
        else {
            window.opener?.postMessage(message);
        }
    }
    looseFocus() {
        this.send(EVENT_LOOSE_FOCUS);
    }
    onMessage(e) {
        if (e.origin !== window.location.origin) {
            return;
        }
        this.executeListener('message', e);
    }
    executeListener(event, data = null) {
        if (!isArray(this.listeners?.[event])) {
            return;
        }
        throttle(() => {
            this.listeners[event].forEach((callback) => {
                if (event === 'message' && data.data) {
                    if (typeof data.data === 'string') {
                        const parsedData = JSON.parse(data.data);
                        callback?.(parsedData);
                    }
                    else {
                        callback?.(data.data);
                    }
                    return;
                }
                callback?.(data);
            });
        }, 0);
    }
    subscribeToEvents() {
        if (!this.isParent) {
            try {
                // TODO add check that "window.opener" has same origin as current window
                // when parent window closed or reloaded
                window.opener?.addEventListener('unload', this.close);
            }
            catch (e) {
                logger.error(e.message);
            }
        }
        window.addEventListener('message', this.onMessage);
        window.addEventListener('keydown', this.onKeyDown);
    }
    /*
     * Transfer keyboard events to parent window
     * */
    onKeyDown(e) {
        if (this.isParent) {
            return;
        }
        switch (e.key) {
            case 'Escape':
                this.close();
                break;
            default:
                this.send(EVENT_KEYBOARD, {
                    key: e.key,
                    ctrlKey: e.ctrlKey,
                    code: e.code,
                    shiftKey: e.shiftKey,
                    altKey: e.altKey,
                    metaKey: e.metaKey,
                });
                break;
        }
    }
}
__decorate([
    observable
], SeparateWindowStore.prototype, "isOpen", void 0);
__decorate([
    action
], SeparateWindowStore.prototype, "open", null);
__decorate([
    action
], SeparateWindowStore.prototype, "close", null);
__decorate([
    action
], SeparateWindowStore.prototype, "on", null);
__decorate([
    action
], SeparateWindowStore.prototype, "off", null);
export const useSeparateWindowStore = () => useStore(SeparateWindowStore);
const separateWindowStore = new SeparateWindowStore();
export { SeparateWindowStore };
export default separateWindowStore;
