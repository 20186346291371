/**
 * Dirty hack for updating all waypoints on the page
 * @see https://github.com/brigade/react-waypoint
 * @see https://stackoverflow.com/questions/1818474/how-to-trigger-the-window-resize-event-in-javascript
 */
const updateWaypoints = function updateWaypoints() {
    // send this event after render of react component tree
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 0);
};
export default updateWaypoints;
