import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import priceFormatter from '@/common/helpers/priceFormatter';
export const FIELD_PRICE_CT = {
    label: 'Price ct',
    fullLabel: 'Price Per Carat',
    key: 'priceCt',
    type: FieldType.Scalar,
    getter(p) {
        return p.priceCt ? `${priceFormatter.format(p.priceCt)}/ct` : null;
    },
    getterPlain(p) {
        return p.priceCt ? p.priceCt : null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        return p.priceCt ? p.priceCt * -1 : null;
    },
    contexts: ALL_CONTEXTS,
};
