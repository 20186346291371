import { COMPARE_DIAMOND_GROUP, COMPARE_ROUGH_GROUP, ProductType } from '@/product/constants/productTypes';
export function getCompareContextByType(type) {
    if (COMPARE_DIAMOND_GROUP.has(type)) {
        return ProductType.Diamond;
    }
    if (COMPARE_ROUGH_GROUP.has(type)) {
        return ProductType.Rough;
    }
    return type;
}
