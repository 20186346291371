import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { inject } from 'mobx-react';
import cls from 'classnames';
import styles from './Layout.less';
function Layout(props) {
    const { isSidebarOpened, isPhone, className, children, twoColumn = false, strictHeight = false, sidebarWidth, } = props;
    useEffect(() => {
        if (strictHeight) {
            document.body.classList.add(styles.layoutStrictHeight);
        }
        else {
            document.body.classList.remove(styles.layoutStrictHeight);
        }
        return () => {
            document.body.classList.remove(styles.layoutStrictHeight);
        };
    }, [strictHeight]);
    const shouldShowSidebar = isSidebarOpened && twoColumn && !isPhone;
    return (_jsx("div", { "aria-label": "Layout", className: cls(styles.layout, {
            [styles.layoutTwoColumn]: twoColumn,
            [styles.layoutOneColumn]: !twoColumn,
            [styles.layoutStrictHeight]: strictHeight,
            [styles.layoutWithSidebar]: twoColumn && shouldShowSidebar,
            [styles.layoutWithoutSidebar]: twoColumn && !shouldShowSidebar,
        }, className), 
        // @ts-ignore
        style: { '--sidebar-width': sidebarWidth ? `${sidebarWidth}rem` : null }, children: children }));
}
export default inject(({ appStore }) => ({
    isPhone: appStore.isPhone,
    isSidebarOpened: appStore.isSidebarOpened,
}))(Layout);
