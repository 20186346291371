import { jsx as _jsx } from "react/jsx-runtime";
import AltSkuField from '@/catalog/components/AltSkuField/AltSkuField';
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
export const FIELD_ALT_SKU = {
    label: 'Alt. SKU',
    fullLabel: 'Alternative SKU',
    key: 'altSku',
    type: FieldType.Custom,
    getter(p) {
        return _jsx(AltSkuField, { value: this.getterPlain(p) });
    },
    getterPlain(p) {
        return p.altSku;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
