export var FieldPresetType;
(function (FieldPresetType) {
    FieldPresetType["Mobile"] = "mobile";
    FieldPresetType["Info"] = "full";
    FieldPresetType["Full"] = "info-full";
    FieldPresetType["MediaOnly"] = "media";
    FieldPresetType["ReferenceComparison"] = "reference-comparison";
    FieldPresetType["MobileReferenceComparison"] = "mobile-reference-comparison";
    FieldPresetType["Solutions"] = "solutions";
    FieldPresetType["SolutionsMedia"] = "solutions-media";
    FieldPresetType["CollectionCombo"] = "collection-combo";
})(FieldPresetType || (FieldPresetType = {}));
export const FIELD_PRESET_TYPE_MOBILE_COMPARISON = FieldPresetType.Mobile;
export const FIELD_PRESET_TYPE_FULL = FieldPresetType.Full;
export const FIELD_PRESET_TYPE_MEDIA_ONLY = FieldPresetType.MediaOnly;
export const FIELD_PRESET_TYPE_REFERENCE_COMPARISON = FieldPresetType.ReferenceComparison;
export const FIELD_PRESET_TYPE_MOBILE_REFERENCE_COMPARISON = FieldPresetType.MobileReferenceComparison;
export const FIELD_PRESET_TYPE_SOLUTIONS = FieldPresetType.Solutions;
export const FIELD_PRESET_TYPE_SOLUTIONS_MEDIA_ONLY = FieldPresetType.SolutionsMedia;
