import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import Rough from '@/product/entity/Rough';
export const FIELD_DIMENSIONS = {
    key: 'dimensions',
    label: 'Dimensions',
    fullLabel: 'Dimensions',
    type: FieldType.Scalar,
    spGroupId: null,
    getter(p) {
        return this.getterPlain(p);
    },
    getterPlain(p) {
        return p instanceof Rough ? p.dimensions : null;
    },
    decorator: emptyDecorator,
    iteratee: null,
    contexts: ALL_CONTEXTS,
};
