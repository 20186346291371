export default class SpreadMetric {
  /**
   * @type {number}
   */
  ct;

  /**
   * @type {number}
   */
  d;

  /**
   * @type {number}
   */
  pc;

  /**
   * @type {string}
   */
  ver;

  /**
   * Contains calculated value for radar,
   * value in range in between 0 and 1
   *
   * @type {number}
   */
  gmc;

  /**
   * @param {number} ct
   * @param {number} d
   * @param {number} pc
   * @param {string} ver
   * @param {number} gmc
   */
  constructor({
    ct, d, pc, ver, gmc,
  }) {
    this.ct = ct;
    this.d = d;
    this.pc = pc;
    this.ver = ver;
    this.gmc = gmc;
  }
}
