import {
  PHOTOREAL_PRODUCTS_EXCLUDED,
  PHOTOREAL_PRODUCTS_ONLY,
} from '../../../catalog/constants/photorealInclusionFilterValues';

/**
 * @param {Query} query
 * @return {Query}
 */
export default (query) => {
  const filters = query.queryStringParams.filters;

  if (filters.photorealProductsInclusion) {
    if (filters.photorealProductsInclusion) {
      if (filters.photorealProductsInclusion === PHOTOREAL_PRODUCTS_ONLY) {
        filters.isVirtual = 1;
      }
      if (filters.photorealProductsInclusion === PHOTOREAL_PRODUCTS_EXCLUDED) {
        filters.isVirtual = 0;
      }

      delete filters.photorealProductsInclusion;
    }
  }

  return query;
};
