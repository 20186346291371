import React, { Component } from 'react';
import PropTypes from 'prop-types';
import commonImg from './common.svg';
import comparisonImg from './comparison.svg';
import privacyImg from './privacy.svg';
import styles from './ErrorPage.less';
import AppError, {IMG_COMMON, IMG_COMPARISON, IMG_PRIVACY} from '../../state/AppError';
import Link from '../../components/Link/Link';
import Button from '../../components/Button/Button';
import Auth from '@/user/services/auth/Auth';
import Layout from '../../components/Layout/Layout';

const IMAGES = {
  [IMG_COMMON]: commonImg,
  [IMG_COMPARISON]: comparisonImg,
  [IMG_PRIVACY]: privacyImg,
};

export default class ErrorPage extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(AppError).isRequired,
  };

  render() {
    const { error } = this.props;
    const hasLinks = error.hasCatalogLink || error.hasCollectionLink || error.hasLoginLink;

    return (
      <Layout>
        <div className={styles.pageErrorWrapper}>
          <div className={styles.pageError}>
            {error.code === 404 && <div className={styles.pageErrorCode} aria-label="Error Code">404</div>}
            <div className={styles.pageErrorTitle} aria-label="Error Title">
              <h2>{error.title}</h2>
            </div>
            <div className={styles.pageErrorImage} aria-label="Error Image">
              <img alt={error.imgName} src={IMAGES[error.imgName]} />
            </div>
            <div className={styles.pageErrorDescription} aria-label="Error Description">
              <div>
                {error.description}
              </div>
              <div className={styles.pageErrorDescriptionLinks} aria-label="Error Links">
                {hasLinks && (<div className={styles.pageErrorDescriptionHasLinks}>Let&apos;s get you back on track with helpful link(s):</div>)}
                {error.hasCatalogLink && (<div>Go to the <Link to="/catalog">Catalog page</Link></div>)}
                {error.hasCollectionLink && (<div>Go to the <Link to="/collections">Demo Collection List</Link></div>)}
                {error.hasLoginLink && (<div><Button onClick={Auth.redirectToLoginPage}>Log in</Button></div>)}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
