import { observable } from 'mobx';

export default class CustomGradingDictEntry {
  id;

  /**
   * @type {number}
   */
  @observable
  refId;

  /**
   * @type {Diamond}
   */
  @observable
  ref;

  /**
   * @type {string}
   */
  @observable
  title;

  constructor({
    id, refId, title,
  }) {
    this.id = id;
    this.refId = refId;
    this.title = title;
  }
}
