import { format, isValid } from 'date-fns';
export const STANDARD_DATE = 'MMM dd, yyyy';
export const HUMAN_DATE = 'dd MMM yyyy';
export const FULL_HUMAN_DATE_TIME = `${HUMAN_DATE}, HH:mm`;
export const SERVER_DATE = 'yyyy-MM-dd';
export const SERVER_TIME = 'HH:mm:ssXXX';
export const SERVER_DATE_TIME = `${SERVER_DATE}'T'${SERVER_TIME}`;
export const formatDate = {
    format(date, pattern) {
        if (!date || date === Infinity) {
            return '';
        }
        if (date instanceof Date || typeof date === 'string' || typeof date === 'number') {
            const dateValue = new Date(date);
            if (dateValue && isValid(dateValue)) {
                return format(dateValue, pattern);
            }
            return `${date}`;
        }
        return '';
    },
    toServer(date) {
        return this.format(date, SERVER_DATE_TIME);
    },
    toServerDate(date) {
        return this.format(date, SERVER_DATE);
    },
    toHumanDate(date) {
        return this.format(date, HUMAN_DATE);
    },
    toFullHumanDateTime(date) {
        return this.format(date, FULL_HUMAN_DATE_TIME);
    },
};
