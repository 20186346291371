import CutwiseAPIClient, {METHOD_GET, METHOD_POST} from '../../common/network/CutwiseAPIClient';

export const PATH_CUSTOMGRADE = 'api/v2/customgrade';

export default class CustomGradingAPI {
  /**
   * @param {string} attribute
   * @return {Promise<QueryResult>}
   */
  fetchCustomGradeAttribute(attribute) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(`api/v2/customgrade/attribute/${attribute}`);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {[Product]} products
   * @param {string} gradingFieldKey
   * @returns {Promise<QueryResult>}
   */
  fetchCustomGradingDataForProducts(products, gradingFieldKey) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_CUSTOMGRADE)
      .addQueryStringParam('product', products.map(p => p.id))
      .addQueryStringParam('attribute', gradingFieldKey);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {Product} product
   * @param {CustomGradingDictEntry} resultedGrade
   * @param {string} gradingFieldKey
   * @param {number} lastProductPosition
   * @param {string} sessionId
   * @returns {Promise<QueryResult>}
   */
  changeCustomGradeForProduct(product, resultedGrade, gradingFieldKey, lastProductPosition, sessionId) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_POST)
      .path(PATH_CUSTOMGRADE)
      .body({
        attribute: gradingFieldKey,
        product: product.id,
        grade: {
          grade: resultedGrade.id,
          session: sessionId,
          initialPosition: lastProductPosition,
        },
      });

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }
}
