import Storage from '../Storage';

export default class LocalStorageDriver {
  constructor() {
    this.storage = Storage;
  }

  get(key) {
    return Promise.resolve(this.storage.getObject(key, this.storage.STORAGE_LOCAL));
  }

  set(key, value) {
    return Promise.resolve(this.storage.saveObject(key, value, this.storage.STORAGE_LOCAL));
  }

  /**
   * @param {string} key
   * @param {function} callback
   */
  onKeyChange(key, callback) {
    window.addEventListener('storage', (e) => {
      if (e.key !== key) {
        return;
      }

      // todo json parser to separate service

      callback(JSON.parse(e.newValue));
    });
  }
}
