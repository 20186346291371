import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './MenuItemSeparator.module.css';
function MenuItemSeparator(props) {
    const { children, borderless = false } = props;
    const className = cls({
        [styles.menuItemSeparator]: Boolean(children),
        [styles.menuItemSeparatorLine]: !children,
        [styles.menuItemSeparatorBordered]: !borderless,
    });
    return _jsx("div", { className: className, children: children });
}
export default memo(MenuItemSeparator);
